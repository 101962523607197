import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useDataFromEndpoint } from "../hooks/useDataFromEndpoint";
import { formatDatetime, highlightText, plural, removeMeetingById, removeBackendMeetingById, } from '../../utils/genericUtils';
import { FileUploadIcon, GoogleMeetLogo, TrashCanIcon, ZoomLogo } from '../Icons';
import Alert from "../Reusable/Alert";
import { Button } from '../../theme';

import styled from "styled-components";
import { LoaderMeeting } from "../../types/LoaderData";
import Attendees from "../Reusable/Attendees";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-item: center;
`;

const Call = styled(Link)`
  display: grid;
  grid-template-columns: auto 230px 180px;
  column-gap: 32px;
  border: var(--standardBorder);
  border-radius: var(--standardRadius);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
  padding: 16px;
  width: 100%;
`;

const Label = styled.span`
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
`;

const IconWrapper = styled.div`
  width: 22px;
`;
const StartDate = styled.span`
  font-size: 12px;
  color: var(--textSecondary);
  display: inline-flex;
  align-items: center;
`;

const Delete = styled.div`
  border: var(--standardBorder);
  border-radius: var(--standardRadius);
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  display: flex;
  justify-content: space-between;
  align-item: center;
  padding: 16px;
`;

const DeleteBtn = styled(Button)`
`;

function getMeetingLabel(m) {
  if (m.meeting_status && m.meeting_status !== 'ready') {
    if (m.meeting_status === "ongoing") {
      if (!m.recording_started_zoom) {
        if (!m.meeting_ended_zoom) {
          return m.meetingName;
        } else {
          return "Meeting ended. Recording was not started";
        }
      } else {
        return "Transcribing...";
      }
    }
    if (m.meeting_status === "processing") {
      return "Processing...";
    }
    if (m.meeting_status === "captions_missing") {
      return "Transcript not available";
    }
    if (m.meeting_status === "recording_permission_denied") {
      return "Permission denied";
    }

    return "error";
  }
  return m.meetingName;
}
function getMeetingIcon({ m, style }: { m: LoaderMeeting, style?: React.CSSProperties; }) {
  if (m.meetingPlatform === 'zoom') {
    return <ZoomLogo size={22} style={style} />;
  }
  if (m.meetingPlatform == 'Google Meets') {
    return <GoogleMeetLogo width="22" style={{ ...style }} />;
  }
  if (m.isBackend) {
    return <FileUploadIcon width="22" style={{ ...style }} />;
  }
  return <GoogleMeetLogo width="22" style={{ ...style }} />;
}

interface Meeting extends LoaderMeeting {
  outputContextStrings?: string[],
  summaryContextStrings?: string[],
  outputMatchesCount?: number,
  summaryMatchesCount?: number,
  transcriptMatchesCount?: number,
  transcriptContextStrings?: string[],
}

interface Props {
  setRemovedMeetingIds: React.Dispatch<React.SetStateAction<string[]>>;
  m: Meeting;
  searchQuery?: string;
  removedMeetingIds: string[];
}


const CallList = ({ setRemovedMeetingIds, m, searchQuery, removedMeetingIds }: Props) => {
  const { t } = useTranslation();
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const [workflowRuns] = useDataFromEndpoint(`/api/workflow_runs`, { params: { taskSet: 'sync_field_mappings' } });

  const workflowRunsByTranscriptId = useMemo(() => {
    if (workflowRuns == null) return {};

    return workflowRuns.reduce((result, workflowRun) => {
      if (result[workflowRun.transcriptId]) return result;
      result[workflowRun.transcriptId] = workflowRun;
      return result;
    }, {});
  }, [workflowRuns?.length]);

  const hasTasks = workflowRunsByTranscriptId[m.transcriptId]?.tasks.some(({ complete }) => complete === false);

  const handleDeleteCall = useCallback(() => {
    m.isBackend ? removeBackendMeetingById(m.meetingId) : removeMeetingById(m?.meetingId);
    setRemovedMeetingIds(removedMeetingIds.concat([m.meetingId]));
  }, [setRemovedMeetingIds, setIsAlertOpen,]);


  const params = `search=${searchQuery}&${m.outputContextStrings ? 'tab=create' : m.transcriptContextStrings ? 'tab=transcript' : m.summaryContextStrings ? 'tab=summary' : ''}`;

  return (
    <Container>
      <Alert
        open={Boolean(isAlertOpen)}
        onOpenChange={setIsAlertOpen}
        onConfirm={handleDeleteCall}
        cancelContent={t('call-list.delete-call.confirmation.cancel', 'Cancel')}
        confirmContent={t('call-list.delete-call.confirmation.confirm', 'Yes, delete this call')}
        description={t('call-list.delete-call.confirmation.description', 'This action cannot be reversed and this call and its associated data will be permanently removed.')}
        title={t('call-list.delete-call.confirmation.title', 'Are you sure you want to delete this call?')}
      />
      <Call
        key={m.meetingId}
        to={{
          pathname: `/meeting/${m.meetingId}`,
          search: searchQuery ? params : undefined
        }}

      >
        <Label>
          {hasTasks && <span style={{ lineHeight: 0, color: 'var(--brand-primary)', fontSize: 24 }}>•</span>}
          <IconWrapper>{getMeetingIcon({ m })}</IconWrapper>
          {getMeetingLabel(m)}
        </Label>
        <StartDate>
          {m.meeting_status === "ongoing" ? <>
            <svg style={{ marginRight: 3 }} xmlns="http://www.w3.org/2000/svg" width="8" height="8"
              viewBox="0 0 8 8" fill="none">
              <circle opacity="0.6" cx="4" cy="4" r="2" fill="var(--brand-primary)" />
            </svg>
            {t('root-sidebar.call-item.in-progress', 'Recording in progress...')}
          </> : formatDatetime(m.meetingStartDate)}
        </StartDate>
        {m.attendeesByColor && m.attendeesByColor.length > 0 && <Attendees limit={3} attendeesByColor={m.attendeesByColor} />}
        <div>
          {m.transcriptContextStrings && m.transcriptContextStrings.length > 0 && <div className="search-match-info">
            <Link className="search-badge-container"
              to={{ pathname: `/meeting/${m.meetingId}`, search: `search=${searchQuery}&tab=transcript` }} >
              <div className="search-badge-name">Transcript</div>
              <div
                className="search-badge-count">{m.transcriptMatchesCount} {plural('match', 'matches', m.transcriptContextStrings.length)}</div>
            </Link>
            <p>
              {highlightText(m.transcriptContextStrings?.slice(0, 3)?.join(" ... ") + (m.transcriptContextStrings.length > 1 ? " ... " : ""), searchQuery?.split(/\s+/), { "fontWeight": "bold" })}
            </p>
          </div>}
          {m.summaryContextStrings && m.summaryContextStrings.length > 0 && <div className="search-match-info">
            <Link className="search-badge-container"
              to={{ pathname: `/meeting/${m.meetingId}`, search: `search=${searchQuery}&tab=summary` }}
            >
              <div className="search-badge-name">AI Summary</div>
              <div
                className="search-badge-count">{m.summaryMatchesCount} {plural('match', 'matches', m.summaryContextStrings.length)}</div>
            </Link>
            <p>
              {highlightText(m.summaryContextStrings?.slice(0, 3)?.join(" ... ") + (m.summaryContextStrings.length > 1 ? " ... " : ""), searchQuery?.split(/\s+/), { "fontWeight": "bold" })}
            </p>
          </div>}
          {m.outputContextStrings && m.outputContextStrings.length > 0 && <div className="search-match-info">
            <Link className="search-badge-container"
              to={{ pathname: `/meeting/${m.meetingId}`, search: `search=${searchQuery}&tab=create` }}
            >
              <div className="search-badge-name">Create</div>
              <div
                className="search-badge-count">{m.outputMatchesCount} {plural('match', 'matches', m.outputMatchesCount)}</div>
            </Link>
            <p>
              {highlightText(m.outputContextStrings?.slice(0, 3)?.join(" ... ") + (m.outputContextStrings.length > 1 ? " ... " : ""), searchQuery?.split(/\s+/), { "fontWeight": "bold" })}
            </p>
          </div>}
        </div>
      </Call>
      <Delete>
        <DeleteBtn icon onClick={() => setIsAlertOpen(!isAlertOpen)}>
          <TrashCanIcon />
        </DeleteBtn>
      </Delete>
    </Container>
  );

};

export default CallList;
