import { SvgSize } from './types';

const SendIcon = (props: SvgSize) => (
  <svg width={props?.size ?? '20'} height={props?.size ?? '20'} viewBox="0 0 495.003 495.003" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Send Icon</title>
    <path d="M164.711 456.687c0 2.966 1.647 5.686 4.266 7.072 2.617 1.385 5.799 1.207 8.245-.468l55.09-37.616-67.6-32.22v63.232zM492.431 32.443c-1.513-1.395-3.466-2.125-5.44-2.125-1.19 0-2.377.264-3.5.816L7.905 264.422c-4.861 2.389-7.937 7.353-7.904 12.783.033 5.423 3.161 10.353 8.057 12.689L133.4 349.618l250.62-205.99-219.565 220.786 156.145 74.4c1.918.919 4.012 1.376 6.084 1.376 1.768 0 3.519-.322 5.186-.977 3.637-1.438 6.527-4.318 7.97-7.956l154.596-390c1.224-3.069.426-6.578-2.005-8.814z" fill="currentColor" />
  </svg>
);

export default SendIcon;
