/* eslint-disable react/prop-types */
// SettingsTabIntegrations.js
import React, { useEffect, useState } from 'react';
import { useUserData } from '../hooks/useUserData';
import { trackEvent, updateUserSettings, disconnectGoogleCalendar, authCalendar, } from '../../utils/genericUtils';
import { useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useDebounce } from 'use-debounce';

import { useTranslation } from 'react-i18next';
import { GoogleCalendarLogo, GoogleMeetLogo, HubspotLogo, SalesforceLogo, SlackLogo, ZoomLogo, XIcon, CheckmarkCircleIcon } from '../Icons';
import ProBadge from '../Reusable/ProBadge';
import IssueDetected from '../Reusable/IssueDetected';
import { BodyLight, Button, H2, HR, } from '../../theme';
import styled from 'styled-components';
import OutlineButton from '../Reusable/OutlineButton';
import HubspotSalesForceNoCalendarConnectedPopup from './HubspotSalesForceNoCalendarConnectedPopup';
import ManageHubspotSalesforceMode from './ManageHubspotSalesforceMode';
import ManageZoom from './ManageZoom';
import ManageSlack from './ManageSlack';


const Row = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 25px;
`;
const Integration = styled.span`
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 600;
`;
const IntegrationName = styled.span`
`;

export const connectedToEmailBadge = (email, t) => {
  let icon;
  const notConnected = email === 'Not connected';
  if (notConnected) {
    icon = <XIcon size={12} />;
  } else {
    icon = <CheckmarkCircleIcon size={12} />;
  }
  return <div className="connected-to-email-badge">
    {icon}
    {notConnected ? t('settings.integrations.connected-badge.not-connected', 'Not connected') : email ? email : t('settings.integrations.connected-badge.connected', 'Connected')}
  </div>;
};

function SettingsTabCalendarIntegration({ calendarIsIntegrated, forceRenewUserData, email_google_calendar }) {
  const { t } = useTranslation();

  function onCalendarLoginSuccess(data) {

    authCalendar(data.code, () => {
      setTimeout(() => {
        forceRenewUserData();
      }, 500);
    });
  }

  const calendarLogin = useGoogleLogin({
    onSuccess: codeResponse => onCalendarLoginSuccess(codeResponse),
    flow: 'auth-code',
    scope: "openid profile email https://www.googleapis.com/auth/calendar.readonly"
  });

  return <Row>
    <div>
      <Integration>
        <GoogleCalendarLogo size="18" /> <IntegrationName>Google Calendar™</IntegrationName>
        {calendarIsIntegrated && connectedToEmailBadge(email_google_calendar, t)}
      </Integration>
      <BodyLight>{t('settings.integrations.calendar.description', 'Connect your calendar to interact with other call participants.')}</BodyLight>
    </div>
    <Button medium className={calendarIsIntegrated && 'connected'} style={{}} onClick={calendarIsIntegrated ? disconnectGoogleCalendar : () => {
      trackEvent('briefly-connect-calendar-clicked', {});
      calendarLogin();
    }}>
      {calendarIsIntegrated ?
        t('disconnect', { ns: 'Actions', defaultValue: "Disconnect" }) : t('connect', { ns: 'Actions', defaultValue: "Connect" })}
    </Button>
  </Row>;


}

export function SettingsTabIntegrations({ setShowZoomOnboardingPopup, brieflyIsInstalled, brieflyUserData, isManageSlackMode, isManageHubspotMode, isManageSalesforceMode, setShowSlackTip, isManageZoom }) {

  const navigate = useNavigate();

  const [{ email_google_calendar, is_integration_active_google_calendar,
    is_hubspot_connected, is_salesforce_connected, slack_email, email,
    slack_integration_is_visible, admin_user_subscription_is_active, admin_user, subscription_is_active, slack_workspace_name,
    zoom_integration_status,
    hubspot_redshift_db_name,
    salesforce_redshift_db_name,
    salesforce_api_disabled_error,
    force_local_storage,
    zoom_is_setup
  }, forceRenewUserData] = useUserData();

  const [showHubspotCalendarRequiredPopup, setShowHubspotCalendarRequiredPopup] = useState(false);

  const [isZoomIntegrationStatusLoading, setIsZoomIntegrationStatusLoading] = useState(false);

  const [slackEmailBuffer, _setSlackEmailBuffer] = useState(slack_email ? slack_email : email);
  const [slackEmailBufferDebounce] = useDebounce(slackEmailBuffer, 1000);

  const userIsPaid = (subscription_is_active || (!!admin_user && admin_user_subscription_is_active));

  const hubspotDomain = hubspot_redshift_db_name ? hubspot_redshift_db_name?.replace('staging-hubspot-', '')?.replace('prod-hubspot-', '')?.replaceAll('-', '.') : '';
  const salesforceDomain = salesforce_redshift_db_name ? salesforce_redshift_db_name?.replace('staging-salesforce-', '')?.replace('prod-salesforce-', '')?.replaceAll('-', '.') : '';

  const { t } = useTranslation();



  useEffect(() => {
    updateUserSettings({
      slack_email: slackEmailBufferDebounce
    }, () => {
      forceRenewUserData();
    });
  }, [slackEmailBufferDebounce]);

  const calendarIsIntegrated = is_integration_active_google_calendar;

  useEffect(() => {
    const interval = setInterval(() => {
      forceRenewUserData();
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const isHubspotManagedBySomeoneElse = false;
  const isSalesforceManagedBySomeoneElse = false;

  const showNoCalendarPopupHubspot = isHubspotManagedBySomeoneElse && !is_integration_active_google_calendar;
  const showNoCalendarPopupSalesforce = isSalesforceManagedBySomeoneElse && !is_integration_active_google_calendar;

  useEffect(() => {
    if (showNoCalendarPopupHubspot || showNoCalendarPopupSalesforce) {
      setShowHubspotCalendarRequiredPopup(true);
    }
  }, []);

  if (isManageSalesforceMode) {
    return <ManageHubspotSalesforceMode isSalesforce={true} {...{
      setShowHubspotCalendarRequiredPopup,
      showHubspotCalendarRequiredPopup,
      userIsPaid,
    }} />;
  }

  if (isManageHubspotMode) {
    return <ManageHubspotSalesforceMode isSalesforce={false} {...{
      setShowHubspotCalendarRequiredPopup,
      showHubspotCalendarRequiredPopup,
      userIsPaid,
    }} />;
  }

  if (isManageZoom) {
    return <ManageZoom {...{
      is_integration_active_google_calendar,
      email_google_calendar,
      zoom_integration_status,
      onZoomIntegrationStatusChange: newStatus => {
        setIsZoomIntegrationStatusLoading(true);
        updateUserSettings({ zoom_integration_status: newStatus }, () => {
          forceRenewUserData();
          setTimeout(() => {
            setIsZoomIntegrationStatusLoading(false);
          }, 500);
        });
      },
      forceRenewUserData,
      isZoomIntegrationStatusLoading
    }} />;
  }

  if (isManageSlackMode) {
    return <ManageSlack />;
  }

  return (
    <div id="settings-integrations">
      <H2>{t('settings.integrations.tab-label', 'Integrations')}</H2>
      {showNoCalendarPopupHubspot && showHubspotCalendarRequiredPopup && <HubspotSalesForceNoCalendarConnectedPopup {...{ integrationName: 'Hubspot', setShowHubspotCalendarRequiredPopup, calendarIsIntegrated, forceRenewUserData, email_google_calendar }} />}
      {showNoCalendarPopupSalesforce && showHubspotCalendarRequiredPopup && !showNoCalendarPopupHubspot && <HubspotSalesForceNoCalendarConnectedPopup {...{ integrationName: 'Salesforce', setShowHubspotCalendarRequiredPopup, calendarIsIntegrated, forceRenewUserData, email_google_calendar }} />}

      <Row>
        <div>
          <Integration>
            <GoogleMeetLogo width="16" />
            <span>{t('settings.integrations.google-meet.title', 'Google Meet')}</span>
            {brieflyIsInstalled && connectedToEmailBadge(brieflyUserData?.profile?.email, t)}
          </Integration>
          <BodyLight>{t('settings.integrations.google-meet.details', 'Download the Chrome Extension to use Briefly in Google Meet')}</BodyLight>
        </div>
        {brieflyIsInstalled ? <p style={{ fontSize: "14px" }}>{t('settings.integrations.google-meet.installed', 'Chrome Extension Installed')}</p> : <Button medium onClick={() => {
          window.open('https://chrome.google.com/webstore/detail/briefly-ai-meeting-summar/bjmgcelbpkgmofiogkmleblcmecflldk', '_blank');
        }}>
          {/* If userData.email_zoom exists then show disconnect, else show connect */}
          {t('settings.integrations.google-meet.download', 'Download extension')}
        </Button>}
      </Row>
      <HR />

      <Row>
        <div>
          <Integration>
            <ZoomLogo size="16" />
            <IntegrationName>Zoom</IntegrationName>
          </Integration>
          <BodyLight>{t('settings.integrations.zoom.description', 'Manage Briefly for your Zoom calls')}</BodyLight>
        </div>
        {zoom_is_setup ?
          <Button medium onClick={() => { navigate('/settings/integrations/zoom'); }}>
            {t('manage', { ns: 'Actions', defaultValue: 'Manage' })}
          </Button>
          :
          <Button medium onClick={() => { setShowZoomOnboardingPopup(true); }}>
            {t('setup', { ns: 'Actions', defaultValue: 'Setup' })}
          </Button>
        }
      </Row>
      <HR />

      <Row>
        <div>
          <Integration>
            <SlackLogo width="16" />
            <IntegrationName>Slack {!userIsPaid ? <ProBadge /> : (slack_workspace_name ? connectedToEmailBadge(slack_workspace_name, t) : '')}</IntegrationName>
          </Integration>
          <BodyLight>{t('settings.integrations.slack.description.part1', 'Connect to receive AI summaries')}{' '}{slack_integration_is_visible ? t('settings.integrations.slack.description.part2', 'and pre call briefings') : ''}</BodyLight>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
          <OutlineButton medium bgColor='var(--backgroundSecondary)' onClick={() => { navigate('/settings/integrations/slack'); }}>
            {t('manage', { ns: 'Actions', defaultValue: 'Manage' })}
          </OutlineButton>
          <Button medium onClick={() => { setShowSlackTip(true); }} >
            {t('settings.integrations.slack.about', 'About')}
          </Button>
        </div>
      </Row>
      <HR />

      <GoogleOAuthProvider clientId="576404817524-ss93f3dua36hvaum9oi4vqrmu8anc6o3.apps.googleusercontent.com">
        <SettingsTabCalendarIntegration calendarIsIntegrated={calendarIsIntegrated} forceRenewUserData={forceRenewUserData} email_google_calendar={email_google_calendar} />
      </GoogleOAuthProvider>

      {!force_local_storage && <>
        <HR />
        <Row>
          <div>
            <Integration>
              <HubspotLogo size="16" />
              <IntegrationName>Hubspot</IntegrationName>
              {!userIsPaid ? <ProBadge /> : (is_hubspot_connected || isHubspotManagedBySomeoneElse ? connectedToEmailBadge(isHubspotManagedBySomeoneElse ? t('settings.integrations.hubspot.team-managed', 'Connected and managed by ') + 'team_hubspot_admin' : hubspotDomain) : '', t)}
            </Integration>
            <BodyLight>{t('settings.integrations.hubspot.description.part1', 'Connect to save summaries and transcripts in Hubspot')}<br />{t('settings.integrations.hubspot.description.part2', 'Requires Google Calendar integration')}</BodyLight>
          </div>
          {!isHubspotManagedBySomeoneElse &&
            <Button medium onClick={() => { navigate('/settings/integrations/hubspot'); }}>
              {t('manage', { ns: 'Actions', defaultValue: 'Manage' })}
            </Button>
          }
        </Row>

        <HR />
        <Row>
          <div>
            <Integration>
              <SalesforceLogo width="16" />
              <IntegrationName>Salesforce</IntegrationName>
              {salesforce_api_disabled_error && <IssueDetected />}
              {!userIsPaid ? <ProBadge /> :
                (is_salesforce_connected || isSalesforceManagedBySomeoneElse ?
                  connectedToEmailBadge(isSalesforceManagedBySomeoneElse ?
                    t('settings.integrations.salesforce.team-managed', 'Connected and managed by ') + 'team_salesforce_admin' :
                    salesforceDomain
                    , t) :
                  '')}
            </Integration>
            <BodyLight>{t('settings.integrations.salesforce.description', 'Connect to save summaries and transcripts in Salesforce')}</BodyLight>
          </div>
          {!isSalesforceManagedBySomeoneElse &&
            <Button medium onClick={() => { navigate('/settings/integrations/salesforce'); }}>
              {t('manage', { ns: 'Actions', defaultValue: 'Manage' })}
            </Button>
          }
        </Row>
      </>
      }
    </div >
  );
}

export default SettingsTabIntegrations;
