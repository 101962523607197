import React from 'react';
import { SvgSize } from '../types';

const ZoomLogo = (props: SvgSize) => (
  <svg xmlns="http://www.w3.org/2000/svg" aria-label="Zoom" viewBox="0 0 512 512" width={props?.size ?? '12'} height={props?.size ?? '12'} {...props}>
    <title>Zoom Logo</title>
    <rect width="100%" height="100%" fill="#2D8CFF" rx="15%" />
    <path fill="#fff" d="M428 357c8 2 15-2 19-8 2-3 2-8 2-19V179c0-11 0-15-2-19-3-8-11-11-19-8-21 14-67 55-68 72-.8 3-.8 8-.8 15v38c0 8 0 11 .8 15 1 8 4 15 8 19 12 9 52 45 61 45zM64 187c0-15 0-23 3-27 2-4 8-8 11-11 4-3 11-3 27-3h129c38 0 57 0 72 8 11 8 23 15 30 30 8 15 8 34 8 72v68c0 15 0 23-3 27-2 4-8 8-11 11-4 3-11 3-27 3H174c-38 0-57 0-72-8-11-8-23-15-30-30-8-15-8-34-8-72z" />
  </svg>
);

export default ZoomLogo;