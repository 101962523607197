import { useTranslation } from 'react-i18next';

import { removeBullets, groupStringIntoArray, trackEvent, highlightText } from '../../utils/genericUtils';
import CopyToClipboard from '../Reusable/CopyToClipboard';
import Caret from '../Icons/Caret';

import { Button, Body } from '../../theme';


import styled, { css } from 'styled-components';
import { AttendeeByColor, MeetingData } from './Types';
import { useOutletContext } from 'react-router-dom';
import { OutletContext } from '../../types/LoaderData';
import { groupKeyInsightsFromText } from '../../utils/summary';

export const Container = styled.details`
  border: var(--standardBorder);
  border-radius: var(--standardRadius);
  padding: 24px;
  position: relative;

  &[open] summary {
    position: absolute;
    top: -20px;
    width: auto;
    transition: all .25s ease-out;
  }

  &[open] .marker {
    transform: rotate(180deg);
  }
`;

export const Heading = styled.summary`
  margin: 0;
  position: absolute;
  top: 5px;
  background: var(--background);
  padding: 8px;
  font-weight: 700;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  width: 95%;
  transition: all .25s ease-in;

  &::-webkit-details-marker {
   display: none;
  }

  .marker {
    margin-left: auto;
  }

`;

const CopyWrapper = styled.div`
  position: absolute;
  top: 12px;
  right: 6px;
`;

const ActionItemHelp = styled.div`
  padding: 12px;
  border-radius: var(--standardRadius);
  background: var(--secondary100);
  margin-bottom: 24px;
  font-size: 12px;
  line-height: 18px;
  color: var(--textSecondary);
  font-weight: 500;
`;

const ButtonLink = styled(Button)`
  font-size: 12px;
  line-height: 18px;
`;

export const Section = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
`;

const ListBlock = styled.ul`
  background: var(--background);
  border-radius: var(--standardRadius);
  padding: 16px;
  font-size: 12px;
  line-height: 18px;
  color: var(--text);
  list-style-position: inside;
  margin: 0;
`;

const ListBlockSecondary = styled(ListBlock)`
  background: var(--backgroundSecondary);
  color: var(--textSecondary);
`;

const ListBlockTitle = styled.h4`
  font-size: 14px;
  font-weight: 700;
  color: var(--textPrimary);
  margin-top: 0;
`;

const ListItem = styled.li <{ $deviceColor?: string; }>`
  text-indent: -1.1rem;
  margin-left: 2rem;

  & span.search-word {
    text-indent: 0;
  }

  &:first-child {
    margin-bottom : 6px;
    font-size: 14px;
    font-weight: 700;
    list-style-type: none;
    color: var(--textPrimary);

    ${({ $deviceColor }) => $deviceColor && (css`
      color: ${$deviceColor};
    `)}
  }

  
`;


interface Props {
  attendeesByColor?: AttendeeByColor[],
  header: string,
  keyIdentifier: 'short_summary' | 'key_insights' | 'long_summary' | 'action_items' | 'notes' | 'live_checklist',
  text: string,
  isBackend?: Pick<MeetingData, 'isBackend'>,
  searchWords?: string,
  noteBlocks?: string[],
}

const SummarySection = ({ attendeesByColor, header, keyIdentifier, text, isBackend, searchWords, noteBlocks }: Props) => {
  const { t } = useTranslation();
  const { setShowZoomTranscriptInfoPopup, setShowMsTeamsTranscriptInfoPopup } = useOutletContext<OutletContext>();
  // @ts-expect-error todo
  const isError = text?.error;

  const Content = () => {
    switch (keyIdentifier) {
      case 'short_summary':
        return <Body>{searchWords ? highlightText(text, searchWords, { background: '#F9C338', color: 'black' }) : text}</Body>;
      case 'long_summary':
        return <ListBlock>{removeBullets(text).map((block, i) => <li key={i}>{searchWords ? highlightText(block, searchWords, { background: '#F9C338', color: 'black' }) : block}</li>)}</ListBlock>;
      case 'key_insights': {
        const insights = groupKeyInsightsFromText(text);
        return (
          <Section>{insights.map((topic) => (
            <ListBlockSecondary key={topic.topicHeading}>
              <ListBlockTitle>{topic.topicHeading}</ListBlockTitle>
              {topic.bulletPoints.map((insight) => {
                return (
                  <ListItem key={insight}>{
                    searchWords ?
                      highlightText(removeBullets(insight)[0], searchWords, { background: '#F9C338', color: 'black' })
                      :
                      removeBullets(insight)
                  }
                  </ListItem>
                )})}
            </ListBlockSecondary>))}
          </Section>
        );
      }
      case 'action_items':
        return (
          <Section>{groupStringIntoArray(text).map((speaker) => (
            <ListBlockSecondary key={speaker}>{speaker.map((item, i) => {
              const deviceColor = i !== 0 ? "" : attendeesByColor?.find((attendee) => attendee.name === item.replace(":", ""))?.deviceColor;

              return (
                <ListItem key={item} $deviceColor={deviceColor}>{
                  searchWords ?
                    highlightText(removeBullets(item)[0], searchWords, { background: '#F9C338', color: 'black' })
                    :
                    removeBullets(item)
                }
                </ListItem>
              );
            })}
            </ListBlockSecondary>))}
          </Section>
        );
      case 'notes':
        return <ListBlock>{noteBlocks?.map((block, i) => <li key={i}>{searchWords ? highlightText(block, searchWords, { background: '#F9C338', color: 'black' }) : block}</li>)}</ListBlock>;
      default:
        break;
    }
  };


  return (
    <Container open >
      <Heading>{header}<Caret className="marker" /></Heading>
      {text && !isError ? <CopyWrapper><CopyToClipboard text={text} /></CopyWrapper> : null}
      {keyIdentifier === 'action_items' && isBackend && <ActionItemHelp >
        {t('meeting.ia-summary.action-items.not-good.question', 'Action items not looking good? Make sure you upload the transcript with participants names.')} <ButtonLink link onClick={() => {
          trackEvent("briefly-how-to-get-zoom-transcripts-click", {
            source: 'SUMMARY_TAB'
          });
          setShowZoomTranscriptInfoPopup(true);
        }}>{t('meeting.ia-summary.action-items.not-good.zoom', 'How to get transcripts for Zoom')}</ButtonLink>, <ButtonLink link onClick={() => {
          trackEvent("briefly-how-to-get-ms-teams-transcripts-click", {
            source: 'SUMMARY_TAB'
          });
          setShowMsTeamsTranscriptInfoPopup(true);
        }
        }>{t('meeting.ia-summary.action-items.not-good.ms-teams', 'How to get transcripts for MS Teams')}</ButtonLink>
      </ActionItemHelp>}
      <div>
        {isError ?
          <Body>{t('meeting.ia-summary.gen-failed.message', 'OpenAI error. Please try again later...')}</Body> :
          text ? (
            <Content />
          ) : (
            <Body>{t('meeting.ia-summary.action-items.no-text', 'Please ensure that you have uploaded the transcript in the correct format as action items are displayed for each meeting participant.')}</Body>
          )}
      </div>
    </Container >
  );
};

export default SummarySection;