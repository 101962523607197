import React from 'react';
import { SvgSize } from './types';


const PlugIcon = (props: SvgSize) => (
  <svg width={props?.size ?? '16'} height={props?.size ?? '16'} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Plug Icon</title>
    <g clipPath="url(#clip0_1276_2192)">
      <path fillRule="evenodd" clipRule="evenodd" d="M3.70526 11.6645L1.81592 12.7553C1.32512 13.0387 0.697539 12.8705 0.414177 12.3797C0.130815 11.8889 0.298975 11.2613 0.789772 10.978L2.67912 9.88716C2.18856 8.12618 2.92626 6.18982 4.58259 5.23353L8.52605 2.95677C8.80213 2.79738 9.15514 2.89197 9.31453 3.16805L9.53897 3.55679L12.2049 2.01762C12.6957 1.73426 13.3233 1.90242 13.6066 2.39322C13.89 2.88401 13.7218 3.51159 13.231 3.79496L10.5651 5.33412L11.5912 7.11139L14.257 5.5723C14.7478 5.28893 15.3754 5.45709 15.6587 5.94789C15.9421 6.43869 15.774 7.06627 15.2832 7.34963L12.6174 8.88873L12.8419 9.27764C13.0013 9.55371 12.9067 9.90673 12.6306 10.0661L8.68717 12.3429C7.03083 13.2992 4.98503 12.9698 3.70526 11.6645Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_1276_2192">
        <rect width={props?.size ?? '16'} height={props?.size ?? '16'} fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);

export default PlugIcon;
