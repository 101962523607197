import React from 'react';

import AsyncSelect from 'react-select/async';
import { ControlProps, Options, components, Props, GroupBase } from 'react-select';

import { useDebouncedCallback } from 'use-debounce';
import styled from 'styled-components';
import { colors } from '../../theme';
import Search from '../Icons/Search';

export interface Option {
  value: string,
  label: string,
}

interface ServerOption {
  text: {
    type: "plain_text",
    text: string,
  },
  value: string,
}

const serverOptionsToSelectOptions = (options: ServerOption[]): Options<Option> => {
  return options.map(({ text: { text }, value }) => ({
    value,
    label: text,
  }));
};

const Select = styled(AsyncSelect<Option, false, GroupBase<Option>>)`
.rs__indicator-separator {
  display: none;
}

.rs__control,
.rs__single-value,
.rs__input-container {
  color: ${colors.gray900};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.15px;
}

.rs__control {
  align-items: center;
  box-sizing: border-box;
  color: ${colors.gray800};
  border-radius: 4px;
  background-color: ${colors.white};
  border: 1px solid ${colors.gray500};
  width: 100%;
  color: ${colors.gray800};
}

.rs__menu {
  background-color: ${colors.gray200};
  border: 1px solid ${colors.gray500};
  width: 100%;
  color: ${colors.gray900};
}

.rs__option {
  background-color: ${colors.gray200};
  cursor: pointer;
  &:hover {
    background-color: ${colors.gray400};
  }
}
`;

const SearchSquare = styled(Search)`
  margin-left: 16px;
  width: 12px;
`;

const Control = ({ children, ...props }: ControlProps<Option, false, GroupBase<Option>>) => (
  <components.Control {...props}>
    <SearchSquare /> {children}
  </components.Control>
);

const AsyncSearchSelect = ({ onSearch, onChange, ...props }: Props<Option, false, GroupBase<Option>> & { onSearch: (q: string) => Promise<{ options: ServerOption[]; }>; }) => {
  const loadOptions = useDebouncedCallback((q, callback) => {
    if (q.length < 3) {
      callback([]);
      return;
    }

    onSearch(q)
      .then((response: { options: ServerOption[]; }) => callback(serverOptionsToSelectOptions(response.options)));
  }, 1000);

  return (
    <Select
      menuPlacement="auto"
      loadOptions={loadOptions}
      classNamePrefix="rs"
      cacheOptions
      components={{ Control }}
      onChange={onChange}
      isClearable
      {...props}
    />
  );
};

export default AsyncSearchSelect;

