import { CHROME_EXTENSION_ID } from '../config/config';

interface Args {
  accessToken: string,
  refreshToken: string,
}

export class ExtensionNotFoundError extends Error {
  constructor() {
    super();
    this.name = 'ExtensionNotFoundError';
  }
}

export default async function signInToExtensionOrThrow({ accessToken, refreshToken }: Args): Promise<void> {
  if (global.chrome.runtime == null) throw new ExtensionNotFoundError();

  return new Promise((resolve) => {
    // give time for message to complete
    setTimeout(() => {
      global.chrome.runtime.sendMessage(CHROME_EXTENSION_ID, {
        eventType: 'SAML_SSO_SUCCESS',
        access_token: accessToken,
        refresh_token: refreshToken,
      });
      resolve();
    }, 100);
  });
}


