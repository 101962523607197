import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { Tooltip } from 'react-tooltip';
import X from '../Icons/X';
import {
  sendTeamInvitation, openCheckoutSessionInNewTab,
  openCustomerPortalSessionInNewTab,
  confirmTeamInvitation,
  formatDateDDMMYYYY,
  rejectTeamInvitation,
  removeMyselfFromTeam,
  removeTeamMember,
  deleteRejectedInvite
} from '../../utils/genericUtils';
import ButtonWithTimeoutReplaceOnClick from '../Reusable/ButtonWithTimeoutReplaceOnClick';
import { useUserData } from '../hooks/useUserData';
import CopyToClipboard from '../Reusable/CopyToClipboard';
import OutlineButton from '../Reusable/OutlineButton';
import { ExclamationIcon, PlusIcon, QuestionMarkIcon } from '../Icons';
import { useTranslation, Trans } from 'react-i18next';
import { BodyLight, Button, H2, HR } from '../../theme';

function validateEmail(email) {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
}

export function SettingsTabSubscriptions() {
  const [
    { profile, subscription_is_active, pending_invites, admin_user, managed_users_not_confirmed, managed_users, admin_user_subscription_is_active, briefly_ai_credits_left,
      subscription_cancels_at_period_end, billing_period_end, users_who_rejected, was_upgraded_to_team, min_seats, forecasted_monthly_bill, coupon_percentage_applied,
      subscription_plan, coupon_fixed_price_applied, email, coupon_percentage_applied_team_members
    },
    forceRenewUserData
  ] = useUserData();

  const { t } = useTranslation();

  const [showAddTeamMemberPopup, setShowAddTeamMemberPopup] = useState(false);
  const [csvEmailsToBeInvites, setCsvEmailsToBeInvited] = useState("");

  const emailsToBeInvited = csvEmailsToBeInvites.split(',').map(x => x.trim().toLowerCase()).filter(x => x);
  const emailsAreValid = emailsToBeInvited.length > 0 && !emailsToBeInvited.find(x => !validateEmail(x));
  const isOutOfSeats = managed_users.concat(managed_users_not_confirmed).length >= min_seats;
  const isTeamMemberAccount = !!admin_user;
  const isProGivenForFree = subscription_plan === 'STANDARD_GIVEN_FOR_FREE' && !isTeamMemberAccount;
  const isEnterprise = (subscription_is_active || isProGivenForFree) && !!min_seats;
  const isTeamAccount = (isEnterprise || (managed_users.concat(managed_users_not_confirmed).concat(users_who_rejected)).length > 0 || was_upgraded_to_team);
  const pendingInvite = pending_invites?.[0];
  const seatPrice = 15 * (100 - coupon_percentage_applied) * (100 - coupon_percentage_applied_team_members) / 100 / 100;
  const PRICE_PER_SEAT = seatPrice;

  const stripeSubscriptionState = subscription_is_active && !isTeamMemberAccount && !isProGivenForFree ? <div className="row" id="stripe-subscription-state" style={{ alignItems: 'center' }}>
    <span>
      {subscription_cancels_at_period_end ? `${t('settings.subscription.state.canceled', 'Membership cancelled: ends on')} ${formatDateDDMMYYYY(billing_period_end)}` : `${t('settings.subscription.state.renew', 'Monthly billing renews on')} ${formatDateDDMMYYYY(billing_period_end)}`}
    </span>
    <span>
      ${(Math.round((100 - coupon_percentage_applied) * forecasted_monthly_bill - coupon_fixed_price_applied) / 100).toFixed(2)}
      <QuestionMarkIcon data-tooltip-id={"tooltip-payment"} style={{ display: 'inline-block', marginLeft: 5, color: 'var(--textSecondary)' }} />
      <Tooltip id="tooltip-payment" place="top" style={{ background: 'var(--gray800)' }}>
        <div style={{ maxWidth: '220px', textAlign: "left", lineHeight: '18px', letterSpacing: '0.3px' }}>
          <b>{t('settings.subscription.state.tooltip.title', 'How is this calculated?')}</b><br /><br />
          <span style={{ color: 'var(--gray300)' }}>{t('settings.subscription.state.tooltip.part1', 'Monthly charges are prorated at')} ${seatPrice} {t('settings.subscription.state.tooltip.part2', 'per user. If you add or remove team members during the month, you\'ll only pay for the time they were part of the team.')}</span>
        </div>
      </Tooltip>
    </span>
  </div> : '';

  const teamMemberPopup = <div className="transcript-info-popup-container">
    <div className="popup-background" style={{}} onClick={() => setShowAddTeamMemberPopup(false)}></div>
    <div className="popup-content">
      <div className="close-icon" onClick={() => setShowAddTeamMemberPopup(false)}>
        <X />
      </div>
      <h1>{isEnterprise && isOutOfSeats ? t('settings.subscription.team-member.out-of-seats', 'You’ve run out of available seats') : t('settings.subscription.team-member.add-members', 'Add members to your team')}</h1>


      {isEnterprise ? <BodyLight>
        {t('settings.subscription.team-member.min_seats', { min_seats, defaultValue: 'Your enterprise membership includes {{min_seats}} seats' })}
      </BodyLight> : ''}

      {isEnterprise ? <>
        <BodyLight>
          {t('settings.subscription.team-member.extra_seat', { seatPrice, defaultValue: `You can add extra members for $\{{seatPrice}} each per month` })}
        </BodyLight>
      </> : ''}

      {!isEnterprise && !isOutOfSeats ? <div className="row">
        {/* exclamation icon */}
        <ExclamationIcon width={19} />
        <BodyLight>
          {t('settings.subscription.team-member.add_member.part1', 'Each new member will be added to your plan as a PRO member')} {isEnterprise ? t('settings.subscription.team-member.add_member.enterprise-part', { seatPrice, defaultValue: 'at {{seatPrice}}/user/month' }) : ' '} {t('settings.subscription.team-member.add_member.part2', 'and your billing will be updated accordingly.')}
        </BodyLight>
      </div> : ''}

      <div>
        <input value={csvEmailsToBeInvites} onChange={e => setCsvEmailsToBeInvited(e.target.value)} type="text" placeholder="name@email.com, name2@email.com, name3@email.com" />
      </div>

      <Button onClick={() => {
        if (emailsToBeInvited.includes(email)) {
          // setShowAddTeamMemberPopup(false)
          Swal.fire({
            icon: 'info',
            title: 'Oops...',
            text: t('settings.subscription.team-member.add_member.is-admin', { email, defaultValue: '{{email}} can\'t be added to the team as it’s already the team admin' }),
            background: '#eee'
          });
          return;
        }
        const alreadyInvitedEmail = managed_users.concat(managed_users_not_confirmed).find(x => emailsToBeInvited.includes(x));
        if (alreadyInvitedEmail) {
          // setShowAddTeamMemberPopup(false)
          Swal.fire({
            icon: 'info',
            title: 'Oops...',
            text: t('settings.subscription.team-member.add_member.already-member', { alreadyInvitedEmail, defaultValue: `{{alreadyInvitedEmail}} can't be added to the team as it’s already a member` }),
            background: '#eee'
          });
          return;
        }
        sendTeamInvitation(emailsToBeInvited, () => {
          setShowAddTeamMemberPopup(false);
          forceRenewUserData();
        });
        setCsvEmailsToBeInvited("");
      }} style={{ marginTop: 20, marginBottom: 5 }} disabled={!emailsAreValid}>
        {isEnterprise && isOutOfSeats ? t('settings.subscription.team-member.invite-member.priced', { seatPrice, defaultValue: `Invite extra members for $\{{seatPrice}}/user/month` }) : t('settings.subscription.team-member.invite-member.non-priced', 'Send invitation')}
      </Button>
    </div>
  </div>;

  const pendingInviteSection = pendingInvite ? <div style={{
    borderRadius: 'var(--standardRadius)',
    background: 'var(--primary100)',
    padding: 24,
    marginBottom: 20
  }}>
    <h2 style={{
      fontSize: 16,
      fontWeight: 900
    }}>{t('settings.subscription.invitation.title', 'You’ve been invited to join a team!')}</h2>

    <BodyLight>
      {t('settings.subscription.invitation.body.part1', { pendingInvite, defaultValue: 'By accepting this invitation you will be added to {{pendingInvite}}\'s team on Briefly as a PRO user.' })}{' '}
      {isTeamAccount ? t('settings.subscription.invitation.body.team-terminate', { pendingInvite, defaultValue: 'Your TEAM account will terminate and your monthly fee will be covered by {{pendingInvite}} account.' })
        : subscription_is_active ? t('settings.subscription.invitation.body.team-terminate', { pendingInvite, defaultValue: 'Your TEAM account will terminate and your monthly fee will be covered by {{pendingInvite}} account.' })
          : t('settings.subscription.invitation.body.no-terminate', { pendingInvite, defaultValue: 'Your monthly fee will be covered by {{pendingInvite}} account.' })
      }
    </BodyLight>

    <div className="row" style={{ marginTop: 10, width: 175 }}>
      <OutlineButton medium bgColor='var(--primary100)' onClick={() => rejectTeamInvitation(pendingInvite, () => {
        forceRenewUserData();
      })}>
        {t('reject', { ns: 'Actions', defaultValue: 'Reject' })}
      </OutlineButton>
      <Button medium onClick={() => confirmTeamInvitation(pendingInvite, () => {
        forceRenewUserData();
      })}>
        {t('accept', { ns: 'Actions', defaultValue: 'Accept' })}
      </Button>
    </div>
  </div> : null;

  return <div id="settings-subscriptions">
    {showAddTeamMemberPopup ? teamMemberPopup : ''}

    {subscription_is_active || isTeamMemberAccount || isProGivenForFree ? <>
      <div className="row">
        <H2>{t('settings.subscription.tab-label', 'Subscriptions')}</H2>
        <div className="badge" style={isEnterprise ? { width: 72 } : {}}>{isEnterprise ? 'ENTERPRISE' : isTeamAccount || isTeamMemberAccount ? 'TEAM' : 'PRO'}</div>
      </div>
      {pendingInviteSection}
      <BodyLight>
        {t('settings.subscription.current.part1', 'You are currently subscribed to Briefly')} {isEnterprise ? 'Enterprise' : isTeamAccount || isTeamMemberAccount ? 'TEAM' : isProGivenForFree ? 'PRO (FREE)' : 'PRO'}{isProGivenForFree ? '' : ','} {isProGivenForFree ? '' : isTeamMemberAccount ? t('settings.subscription.current.managed-part', { admin_user, defaultValue: '{{admin_user}} is managing your subscription' }) : t('settings.subscription.current.stripe-part', 'you can manage your membership on Stripe')}
      </BodyLight>

      {(admin_user && !admin_user_subscription_is_active) && <BodyLight>
        {t('settings.subscription.current.inactive', 'Subscription is not active. Please tell account manager to renew subscription')}
      </BodyLight>}

      {!isTeamMemberAccount && subscription_is_active && !isProGivenForFree ? (
        <div>
          <Button onClick={() => openCustomerPortalSessionInNewTab()}>
            {t('settings.subscription.current.manage-membership', 'Manage my membership')}
          </Button>
          <Button secondary onClick={() => window.open('https://www.brieflya=-i.com/contact-us', '_blank')} >
            {t('settings.subscription.current.contact-support', 'Contact support')}
          </Button>
        </div>
      ) : (
        isProGivenForFree ? '' : <Button onClick={() => {
          Swal.fire({
            title: t('settings.subscription.current.remove-alert.title', { admin_user, defaultValue: 'Are you sure you want to be removed from {{admin_user}} team account?' }),
            text: t('settings.subscription.current.remove-alert.content', "By doing so you will loose all Briefly Pro account privileges and your account will be switched to a Free subscription"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: t('settings.subscription.current.remove-alert.confirm', 'Yes, remove my team membership'),
            cancelButtonText: t('settings.subscription.current.remove-alert.cancel', 'No, keep my membership'),
            background: '#eee'
          }).then((result) => {
            if (result.isConfirmed) {
              removeMyselfFromTeam(admin_user, () => {
                forceRenewUserData();
              });
            }
          });
        }}>
          {t('settings.subscription.current.remove', 'Remove team membership')}
        </Button>)}

      {!isTeamMemberAccount && !isProGivenForFree ? <HR /> : ''}
    </> : <><H2>{t('settings.subscription.tab-label', 'Subscriptions')}</H2> {pendingInviteSection} </>}

    {!(subscription_is_active || (isTeamMemberAccount && admin_user_subscription_is_active)) && !isProGivenForFree ? <BodyLight>
      {(isTeamMemberAccount && !admin_user_subscription_is_active) ? <HR /> : ''}
      <b>{briefly_ai_credits_left} {t('settings.subscription.current.free.credits-left', 'free AI credits left')}</b>
      <br />
      <span>{t('settings.subscription.current.free.credits-cost', 'AI summary, BrieflyCreate Output or email follow up generation, will use 1 AI credit. Transcriptions and transcript uploads are unlimited on your free plan.')}</span>
    </BodyLight> : ''}

    {subscription_is_active && !isTeamAccount ? stripeSubscriptionState : ''}

    {!isTeamMemberAccount && (subscription_is_active || isProGivenForFree) ? <div className="row">
      <div>
        <H2>
          {isTeamAccount ? t('subscription.team.title', 'Team') : t('subscription.team.switch-title', 'Switch to team account')}
        </H2>
        <BodyLight>
          {!isTeamAccount ?
            <Trans i18nKey="settings.subscription.team.switch.description" PRICE_PER_SEAT={PRICE_PER_SEAT}>
              By switching you’ll be billed ${{ PRICE_PER_SEAT }}.00 per month plus $\{{ PRICE_PER_SEAT }}.00 per month for each member added
            </Trans> : isEnterprise ?
              <div style={{ color: 'var(--white)', fontWeight: 500 }}>
                <div style={{ padding: "6px", textAlign: "center", color: "var(--textPrimary)" }}>{min_seats} {t('subscription.team.total-seats', 'total seats available with your plan')}</div>
                <div style={{ background: 'var(--secondary600)', padding: "6px 0", textAlign: "center" }}>{Math.min(min_seats, managed_users.concat(managed_users_not_confirmed).length)}{' '}{t('subscription.team.seats.used', 'used')}</div>
                <div style={{ background: 'var(--primary600)', padding: "6px 0", textAlign: "center" }}>{Math.max(0, min_seats - managed_users.concat(managed_users_not_confirmed).length)}{' '} {t('subscription.team.seats.available', 'available')}</div>
                {managed_users.concat(managed_users_not_confirmed).length > min_seats &&
                  <div style={{ background: 'var(--brand-tertiary)', padding: "6px 0", textAlign: "center" }}>{managed_users.concat(managed_users_not_confirmed).length - min_seats}{' '}{t('subscription.team.seats.extra', 'extra added')}</div>}
              </div> : ''}
        </BodyLight>
      </div>
      <div>
        <Button medium onClick={() => setShowAddTeamMemberPopup(true)} style={{ marginLeft: 15 }}>
          <PlusIcon size={16} />
          {t('subscription.team.add-member', 'Add member')}
        </Button>
        <BodyLight style={{ fontSize: 12, textAlign: 'right' }}>
          {isTeamAccount && !isEnterprise &&
            <Trans i18nKey='subscription.team.add-member-price' PRICE_PER_SEAT={PRICE_PER_SEAT} >
              ${{ PRICE_PER_SEAT }}.00 per member per month
            </Trans>}
        </BodyLight>
      </div>
    </div> : isTeamMemberAccount || isProGivenForFree ? '' : <div className="row" style={{ marginTop: 30 }}>
      <div>
        <H2>
          {t('subscription.upgrade.label', 'Upgrade to PRO')}
        </H2>
        <BodyLight>
          <Trans i18nKey='subscription.upgrade.description' PRICE_PER_SEAT={PRICE_PER_SEAT} >
            Upgrade to PRO account for ${{ PRICE_PER_SEAT }}.00 per month
          </Trans>
        </BodyLight>
      </div>
      <div>
        <Button onClick={() => openCheckoutSessionInNewTab()}>
          {t('subscription.upgrade.button', 'Upgrade to PRO')}
        </Button>
      </div>
    </div>}

    {(subscription_is_active || isProGivenForFree) && isTeamAccount ? <>
      <HR />
      {stripeSubscriptionState}
    </> : ''}

    {isTeamAccount ? <div id="team-user-table">
      <div className="header row">
        <h3>{t('subscription.team-table.headers.email', 'Email')}</h3>
        <h3>{t('subscription.team-table.headers.role', 'Role')}</h3>
        <div></div>
      </div>
      <div className="row">
        <div>{profile?.email}</div>
        <div>{t('subscription.team-table.row.admin', 'Admin')}</div>
        <div></div>
      </div>
      {managed_users.map(email => {
        return <>
          <HR />
          <div className="row">
            <div>{email}</div>
            <div>{t('subscription.team-table.row.member', 'Team Member')}</div>
            <div><a onClick={() => {
              removeTeamMember(email, () => {
                forceRenewUserData();
              });
            }} className="remove-btn">{t('subscription.team-table.row.remove', 'Remove user')}</a></div>
          </div>
        </>;
      })}
      {managed_users_not_confirmed.filter(x => !managed_users.includes(x)).map(email => {
        return <>
          <HR />
          <div className="row">
            <div>{email}</div>
            <div>{t('subscription.team-table.row.member', 'Team Member')}</div>
            <div style={{ margin: 0, minWidth: 175, display: "flex", justifyContent: "space-between" }}>
              <div style={{ textAlign: "left" }}>
                {t('subscription.team-table.row.pending', 'Pending...')}
                <br />
                <ButtonWithTimeoutReplaceOnClick
                  secondary small
                  initialText={t('subscription.team-table.row.resend', 'Re-send invite')}
                  clickedText={t('subscription.team-table.row.sent', 'Email Sent')}
                  onClick={() => {
                    sendTeamInvitation([email], () => {
                      forceRenewUserData();
                    });
                  }}
                />
              </div>
              <div style={{ display: "flex", gap: "5px" }}>
                <CopyToClipboard style={{ padding: '2px 5px' }} id="copy_invite_url" text="https://app.brieflyai.com/settings/subscriptions" />
                <Tooltip id="copy_invite_url" place="top" style={{ background: '#2c2c2c' }}>
                  <div style={{ maxWidth: '150px', textAlign: "left", lineHeight: '18px', letterSpacing: '0.3px' }}>
                    <b>{t('subscription.team-table.row.copy-invite', 'Copy invite URL')}</b>
                  </div>
                </Tooltip>
                <Button icon
                  onClick={() => {
                    removeTeamMember(email, () => {
                      forceRenewUserData();
                    });
                  }}>
                  <X data-tooltip-id="remove_user" style={{ marginRight: "0" }} size='11' />
                </Button>
                <Tooltip id="remove_user" place="top" style={{ background: '#2c2c2c' }}>
                  <div style={{ maxWidth: '150px', textAlign: "left", lineHeight: '18px', letterSpacing: '0.3px' }}>
                    <b>{t('subscription.team-table.row.remove', 'Remove user')}</b>
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        </>;
      })}
      {users_who_rejected.map(email => {
        return <>
          <HR />
          <div className="row">
            <div>{email}</div>
            <div>{t('subscription.team-table.row.member', 'Team Member')}</div>
            <div style={{ margin: 0, minWidth: 175, display: "flex", justifyContent: "space-between" }}>
              <div style={{ textAlign: "left" }}>
                {t('subscription.team-table.row.rejected', 'Rejected')}
                <br />
                <ButtonWithTimeoutReplaceOnClick
                  secondary small
                  initialText={t('subscription.team-table.row.resend', 'Re-send invite')}
                  clickedText={t('subscription.team-table.row.sent', 'Email Sent')}
                  onClick={() => {
                    sendTeamInvitation([email], () => {
                      forceRenewUserData();
                    });
                  }}
                />
              </div>
              <div style={{ display: "flex", gap: "5px" }}>
                <Button icon
                  onClick={() => {
                    deleteRejectedInvite(email, () => {
                      forceRenewUserData();
                    });
                  }}>
                  <X data-tooltip-id={`remove_user-${email}`} style={{ marginRight: "0" }} size='11' />
                </Button>
                <Tooltip id={`remove_user-${email}`} place="top" style={{ background: '#2c2c2c' }}>
                  <div style={{ maxWidth: '150px', textAlign: "left", lineHeight: '18px', letterSpacing: '0.3px' }}>
                    <b>{t('subscription.team-table.row.remove', 'Remove user')}</b>
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        </>;
      })}
    </div > : ''}
  </div >;
}

export default SettingsTabSubscriptions;
