import { useEffect } from "react"
import { getUserAccessTokenPromise } from '../../utils/genericUtils';
import signInToExtensionOrThrow from "../../utils/signInToExtensionOrThrow";
import { WELCOME_URL } from '../../config/config';
import { ThreeDots } from "react-loader-spinner";
import styled from "styled-components";

const Page = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;

export default function PostExtensionInstall() {
  const loginToExtensionAndRedirect = async () => {
    try {
      const {
        access: accessToken,
        refresh: refreshToken,
      } = await getUserAccessTokenPromise() as { access: string, refresh: string };

      await signInToExtensionOrThrow({ accessToken, refreshToken })
    } finally {
      window.location.href = WELCOME_URL;
    }
  }

  useEffect(() => {
    loginToExtensionAndRedirect();
  }, [])

  return <Page>
    <ThreeDots
      height="20"
      width="20"
      radius="9"
      color="white"
      ariaLabel="three-dots-loading"
    />
  </Page>
}

