import React from "react";

const Caret = (props: React.HTMLAttributes<HTMLOrSVGElement>) =>
  <svg viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg" width="12" height="6" {...props}>
    <title>Caret Icon</title>
    <path d="M5.98902 6.00125C5.75585 6.00171 5.52987 5.92033 5.35033 5.77125L0.360516 1.77125C0.156716 1.60151 0.0285529 1.3576 0.00422288 1.09318C-0.0201072 0.82875 0.0613883 0.565468 0.230781 0.361252C0.400174 0.157035 0.643588 0.0286113 0.907476 0.00423152C1.17136 -0.0201482 1.43411 0.0615131 1.63791 0.231252L5.98902 3.71125L10.3401 0.391253C10.4422 0.308187 10.5597 0.246155 10.6858 0.208723C10.8118 0.171291 10.9441 0.159198 11.0748 0.173137C11.2056 0.187076 11.3323 0.226773 11.4477 0.289947C11.5631 0.35312 11.6649 0.438526 11.7473 0.541252C11.8387 0.644074 11.9079 0.7647 11.9506 0.895574C11.9933 1.02645 12.0086 1.16475 11.9954 1.30181C11.9823 1.43887 11.9411 1.57175 11.8744 1.6921C11.8076 1.81246 11.7168 1.91771 11.6075 2.00125L6.61774 5.83125C6.43303 5.95677 6.21167 6.01662 5.98902 6.00125Z" fill="#262E3D" />
    <path d="M5.98902 6.00125C5.75585 6.00171 5.52987 5.92033 5.35033 5.77125L0.360516 1.77125C0.156716 1.60151 0.0285529 1.3576 0.00422288 1.09318C-0.0201072 0.82875 0.0613883 0.565468 0.230781 0.361252C0.400174 0.157035 0.643588 0.0286113 0.907476 0.00423152C1.17136 -0.0201482 1.43411 0.0615131 1.63791 0.231252L5.98902 3.71125L10.3401 0.391253C10.4422 0.308187 10.5597 0.246155 10.6858 0.208723C10.8118 0.171291 10.9441 0.159198 11.0748 0.173137C11.2056 0.187076 11.3323 0.226773 11.4477 0.289947C11.5631 0.35312 11.6649 0.438526 11.7473 0.541252C11.8387 0.644074 11.9079 0.7647 11.9506 0.895574C11.9933 1.02645 12.0086 1.16475 11.9954 1.30181C11.9823 1.43887 11.9411 1.57175 11.8744 1.6921C11.8076 1.81246 11.7168 1.91771 11.6075 2.00125L6.61774 5.83125C6.43303 5.95677 6.21167 6.01662 5.98902 6.00125Z" fill="url(#paint0_linear_3197_529)" />
    <defs>
      <linearGradient id="paint0_linear_3197_529" x1="0" y1="3.00181" x2="12" y2="3.00181" gradientUnits="userSpaceOnUse">
        <stop stopColor="#F0631B" />
        <stop offset="1" stopColor="#BE01B6" />
      </linearGradient>
    </defs>
  </svg>

  ;

export default Caret;