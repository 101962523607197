import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LocizeBackend, { LocizeBackendOptions } from 'i18next-locize-backend';
import ChainedBackend, { ChainedBackendOptions } from 'i18next-chained-backend';
import LocalStorageBackend, { LocalStorageBackendOptions } from 'i18next-localstorage-backend';
import { LOCIZE_APP_ID, LOCIZE_VERSION, ENVIRONMENT } from '../config/config';
import { ExtensionChangeLanguageEvent, requestExtensionCurrentLanguage } from '../utils/extension';

const locizeOptions: LocizeBackendOptions = {
  projectId: LOCIZE_APP_ID,
  version: LOCIZE_VERSION,
  private: false,
};

export const LocizeInstance = new LocizeBackend(locizeOptions);

const defaultCacheDurationMs = 1000 * 60 * 60 * 24 * 7; // 7 days
// Adjust your cache duration in case you are translating and be easier to see latest changes
const devCacheDurationMs = 1000 * 10;


const I18NextCacheDurationMs = ENVIRONMENT === 'DEVELOPMENT' ? devCacheDurationMs : defaultCacheDurationMs;

const localStorageOptions: LocalStorageBackendOptions = {
  expirationTime: I18NextCacheDurationMs
};

const backendChainOptions: ChainedBackendOptions = {
  backends: [LocalStorageBackend, LocizeInstance],
  backendOptions: [localStorageOptions, locizeOptions]
};

const namespaces = ['Web App', 'Actions'] as const;

i18next
  .use(ChainedBackend)
  .use(initReactI18next).init<ChainedBackendOptions>({
    debug: ENVIRONMENT === 'DEVELOPMENT' ? false : true,
    lng: window.localStorage.getItem('selected-lng') ?? undefined,
    backend: backendChainOptions,
    ns: namespaces,
    defaultNS: namespaces[0],
    fallbackLng: 'en',
  });

// Fallback value when extension is not available
i18next.on("languageChanged", (lng) => {
  window.localStorage.setItem('selected-lng', lng);
});

// Listen when extension notifies the current language
window.addEventListener('lang-from-storage', (evt) => {
  const customEvent = evt as ExtensionChangeLanguageEvent;
  const lang = customEvent.detail.lng;
  i18next.changeLanguage(lang);
});

// Check on Re-focus tab
document.addEventListener('visibilitychange', () => {
  if (!document.hidden) {
    requestExtensionCurrentLanguage();
  }
});

// Do in initial load
requestExtensionCurrentLanguage();

export default i18next;
