// ZoomOAuth.js
import React, { useEffect } from 'react';
import { salesforceCallback } from '../../utils/genericUtils';

const SalesforceAuthCallback = () => {

  const urlSearchParams = new URLSearchParams(window.location.search);

  const authCode = urlSearchParams.get('code');

  useEffect(() => {
    salesforceCallback(authCode, () => {
      window.location = '/settings/integrations';
    });
  }, []);

  return <div className="hubspot-loader" style={{ width: '100%', height: '100%', background: 'black', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
    <svg width="121" height="89" viewBox="0 0 121 89" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M17.1856 14.3604H108.829C111.073 14.3604 112.893 16.1795 112.893 18.4236V81.8113C112.893 84.0553 111.073 85.8745 108.829 85.8745H17.1856C14.9415 85.8745 13.1223 84.0553 13.1223 81.8113V18.4236C13.1223 16.1795 14.9415 14.3604 17.1856 14.3604Z" fill="#212121" />
      <path fillRule="evenodd" clipRule="evenodd" d="M46.7038 65.9482H79.749C81.2189 65.9482 82.4106 67.1399 82.4106 68.6098V75.1577C82.4106 76.6277 81.2189 77.8193 79.749 77.8193H46.7038C45.2339 77.8193 44.0422 76.6277 44.0422 75.1577V68.6098C44.0422 67.1399 45.2339 65.9482 46.7038 65.9482Z" fill="#E44867" />
      <path d="M56.2098 71.8829H70.2413" stroke="white" strokeWidth="0.59901" strokeLinecap="round" strokeLinejoin="round" />
      <path fillRule="evenodd" clipRule="evenodd" d="M17.1856 14.3604H108.829C111.073 14.3604 112.893 16.1795 112.893 18.4236V81.8113C112.893 84.0553 111.073 85.8745 108.829 85.8745H17.1856C14.9415 85.8745 13.1223 84.0553 13.1223 81.8113V18.4236C13.1223 16.1795 14.9415 14.3604 17.1856 14.3604Z" stroke="#C6C6C6" strokeWidth="0.39934" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M63.2255 22.3932H92.808" stroke="#C6C6C6" strokeWidth="0.39934" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22.7037 22.3932H59.0174" stroke="#C6C6C6" strokeWidth="0.39934" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22.7037 26.1461H85.351" stroke="#C6C6C6" strokeWidth="0.39934" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22.7037 29.9005H45.797" stroke="#C6C6C6" strokeWidth="0.39934" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M50.0118 29.9005H72.2209" stroke="#C6C6C6" strokeWidth="0.39934" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M30.6565 47.6178H89.83" stroke="#C6C6C6" strokeWidth="0.39934" strokeLinecap="round" strokeLinejoin="round" />
      <ellipse cx="29.4434" cy="47.5977" rx="6.73971" ry="6.73291" fill="#F97238" />
      <path d="M26.4501 47.555L29.2969 49.8663L32.4368 45.9824" stroke="white" strokeWidth="0.59901" strokeLinecap="round" strokeLinejoin="round" />
      <ellipse opacity="0.1" cx="96.5697" cy="47.5977" rx="6.73971" ry="6.73291" fill="#C6C6C6" />
      <ellipse cx="63.0074" cy="47.5977" rx="6.73971" ry="6.73291" fill="#F97238" />
      <path d="M60.0123 47.555L62.8592 49.8663L66.0007 45.9824" stroke="white" strokeWidth="0.59901" strokeLinecap="round" strokeLinejoin="round" />
      <path fillRule="evenodd" clipRule="evenodd" d="M110.445 2.37842L97.6387 4.33383C97.6387 4.33383 98.0121 10.0819 97.6387 18.8252L111.813 16.8254C112.027 11.9693 111.567 7.10691 110.445 2.37842Z" fill="#E44867" />
      <path fillRule="evenodd" clipRule="evenodd" d="M120.888 13.8271H107.211L105.721 28.8177H120.888V13.8271Z" fill="#C6C6C6" />
      <path d="M110.072 19.5756L117.416 19.295" stroke="#C6C6C6" strokeWidth="0.39934" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M112.124 22.0095H118.092" stroke="#C6C6C6" strokeWidth="0.39934" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M109.605 24.9633L115.899 24.4937" stroke="#C6C6C6" strokeWidth="0.39934" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M100.84 10.6017L107.367 9.50293" stroke="#C6C6C6" strokeWidth="0.39934" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M101.446 13.2985L106.62 12.501" stroke="#C6C6C6" strokeWidth="0.39934" strokeLinecap="round" strokeLinejoin="round" />
      <path fillRule="evenodd" clipRule="evenodd" d="M104.595 5.84326C104.739 6.33102 104.937 6.80149 105.183 7.24631L104.595 5.84326Z" fill="white" />
      <path d="M110.072 19.5756L117.416 19.295" stroke="#434343" strokeWidth="0.39934" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M112.124 22.0095H118.092" stroke="#434343" strokeWidth="0.39934" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M109.605 24.9633L115.899 24.4937" stroke="#434343" strokeWidth="0.39934" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M100.84 10.6017L107.367 9.50293" stroke="#C6C6C6" strokeWidth="0.39934" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M101.446 13.2985L106.62 12.501" stroke="#C6C6C6" strokeWidth="0.39934" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M104.595 5.84326C104.739 6.33102 104.937 6.80149 105.183 7.24631" stroke="#C6C6C6" strokeWidth="0.39934" strokeLinecap="round" strokeLinejoin="round" />
      <path fillRule="evenodd" clipRule="evenodd" d="M101.161 1.32984C101.108 1.67546 101.216 2.02641 101.455 2.28096C101.706 2.52792 102.072 2.61641 102.407 2.51136L102.701 3.55405C102.772 3.74605 102.765 3.95823 102.681 4.14481C102.507 4.6499 102.369 5.27611 102.731 5.66601C102.923 5.85124 103.174 5.96305 103.439 5.98207C104.238 6.07668 105.019 5.69919 105.444 5.01322C105.548 4.85255 105.606 4.66676 105.612 4.47563C105.6 4.09502 105.317 3.77834 104.942 3.72537C104.841 3.73019 104.74 3.71201 104.648 3.6722C104.567 3.60819 104.508 3.52037 104.48 3.42113L104.069 2.35481C104.018 2.24645 103.989 2.12896 103.983 2.00921C104.026 1.81177 104.096 1.62103 104.189 1.44208C104.273 1.0924 104.147 0.725641 103.866 0.502776C103.045 -0.173644 101.323 0.139459 101.161 1.32984Z" fill="#434343" />
      <path fillRule="evenodd" clipRule="evenodd" d="M6.60339 66.6348C6.60339 66.6348 5.34047 73.8339 11.0451 76.5949C11.0451 76.5949 4.98834 79.375 5.29153 86.025L18.3146 86.2064C18.3146 86.2064 18.6727 78.9476 13.3166 76.6761C13.3166 76.6761 18.6691 74.4952 18.6178 66.3472L6.60339 66.6348Z" fill="white" stroke="#C6C6C6" strokeWidth="0.39934" strokeLinecap="round" strokeLinejoin="round" />
      <path fillRule="evenodd" clipRule="evenodd" d="M15.5632 72.6045C14.6952 74.3635 13.5586 75.2262 12.7936 75.6339L12.729 75.6677C12.7184 75.6731 12.7078 75.6785 12.6974 75.6838L12.6356 75.7144C12.6255 75.7193 12.6155 75.7242 12.6055 75.7289L12.5469 75.7564C12.5373 75.7609 12.5278 75.7652 12.5184 75.7694L12.463 75.794L12.41 75.8167C12.4014 75.8203 12.3928 75.8239 12.3844 75.8273L12.335 75.8473L12.2881 75.8655L12.2439 75.8821L12.2024 75.897L12.1637 75.9104L12.1279 75.9223L12.0951 75.9328L12.0653 75.9419C12.0672 75.9482 12.0683 75.9547 12.069 75.9614L12.0695 75.9731V79.3428C12.0695 79.3482 12.0692 79.3535 12.0685 79.3588L12.1122 79.3663L12.162 79.3759C13.008 79.5451 16.1322 80.5477 17.0816 86.1933L6.40051 86.0191L6.40788 85.9778L6.41987 85.9155L6.43128 85.8594L6.44557 85.7921L6.46287 85.7142L6.4833 85.6262C6.49064 85.5952 6.49853 85.5626 6.50698 85.5285L6.53405 85.4218C6.91804 83.9394 8.23732 80.1548 11.8305 79.3675C11.8296 79.3634 11.8289 79.3589 11.8285 79.3544L11.8279 79.3428V75.9731C11.8279 75.9523 11.8332 75.9328 11.8424 75.9157C10.5466 75.2064 9.46444 74.1622 8.709 72.892L8.67328 72.8313L15.5632 72.6045Z" fill="#E44867" />
      <path fillRule="evenodd" clipRule="evenodd" d="M4.58374 85.8997L19.0714 86.1026V88.2572H4.58374V85.8997ZM19.5763 64.0972V66.5705L5.49213 66.8737V64.5508L19.5763 64.0972Z" fill="#6D6D6D" />
    </svg>

    <style>
      {`
          * {
            pointer-events: none !important;
          }
        `}
    </style>
    <b>We are connecting Briefly to Salesforce</b>
    <p>This may take up to minute... please don’t leave this page</p>
  </div>;
};

export default SalesforceAuthCallback;
