import React, { ChangeEventHandler, FormEventHandler, useCallback, useEffect, useState, useMemo } from 'react';
import { apiGet } from '../../../utils/api';
import CreatableSelect from 'react-select/creatable';
import Select, { ControlProps, components } from 'react-select';
import { ThreeDots } from 'react-loader-spinner';
import { FileUploadIcon, GoogleMeetLogo, SearchIcon, ZoomLogo, CalendarIcon, PersonCircle, SortArrow } from '../../Icons';
import { Button } from '../../../theme';
import { Centered, LoadingAnimation, GroupRow, Label, LinkButton, Container, SearchRow, MeetingList, Row } from './styles';
import { Screen, SortOrder, Meeting, State, Participant, MeetingGroup, Props, Option } from './types';
import { Mode } from 'fs';
import MeetingRow from './MeetingRow';
import { useTranslation } from 'react-i18next';

const DAYS = 60 * 60 * 24;
const LIMIT = 300;
const orderOptions: { label: string, value: SortOrder, translationSubKey: string }[] = [
  { label: "Newest", value: '-meeting_date_start_time', translationSubKey: 'newest' },
  { label: "Oldest", value: 'meeting_date_start_time', translationSubKey: 'oldest' },
  { label: "Most Relevant", value: '-rank', translationSubKey: 'most-relevant' },
];

const dateOptions: { label: string, value: number }[] = [
  { label: "1 Day", value: 1 * DAYS },
  { label: "7 Days", value: 7 * DAYS },
  { label: "14 Days", value: 14 * DAYS },
  { label: "30 Days", value: 30 * DAYS },
]

export function getMeetingIcon(platform: string): JSX.Element {
  if (platform === 'zoom') {
    return <ZoomLogo />;
  }
  if (platform == 'Google Meets') {
    return <GoogleMeetLogo />;
  }

  return <FileUploadIcon />;
}

export default function MeetingFilter({ onConfirm, initialSelectedMeetingIds, onClose }: Props): JSX.Element {
  const initialMode: Mode = initialSelectedMeetingIds.length === 0 ? 'create' : 'update';
  const mode = initialMode;

  const { t, i18n } = useTranslation();

  const translatedDateOptions = useMemo(() => dateOptions.map(({ label, value }) => {
    const translatedLabel = t('chat.meeting-selector.time.label', {
      count: value / DAYS,
      defaultValue: label
    })

    return { label: translatedLabel, value }
  }), [t]);

  const translatedOrderOptions = useMemo(() => orderOptions.map(({ label, value, translationSubKey }) => {
    const translatedLabel = t(`chat.meeting-selector.order.${translationSubKey}`, { defaultValue: label });
    return { label: translatedLabel, value }
  }), [t]);

  const [isLoading, setIsLoading] = useState(true);
  const [screen, setScreen] = useState<Screen>(mode === 'create' ? 'select' : 'review');
  const [state, setState] = useState<State>({
    q: [],
    participants: [],
    daysAgo: undefined,
    order: translatedOrderOptions[0],
  });

  const [meetings, setMeetings] = useState<Meeting[]>([]);
  const [selectedMeetingIds, setSelectedMeetingIds] = useState<Set<number>>(new Set(initialSelectedMeetingIds))
  const [participants, setParticipants] = useState<Participant[]>([])
  const groupedMeetings = useMemo<MeetingGroup[]>(() => {
    if (meetings.length === 0) {
      return [];
    }

    if (state.order?.value === '-rank') {
      return [{ name: 'All', meetings, order: 0 }];
    }

    return meetings.reduce<MeetingGroup[]>((groups, meeting) => {
      const date = new Date(meeting.meeting_start_date_time * 1000);
      const order = date.getFullYear() * (date.getMonth() + 1)
      const group = groups.find((group) => group.order === order);
      if (!group) {
        return [...groups, {
          name: date.toLocaleString(i18n.language ?? 'default', { month: 'long' }),
          order,
          meetings: [meeting],
        }]
      }

      group.meetings.push(meeting)

      return groups;
    }, []);
  }, [meetings, state.order]);

  useEffect(() => {
    (async () => {
      const { participants } = await apiGet<Participant[], 'participants'>({ path: '/participants' });
      setParticipants(participants);
    })()
  }, []);

  const loadMeetings = useCallback(async (query: string) => {
    setIsLoading(true);
    try {
      const { transcripts } = await apiGet<Meeting[], 'transcripts'>({
        path: `/transcripts?${query}`,
      });
      setMeetings(transcripts);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (screen == 'review') {
      loadMeetings(Array.from(selectedMeetingIds).map((id) => `id=${id}`).join('&'))
    } else {
      const query = [`limit=${LIMIT}`]

      state.q.forEach((s) => query.push(`q=${s.value}`))
      state.participants.forEach((p) => query.push(`participants=${p.value}`))

      if (state.daysAgo) {
        query.push(`startTime=${(Date.now() / 1000) - state.daysAgo.value}`);
      }
      loadMeetings(query.join('&'))
    }
  }, [state, screen]);

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()
    setIsLoading(true)

    try {
      await onConfirm(Array.from(selectedMeetingIds));
    } finally {
      setIsLoading(false)
    }
  }
  const handleMeetingSelected = (id: number, selected: boolean) => {
    const newSet = new Set(selectedMeetingIds);

    if (selected) {
      newSet.add(id)
    } else {
      newSet.delete(id)
    }

    return setSelectedMeetingIds(newSet);
  }

  const handleGroupSelected: ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = Number(e.target.value);
    const groups = value === -1 ? groupedMeetings : groupedMeetings.filter(({ order }) => order === value);
    const newSet = new Set(selectedMeetingIds);

    if (groups.length === 0) throw new Error(`Can't find selected group ${value}`);

    if (e.target.checked) {
      groups.forEach((group) => (
        group.meetings.forEach(({ id }) => newSet.add(id))
      ))
    } else {
      groups.forEach((group) => (
        group.meetings.forEach(({ id }) => newSet.delete(id))
      ));
    }

    return setSelectedMeetingIds(newSet);
  }


  return <Container>
    {screen == 'select' && (
      <>
        <form onSubmit={handleSubmit} id="search">
          <SearchRow>
            <CreatableSelect
              isMulti
              defaultValue={state.q}
              onChange={(options) => setState({ ...state, q: options })} value={state.q}
              classNamePrefix={"search"}
              placeholder={t('chat.meeting-selector.search.placeholder', 'Search')}
              components={{
                Control: ({ children, ...props }: ControlProps<Option, true>) => {
                  return (
                    <components.Control {...props}>
                      <SearchIcon size={14} />
                      {children}
                    </components.Control>
                  );
                }
              }}
            />
            <Select
              isMulti
              defaultValue={state.participants}
              options={participants.map(({ name }) => ({ label: name, value: name })) as Option[]}
              onChange={(options) => setState({ ...state, participants: options })}
              classNamePrefix={"select"}
              placeholder={t('chat.meeting-selector.user', "Select user")}
              components={{
                Control: ({ children, ...props }: ControlProps<Option, true>) => {
                  return (
                    <components.Control {...props}>
                      <PersonCircle size={14} />
                      {children}
                    </components.Control>
                  );
                }
              }}
            />
            <Select
              isClearable
              defaultValue={state.daysAgo}
              options={translatedDateOptions}
              onChange={(option) => setState({ ...state, daysAgo: option })}
              classNamePrefix={"select"}
              placeholder={t('chat.meeting-selector.time.placeholder', 'All time')}
              components={{
                Control: ({ children, ...props }: ControlProps<{ label: string, value: number }, false>) => {
                  return (
                    <components.Control {...props}>
                      <CalendarIcon size={14} />
                      {children}
                    </components.Control>
                  );
                }
              }}
            />
            <Select
              isClearable={false}
              defaultValue={state.order}
              options={translatedOrderOptions}
              onChange={(option) => setState({ ...state, order: option })}
              classNamePrefix={"select"}
              components={{
                Control: ({ children, ...props }: ControlProps<{ label: string, value: SortOrder }, false>) => {
                  return (
                    <components.Control {...props}>
                      <SortArrow />
                      {children}
                    </components.Control>
                  );
                }
              }}
            />
          </SearchRow>
        </form>
        <MeetingList>
          {groupedMeetings.length === 0 && <Centered>{t("chat.meeting-selector.no-meetings", "You don't have any meetings")}</Centered>}
          {isLoading ? <LoadingAnimation><ThreeDots color='var(--gray400)' /></LoadingAnimation> : (
            groupedMeetings.map((group) => (
              <React.Fragment key={group.name}>
                {group.name !== 'All' && (
                  <GroupRow>
                    <input
                      type="checkbox"
                      value={group.order}
                      onChange={handleGroupSelected}
                      checked={group.meetings.every(({ id }) => selectedMeetingIds.has(id))}
                    /> {group.name}
                  </GroupRow>
                )}
                {group.meetings.map((meeting) => (
                  <MeetingRow
                    key={meeting.id}
                    meeting={meeting}
                    active={selectedMeetingIds.has(meeting.id)}
                    selected={selectedMeetingIds.has(meeting.id)}
                    onChange={handleMeetingSelected}
                  />
                ))}
              </React.Fragment>
            ))
          )}
        </MeetingList>
        <Row>
          <Label>
            <input
              type="checkbox"
              onChange={handleGroupSelected}
              value={-1}
              checked={groupedMeetings.every(({ meetings }) => meetings.every(({ id }) => selectedMeetingIds.has(id)))}
            />
            <span>{t('chat.meeting-selector.select-all', 'Select All')}</span>
          </Label>

          <LinkButton small onClick={() => onClose()}>{t('close', { ns: 'Actions', defaultValue: 'Close' })}</LinkButton>
          <Button
            small
            type="button"
            onClick={() => setScreen('review')}
          >{t('review', { ns: 'Actions', defaultValue: 'Review' })} ({selectedMeetingIds.size})</Button>
        </Row>
      </>
    )}
    {screen == 'review' && (
      <>
        <MeetingList>
          {selectedMeetingIds.size === 0 && <Centered>{t('chat.meeting-selector.no-meetings-selected', 'No meetings selected')}</Centered>}
          {meetings.filter(({ id }) => selectedMeetingIds.has(id)).map((meeting) => (
            <MeetingRow
              key={meeting.id}
              meeting={meeting}
              active={false}
              selected={selectedMeetingIds.has(meeting.id)}
              onChange={handleMeetingSelected}
            />
          ))}
        </MeetingList>
        <Row>
          <LinkButton small onClick={() => setScreen('select')}>{mode === 'create' ? t('back', { ns: 'Actions', defaultValue: 'Back'}) : t('edit', { ns: 'Actions', defaultValue: 'Edit'})}</LinkButton>
          <form onSubmit={handleSubmit}>
            <Button
              small
            >{isLoading ? <ThreeDots color="white" width="20px" height="10px" /> : `${initialMode === 'create' ? t('add', { ns: 'Actions', defaultValue: 'Add' }) : t('confirm', { ns: 'Actions', defaultValue: 'Confirm' })} (${selectedMeetingIds.size})`}</Button>
          </form>
        </Row>
      </>
    )}
  </Container>
}

