import { Meeting } from "./types";

import { Column, DateText, Row, MeetingTitle, RowContainer } from "./styles";
import { attendeesWithColor } from '../../../utils/genericUtils';

import Attendees from "../Attendees";
import { getMeetingIcon } from ".";
import { useTranslation } from "react-i18next";

function MeetingRow({ meeting, onChange, selected, active }: { meeting: Meeting, onChange: (id: number, selected: boolean) => void, selected: boolean, active: boolean }): JSX.Element {
  const startDate = new Date(meeting.meeting_start_date_time * 1000);
  const endDate = new Date(meeting.meeting_end_date_time * 1000);
  const attendeesByColor = attendeesWithColor(meeting.meeting_participants);

  const { t } = useTranslation();

  return <RowContainer $active={active} onClick={() => onChange(meeting.id, !selected)}>
    <div>
      <input type="checkbox" value={meeting.id} checked={selected} />
    </div>
    <Column $center>
      <DateText>{startDate.getDate()}</DateText>
      <DateText>{startDate.toLocaleString('default', { weekday: "short" })}</DateText>
    </Column>
    <Column>
      <span>{t('chat.meeting-selector.item.meeting', 'Meeting')}</span>
      <Row>
        {getMeetingIcon(meeting.meeting_platform)}
        <MeetingTitle>{meeting.meeting_name}</MeetingTitle>
      </Row>
    </Column>
    <Column>
      <span>{t('chat.meeting-selector.item.time', 'Time')}</span>
      <span>
        {startDate.toLocaleString('default', { hour12: true, hour: 'numeric', minute: '2-digit' })}
        -
        {endDate.toLocaleString('default', { hour12: true, hour: 'numeric', minute: '2-digit' })}
      </span>
    </Column>
    <Column>
      <span>{t('chat.meeting-selector.item.members', 'Members')}</span>
      <Attendees attendeesByColor={attendeesByColor} size="20px" />
    </Column>
  </RowContainer>
}


export default MeetingRow;
