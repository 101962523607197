import React, { ButtonHTMLAttributes, forwardRef } from "react";
import { Link } from "react-router-dom";
import styled, { css } from 'styled-components';

export const colors = {
  brandBriefly: "var(--brand-Primary)",
  brandSecondary: "var(--brandSecondary)",

  gray200: "var(--gray200)",
  gray300: "var(--gray300)",
  gray400: "var(--gray400)",
  gray500: "var(--gray500)",
  gray600: "var(--gray600)",
  gray800: "var(--gray800)",
  gray900: "var(--gray900)",

  green500: "var(--green500)",

  primary100: "var(--primary100)",
  primary200: "var(--primary200)",
  primary600: "var(--primary600)",

  secondary100: "var(--secondary100)",
  secondary200: "var(--secondary200)",
  secondary600: "var(--secondary600)",

  white: "var(--white)",
};

interface Theme {
  link: React.CSSProperties,
  buttons: {
    primary: React.CSSProperties,
    secondary: React.CSSProperties,
    link: React.CSSProperties,
    small: React.CSSProperties,
    inline: React.CSSProperties,
  },
  typography: {
    title: React.CSSProperties,
    body: React.CSSProperties,
  },
}

const theme: Theme = {
  link: {
    color: colors.brandBriefly,
  },
  buttons: {
    primary: {},
    secondary: {
      border: `1px solid ${colors.brandBriefly}`,
      backgroundColor: colors.gray900,
    },
    link: {
      width: 'auto',
      height: 'auto',
      border: 'none',
      borderRadius: 0,
      backgroundColor: 'transparent',
      background: 'transparent',
      display: 'inline',
      margin: 0,
      opacity: 1,
      textDecorationLine: 'underline',
    },
    small: {
      padding: '8px 32px',
      height: 'auto',
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '16px',
      margin: 0,
    },
    inline: {
      width: 'auto',
    },
  },
  typography: {
    title: {
      fontSize: 24,
      fontWeight: 900,
      lineHeight: 'normal',
      letterSpacing: -0.2,
    },
    body: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '22px',
      letterSpacing: 0.15,
      margin: 0,
    }
  },
};

export const A = styled(Link)`
  color: var(--brand-primary);

  &:hover {
    text-decoration: underline;
  }
`;

export const Body = styled.p`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.15px;
  margin: 0;
`;

export const BodyLight = styled(Body)`
  color: var(--textSecondary)
`;

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  forwardedAs?: string,
  secondary?: boolean,
  small?: boolean,
  icon?: boolean,
  inline?: boolean,
  link?: boolean,
  iconWithText?: boolean,
  transparent?: boolean,
  medium?: boolean,
  full?: boolean,
  outline?: boolean,
  disabled?: boolean,
  onClick?: React.MouseEventHandler<HTMLButtonElement>,
}

const baseButtonStyles = css<ButtonProps>`
  border: var(--standardBorder);
  border-radius: var(--standardRadius);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: fit-content;
  height: fit-content;
  padding: 12px 36px;
  font-size: 16px;
  background: linear-gradient(var(--focusGradientPrimary));
  color: var(--white);
  font-weight: 600;
  cursor: pointer;

  &:focus {
    outline: 1px solid var(--brand-secondary);
  }
  &:hover {
    opacity: .85;
  }
  &:disabled {
    background: var(--gray400);
    opacity: .5;
    cursor: not-allowed;
  }

  ${({ outline }) => outline && css`
    background: var(--gray200);
    border: var(--standardBorder);
    color: var(--gray700);
    &:hover {
      background: var(--gray100);
      box-shadow: 1px 1px 0px rgba(0,0,0,0.05);
    }
    &:active {
      background: var(--gray300);
      box-shadow: inset -1px 1px 0px rgba(0,0,0,0.05);
    }
  `}


  ${({ secondary }) => secondary && css`
    background: linear-gradient(var(--focusGradientSecondary));
    color: var(--textSecondary);
    font-weight: 500;
  `}

  ${({ transparent }) => transparent && css`
    border: none;
    background: transparent;
    color: var(--textPrimary);

    &:hover {
      background: linear-gradient(var(--focusGradientSecondary));
      color: var(--textPrimary);
    }
    &:disabled {
      background: transparent;
      opacity: .5;
      cursor: not-allowed;
    }

    svg {
      color: var(--brand-primary);
    }
  `}

  ${({ link }) => link && css`
    display: inline-flex;
    color: var(--brand-primary);
    border: none;
    border-radius: 0;
    background-color: transparent;
    background: transparent;
    margin: 0;
    opacity: 1;
    text-decoration: underline;
    padding: 0;

    &:disabled {
      background: transparent;
      opacity: .5;
      cursor: not-allowed;
    }
  `}

  ${({ icon }) => icon && css`
    color: var(--brand-primary);
    width: auto;
    height: auto;
    border: none;
    background-color: transparent;
    background: transparent;
    display: inline;
    margin: 0;
    opacity: 1;
    padding: 6px;
  `}

  ${({ iconWithText }) => iconWithText && css`
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 6px 12px;
    font-size: 14px;
    border-radius: var(--standardRadius);
    background: linear-gradient(var(--focusGradientSecondary));
    color: var(--textPrimary);
    font-weight: 400;

    svg {
      color: var(--brand-primary);
    }
  `}

  ${({ inline }) => inline && css`
    width: auto !important;
  `}
 ${({ small }) => small && css`
    padding: 6px 12px;
    font-size: 14px;
  `}
  ${({ medium }) => medium && css`
    padding: 8px 16px;
    font-size: 16px;
  `}
  ${({ full }) => full && css`
    width: 100%;
  `}
`;

const ButtonStyled = styled.button<ButtonProps>`
  ${baseButtonStyles}
`;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(({ forwardedAs = "button", children, className, ...rest }, ref) => {
  return (
    <ButtonStyled className={className} ref={ref} as={forwardedAs} {...rest} >
      {children}
    </ButtonStyled>
  );
});

Button.displayName = "Button";

export const ButtonLink = styled(Link) <ButtonProps>`
  ${baseButtonStyles}
`;

export const FlexEnd10 = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

export const WhiteButton = styled(Button) <{ $bgColor?: string; }>`
  color: var(--textPrimary);
  width: 100%;

  ${({ $bgColor }) => css`
    background: ${$bgColor ? $bgColor : 'var(--white)'}
  `}
`;

export const FlexStart10 = styled.div`
  display: flex;
  gap: 10px;
`;
export const FlexCenter10 = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;
export const FlexColumn10 = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

export const H2 = styled.h2`
  margin: 0 0 16px;
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
`;

export const HR = styled.hr`
  background: 'var(--gray600)';
  margin: 24px 0;
  width: 100%;
`;

export const Title = styled.span`
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: -0.2px;
`;

export default theme;
