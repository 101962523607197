import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { trackEvent } from '../../utils/genericUtils';
import styled, { css } from 'styled-components';
import { PlusIcon, SparklesIcon } from '../Icons';
import { ReactComponent as EditSvg } from '../Icons/edit.svg';
import Accordion from '../Reusable/Accordion';
import { Button } from '../../theme';
import { ChatThreadContext } from '../../ChatThreadProvider';
import Alert from '../Reusable/Alert';
import { apiPatch } from '../../utils/api';
import { ChatThread, UpdateFields } from '../Chat/ShowChat/types';
import { ThreeDots } from 'react-loader-spinner';

interface Props {
  isActive: boolean;
}

const RenameAlertBody = styled.div`
  display: flex;
  align-items: center;
  margin: 2rem 0;
  gap: 1rem;

  span {
    display: block;
    flex-shrink: 0;
  }
`;

const RenameInput = styled.input`
  box-sizing: border-box;
  border: 2px solid var(--brand-primary);
  border-radius: 8px;
  padding: 0.5em 1em;
  font-weight: lighter;
  color: var(--gray600);
  display: block;
  flex-basis: 100%;
  background: var(--gray300);
  font-size: 1rem;
  margin-right: 0;
  margin-left: auto;

  &:hover, &:focus {
    outline: none;
    border-color: var(--brand-secondary);
  }
`;

const HoveringButton = styled.button`
  opacity: 0;
  position: absolute;
  background: var(--gray300);
  border: none;
  border-radius: 1rem;
  aspect-ratio: 1;
  width: 2rem;
  cursor: pointer;
  color: var(--brand-secondary);
  right: 0.4rem;

  &:hover {
    background: var(--secondary600);
    color: var(--gray300);
  }

  svg {
    width: 0.9rem;
    height: 0.9rem;
  }

  svg[aria-label="edit"] {
      position: relative;
      left: 3%;
      bottom: -3%;
  }
`;

const Row = styled.div<{ $isActive?: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  font-size: 0.9rem;
  padding: 8px 0;

  a {
    display: flex;
    gap: 8px;
    width: 100%;
    border-radius: var(--standardRadius);
    cursor: pointer;
    ${({ $isActive }) => $isActive && css`
      font-weight: bold;
      color: var(--primary600);
    `}
  }

  &:not(:first-child) {
    border-radius: var(--standardRadius);
    padding: 10px 8px;
  }

  &:hover:not(:first-child) {
    background: var(--secondary200);
  }

  &:hover ${HoveringButton} {
    opacity: 1;
  }
`;

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(from var(--background) r g b / 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
`;

interface RenameChatDialogProps {
  chatId: string | null;
  oldName: string | null;
  onClose: () => void;
  onSuccess: (newName: string) => void;
}


const RenameChatDialog = (props: RenameChatDialogProps) => {
  const { chatId, onSuccess, onClose, oldName } = props;
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState<string>(oldName ?? '');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    setInputValue(oldName ?? '');
  }, [oldName])

  const handleSubmit = async () => {
    const cleansedInput = inputValue.trim();
    if(cleansedInput.length > 0) {
      setIsSubmitting(true);
      try {
        await apiPatch<UpdateFields, ChatThread, 'chat_thread'>({
          path: `/chat-threads/${chatId}`,
          body: { 'rename_title': cleansedInput }
        });
        onSuccess(cleansedInput);
        onClose();
      } finally {
        setIsSubmitting(false);
      }
    }
  }

  return (
    <>
      {isSubmitting &&
        <LoadingOverlay>
          <ThreeDots color='var(--brand-primary)' />
        </LoadingOverlay>
      }
      <Alert
        open={chatId !== null}
        onOpenChange={(effect) => { if (effect === false) onClose(); }}
        title={t('chat.rename.title', 'Rename Chat')}
        cancelContent={t('cancel', { ns: 'Actions', defaultValue: 'Cancel' })}
        confirmContent={t('rename', { ns: 'Actions', defaultValue: "Rename" })}
        onConfirm={() => handleSubmit()}>
          <RenameAlertBody>
            <span>{t('chat.rename.new-name-label', 'New Name:')}</span>
            <RenameInput placeholder={oldName ?? undefined} value={inputValue} onChange={(e) => setInputValue(e.target.value)} type="text" />
          </RenameAlertBody>
      </Alert>
    </>
  )
}

const Chat = ({ isActive }: Props) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { chatThreads: chats, loadChatThreads } = useContext(ChatThreadContext);

  const [renameChatId, setRenameChatId] = useState<string | null>(null);
  const chatToRename = renameChatId !== null ? chats.find((chat) => chat.uuid === renameChatId) : undefined;

  const handleClick = () => {
    trackEvent("briefly-chat-navigate", {});
  };

  const handleRenameSuccess = () => {
    loadChatThreads(new AbortController().signal);
    trackEvent("briefly-chat-rename", {});
  }

  return (
    <Accordion
      title={t('root-sidebar.chat.dropdown', 'Chat')}
      icon={<span style={{ color: 'var(--primary600)' }}><SparklesIcon size={16} /></span>}
      defaultOpen={isActive}
      isActive={isActive}
    >
      <RenameChatDialog
        chatId={renameChatId}
        oldName={chatToRename?.title ?? null}
        onSuccess={() => handleRenameSuccess()}
        onClose={() => setRenameChatId(null)}
      />
      <Row>
        <Link to="/chat" style={{ display: 'flex', alignItems: 'center' }}>
          <Button full>
            <PlusIcon />
            <span>
              {t('root-sidebar.chat.new-chat', 'New Chat')}
            </span>
          </Button>
        </Link>
      </Row>
      {chats.sort((a, b) => b.created_on - a.created_on).map((chat) => (
        <Row key={chat.uuid} $isActive={pathname.includes(chat.uuid)}>
          <Link to={`/chat/${chat.uuid}`} onClick={handleClick}>
            {chat.title}
          </Link>
          <HoveringButton onClick={() =>  setRenameChatId(chat.uuid)}>
            <EditSvg aria-label="edit"/>
          </HoveringButton>
        </Row>
      ))}
    </Accordion>
  );
};

export default Chat;
