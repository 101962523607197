import Caret from '../Icons/Caret';
import styled, { css } from 'styled-components';


const Details = styled.details`
  &[open] .marker {
    transform: rotate(180deg);
  }
`;
const Summary = styled.summary <{ $isActive?: boolean; }>`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 12px 16px;
  cursor: pointer;
  border-radius: var(--standardRadius);
  font-weight: 500;

  &:focus-visible {
    outline: 1px solid var(--brand-secondary);
  }

  &::-webkit-details-marker {
   display: none;
  }

  &:hover {
    border: var(--standardBorder);
  }

  .marker {
    margin-left: auto;
  }

  border: 1px solid var(--background);
  ${({ $isActive }) => $isActive && css`
    background: linear-gradient(var(--focusGradientSecondary));
    border: var(--standardBorder);
  `}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  padding-left: 20px;
  max-height: 550px;
  overflow-y: auto;
`;

interface Props extends React.PropsWithChildren {
  defaultOpen?: boolean;
  icon?: JSX.Element;
  title: string | JSX.Element;
  className?: string;
  isActive?: boolean;
}



const Accordion = ({ defaultOpen, icon, title, children, className, isActive }: Props) => {
  return (
    <Details open={defaultOpen} className={className}>
      <Summary $isActive={isActive}>
        {icon}
        {title}
        <Caret className="marker" />
      </Summary>
      <Content>{children}</Content>
    </Details>
  );
};


export default Accordion;
