import { Message } from "./types";
import { formatDateDDMMYYYY } from '../../../utils/genericUtils';
import { Meeting } from "../../Reusable/MeetingFilter/types";

export function cleanMessage(original: Message, sources: Record<string, number>): Message {
  const message: Message = { ...original };

  message.content.forEach((content) => {
    if (!content.text.annotations) return;

    content.text.value = content.text.annotations.reverse().reduce<string>((str, annotation) => {
      const { file_citation, text } = annotation;
      const id = sources[file_citation.file_id]
      const sourceText = id ? `[*(${id})*](/meeting/bkend-${id})` : '';

      return str.replace(text, sourceText)
    }, content.text.value);
  });

  return message;
}

export function formatDateRange(transcripts: Meeting[]): string {
  const first = transcripts[0];

  if (!first) return "";

  const last = transcripts[transcripts.length - 1];
  const startDate = new Date(first.meeting_start_date_time * 1000);
  const endDate = new Date(last.meeting_end_date_time * 1000);
  return `${formatDateDDMMYYYY(startDate)} - ${formatDateDDMMYYYY(endDate)}`
}
