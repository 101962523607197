import { SvgSize } from "./types";

const AppSquares = (props: SvgSize) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 17" style={{ width: props?.size ?? '17', height: props?.size ?? '17' }} {...props}>
    <title>Box Widget Icons</title>
    <path d="M5.79545 7.72727H1.93182C1.41947 7.72727 0.928103 7.52374 0.565816 7.16146C0.20353 6.79917 0 6.3078 0 5.79545V1.93182C0 1.41947 0.20353 0.928103 0.565816 0.565816C0.928103 0.20353 1.41947 0 1.93182 0H5.79545C6.3078 0 6.79917 0.20353 7.16146 0.565816C7.52374 0.928103 7.72727 1.41947 7.72727 1.93182V5.79545C7.72727 6.3078 7.52374 6.79917 7.16146 7.16146C6.79917 7.52374 6.3078 7.72727 5.79545 7.72727ZM1.93182 1.28788C1.76103 1.28788 1.59725 1.35572 1.47648 1.47648C1.35572 1.59725 1.28788 1.76103 1.28788 1.93182V5.79545C1.28788 5.96624 1.35572 6.13003 1.47648 6.25079C1.59725 6.37155 1.76103 6.43939 1.93182 6.43939H5.79545C5.96624 6.43939 6.13003 6.37155 6.25079 6.25079C6.37155 6.13003 6.43939 5.96624 6.43939 5.79545V1.93182C6.43939 1.76103 6.37155 1.59725 6.25079 1.47648C6.13003 1.35572 5.96624 1.28788 5.79545 1.28788H1.93182Z" fill="black" />
    <path d="M5.79545 7.72727H1.93182C1.41947 7.72727 0.928103 7.52374 0.565816 7.16146C0.20353 6.79917 0 6.3078 0 5.79545V1.93182C0 1.41947 0.20353 0.928103 0.565816 0.565816C0.928103 0.20353 1.41947 0 1.93182 0H5.79545C6.3078 0 6.79917 0.20353 7.16146 0.565816C7.52374 0.928103 7.72727 1.41947 7.72727 1.93182V5.79545C7.72727 6.3078 7.52374 6.79917 7.16146 7.16146C6.79917 7.52374 6.3078 7.72727 5.79545 7.72727ZM1.93182 1.28788C1.76103 1.28788 1.59725 1.35572 1.47648 1.47648C1.35572 1.59725 1.28788 1.76103 1.28788 1.93182V5.79545C1.28788 5.96624 1.35572 6.13003 1.47648 6.25079C1.59725 6.37155 1.76103 6.43939 1.93182 6.43939H5.79545C5.96624 6.43939 6.13003 6.37155 6.25079 6.25079C6.37155 6.13003 6.43939 5.96624 6.43939 5.79545V1.93182C6.43939 1.76103 6.37155 1.59725 6.25079 1.47648C6.13003 1.35572 5.96624 1.28788 5.79545 1.28788H1.93182Z" fill="url(#paint0_linear_101_6)" />
    <path d="M15.0682 7.72727H11.2046C10.6922 7.72727 10.2008 7.52374 9.83855 7.16146C9.47627 6.79917 9.27274 6.3078 9.27274 5.79545V1.93182C9.27274 1.41947 9.47627 0.928103 9.83855 0.565816C10.2008 0.20353 10.6922 0 11.2046 0H15.0682C15.5805 0 16.0719 0.20353 16.4342 0.565816C16.7965 0.928103 17 1.41947 17 1.93182V5.79545C17 6.3078 16.7965 6.79917 16.4342 7.16146C16.0719 7.52374 15.5805 7.72727 15.0682 7.72727ZM11.2046 1.28788C11.0338 1.28788 10.87 1.35572 10.7492 1.47648C10.6285 1.59725 10.5606 1.76103 10.5606 1.93182V5.79545C10.5606 5.96624 10.6285 6.13003 10.7492 6.25079C10.87 6.37155 11.0338 6.43939 11.2046 6.43939H15.0682C15.239 6.43939 15.4028 6.37155 15.5235 6.25079C15.6443 6.13003 15.7121 5.96624 15.7121 5.79545V1.93182C15.7121 1.76103 15.6443 1.59725 15.5235 1.47648C15.4028 1.35572 15.239 1.28788 15.0682 1.28788H11.2046Z" fill="black" />
    <path d="M15.0682 7.72727H11.2046C10.6922 7.72727 10.2008 7.52374 9.83855 7.16146C9.47627 6.79917 9.27274 6.3078 9.27274 5.79545V1.93182C9.27274 1.41947 9.47627 0.928103 9.83855 0.565816C10.2008 0.20353 10.6922 0 11.2046 0H15.0682C15.5805 0 16.0719 0.20353 16.4342 0.565816C16.7965 0.928103 17 1.41947 17 1.93182V5.79545C17 6.3078 16.7965 6.79917 16.4342 7.16146C16.0719 7.52374 15.5805 7.72727 15.0682 7.72727ZM11.2046 1.28788C11.0338 1.28788 10.87 1.35572 10.7492 1.47648C10.6285 1.59725 10.5606 1.76103 10.5606 1.93182V5.79545C10.5606 5.96624 10.6285 6.13003 10.7492 6.25079C10.87 6.37155 11.0338 6.43939 11.2046 6.43939H15.0682C15.239 6.43939 15.4028 6.37155 15.5235 6.25079C15.6443 6.13003 15.7121 5.96624 15.7121 5.79545V1.93182C15.7121 1.76103 15.6443 1.59725 15.5235 1.47648C15.4028 1.35572 15.239 1.28788 15.0682 1.28788H11.2046Z" fill="url(#paint1_linear_101_6)" />
    <path d="M5.79545 17H1.93182C1.41947 17 0.928103 16.7965 0.565816 16.4342C0.20353 16.0719 0 15.5805 0 15.0682V11.2046C0 10.6922 0.20353 10.2008 0.565816 9.83855C0.928103 9.47627 1.41947 9.27274 1.93182 9.27274H5.79545C6.3078 9.27274 6.79917 9.47627 7.16146 9.83855C7.52374 10.2008 7.72727 10.6922 7.72727 11.2046V15.0682C7.72727 15.5805 7.52374 16.0719 7.16146 16.4342C6.79917 16.7965 6.3078 17 5.79545 17ZM1.93182 10.5606C1.76103 10.5606 1.59725 10.6285 1.47648 10.7492C1.35572 10.87 1.28788 11.0338 1.28788 11.2046V15.0682C1.28788 15.239 1.35572 15.4028 1.47648 15.5235C1.59725 15.6443 1.76103 15.7121 1.93182 15.7121H5.79545C5.96624 15.7121 6.13003 15.6443 6.25079 15.5235C6.37155 15.4028 6.43939 15.239 6.43939 15.0682V11.2046C6.43939 11.0338 6.37155 10.87 6.25079 10.7492C6.13003 10.6285 5.96624 10.5606 5.79545 10.5606H1.93182Z" fill="black" />
    <path d="M5.79545 17H1.93182C1.41947 17 0.928103 16.7965 0.565816 16.4342C0.20353 16.0719 0 15.5805 0 15.0682V11.2046C0 10.6922 0.20353 10.2008 0.565816 9.83855C0.928103 9.47627 1.41947 9.27274 1.93182 9.27274H5.79545C6.3078 9.27274 6.79917 9.47627 7.16146 9.83855C7.52374 10.2008 7.72727 10.6922 7.72727 11.2046V15.0682C7.72727 15.5805 7.52374 16.0719 7.16146 16.4342C6.79917 16.7965 6.3078 17 5.79545 17ZM1.93182 10.5606C1.76103 10.5606 1.59725 10.6285 1.47648 10.7492C1.35572 10.87 1.28788 11.0338 1.28788 11.2046V15.0682C1.28788 15.239 1.35572 15.4028 1.47648 15.5235C1.59725 15.6443 1.76103 15.7121 1.93182 15.7121H5.79545C5.96624 15.7121 6.13003 15.6443 6.25079 15.5235C6.37155 15.4028 6.43939 15.239 6.43939 15.0682V11.2046C6.43939 11.0338 6.37155 10.87 6.25079 10.7492C6.13003 10.6285 5.96624 10.5606 5.79545 10.5606H1.93182Z" fill="url(#paint2_linear_101_6)" />
    <path d="M13.1364 17C12.3722 17 11.6252 16.7734 10.9899 16.3489C10.3545 15.9243 9.85927 15.3209 9.56684 14.6149C9.27441 13.9089 9.1979 13.1321 9.34698 12.3826C9.49606 11.6331 9.86403 10.9447 10.4044 10.4044C10.9447 9.86403 11.6331 9.49606 12.3826 9.34698C13.1321 9.1979 13.9089 9.27441 14.6149 9.56684C15.3209 9.85927 15.9243 10.3545 16.3489 10.9899C16.7734 11.6252 17 12.3722 17 13.1364C16.999 14.1608 16.5916 15.1429 15.8672 15.8672C15.1429 16.5916 14.1608 16.999 13.1364 17ZM13.1364 10.5606C12.6269 10.5606 12.1289 10.7117 11.7054 10.9947C11.2818 11.2777 10.9516 11.68 10.7567 12.1507C10.5617 12.6213 10.5107 13.1392 10.6101 13.6389C10.7095 14.1385 10.9548 14.5975 11.315 14.9577C11.6753 15.3179 12.1342 15.5633 12.6339 15.6626C13.1335 15.762 13.6514 15.711 14.1221 15.5161C14.5927 15.3211 14.995 14.991 15.278 14.5674C15.5611 14.1438 15.7121 13.6458 15.7121 13.1364C15.7121 12.4532 15.4408 11.7981 14.9577 11.315C14.4747 10.832 13.8195 10.5606 13.1364 10.5606Z" fill="black" />
    <path d="M13.1364 17C12.3722 17 11.6252 16.7734 10.9899 16.3489C10.3545 15.9243 9.85927 15.3209 9.56684 14.6149C9.27441 13.9089 9.1979 13.1321 9.34698 12.3826C9.49606 11.6331 9.86403 10.9447 10.4044 10.4044C10.9447 9.86403 11.6331 9.49606 12.3826 9.34698C13.1321 9.1979 13.9089 9.27441 14.6149 9.56684C15.3209 9.85927 15.9243 10.3545 16.3489 10.9899C16.7734 11.6252 17 12.3722 17 13.1364C16.999 14.1608 16.5916 15.1429 15.8672 15.8672C15.1429 16.5916 14.1608 16.999 13.1364 17ZM13.1364 10.5606C12.6269 10.5606 12.1289 10.7117 11.7054 10.9947C11.2818 11.2777 10.9516 11.68 10.7567 12.1507C10.5617 12.6213 10.5107 13.1392 10.6101 13.6389C10.7095 14.1385 10.9548 14.5975 11.315 14.9577C11.6753 15.3179 12.1342 15.5633 12.6339 15.6626C13.1335 15.762 13.6514 15.711 14.1221 15.5161C14.5927 15.3211 14.995 14.991 15.278 14.5674C15.5611 14.1438 15.7121 13.6458 15.7121 13.1364C15.7121 12.4532 15.4408 11.7981 14.9577 11.315C14.4747 10.832 13.8195 10.5606 13.1364 10.5606Z" fill="url(#paint3_linear_101_6)" />
    <defs>
      <linearGradient id="paint0_linear_101_6" x1="0" y1="3.86364" x2="7.72727" y2="3.86364" gradientUnits="userSpaceOnUse">
        <stop stopColor="#F0631B" />
        <stop offset="1" stopColor="#BE01B6" />
      </linearGradient>
      <linearGradient id="paint1_linear_101_6" x1="9.27274" y1="3.86364" x2="17" y2="3.86364" gradientUnits="userSpaceOnUse">
        <stop stopColor="#F0631B" />
        <stop offset="1" stopColor="#BE01B6" />
      </linearGradient>
      <linearGradient id="paint2_linear_101_6" x1="0" y1="13.1364" x2="7.72727" y2="13.1364" gradientUnits="userSpaceOnUse">
        <stop stopColor="#F0631B" />
        <stop offset="1" stopColor="#BE01B6" />
      </linearGradient>
      <linearGradient id="paint3_linear_101_6" x1="9.27274" y1="13.1364" x2="17" y2="13.1364" gradientUnits="userSpaceOnUse">
        <stop stopColor="#F0631B" />
        <stop offset="1" stopColor="#BE01B6" />
      </linearGradient>
    </defs>
  </svg>

);

export default AppSquares;