import React from 'react';

const QuestionMark = (props: React.HTMLAttributes<HTMLOrSVGElement>) => (
  <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Question Mark Icon</title>
    <path d="M8.33099 5.5493C8.70657 5.17371 8.89437 4.723 8.89437 4.19718C8.89437 3.53991 8.65962 2.97653 8.19014 2.50704C7.72066 2.03756 7.15728 1.80282 6.5 1.80282C5.84272 1.80282 5.27934 2.03756 4.80986 2.50704C4.48644 2.83046 4.27442 3.19844 4.1738 3.61098C4.09638 3.92838 4.37048 4.19718 4.69718 4.19718V4.19718C5.02389 4.19718 5.27519 3.91515 5.43788 3.63184C5.49425 3.53368 5.5666 3.44044 5.65493 3.35211C5.89906 3.10798 6.18075 2.98592 6.5 2.98592C6.81925 2.98592 7.10094 3.10798 7.34507 3.35211C7.5892 3.59624 7.71127 3.87793 7.71127 4.19718C7.71127 4.51643 7.5892 4.79812 7.34507 5.04225L6.61268 5.80282C6.14319 6.30986 5.90845 6.87324 5.90845 7.49296V7.49296C5.90845 7.66409 6.04718 7.80282 6.21831 7.80282H6.5C6.8267 7.80282 7.0831 7.53365 7.16565 7.21755C7.26853 6.82354 7.47858 6.45525 7.79578 6.11268L8.33099 5.5493ZM6.5 10.1972C6.8267 10.1972 7.09155 9.93234 7.09155 9.60563V9.60563C7.09155 9.27893 6.8267 9.01408 6.5 9.01408V9.01408C6.1733 9.01408 5.90845 9.27893 5.90845 9.60563V9.60563C5.90845 9.93234 6.1733 10.1972 6.5 10.1972V10.1972ZM2.24648 1.77465C3.42958 0.591549 4.84742 0 6.5 0C8.15258 0 9.56103 0.591549 10.7254 1.77465C11.9085 2.93897 12.5 4.34742 12.5 6C12.5 7.65258 11.9085 9.07042 10.7254 10.2535C9.56103 11.4178 8.15258 12 6.5 12C4.84742 12 3.42958 11.4178 2.24648 10.2535C1.08216 9.07042 0.5 7.65258 0.5 6C0.5 4.34742 1.08216 2.93897 2.24648 1.77465Z" fill="currentColor" />
  </svg>
);

export default QuestionMark;
