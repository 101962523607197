interface KeyInsightGroup {
  topicHeading: string;
  bulletPoints: string[];
}

export const groupKeyInsightsFromText = (text: string): KeyInsightGroup[] => {
  const splittedLines = text.split('\n\n');

  const insightGroups: KeyInsightGroup[] = splittedLines.reduce<KeyInsightGroup[]>((accumulation, currentLine) => {
    if(!currentLine.startsWith('• ')) {
      accumulation.push({
        topicHeading: removeDiscussionTopicPrefix(currentLine),
        bulletPoints: [],
      })

      return accumulation;
    }


    accumulation.at(-1)?.bulletPoints.push(currentLine);
    return accumulation;
  }, [])

  return insightGroups;
}

export const removeDiscussionTopicPrefix = (text: string): string => {
  // Use regex to remove any words right after the start of the line and the colon
  return text.replace(/^[^:]*:\s*/, '');
}
