import React from "react";

const DoubleLeftArrow = (props: React.HTMLAttributes<HTMLOrSVGElement>) => <svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
  <title>Left Arrow Icon</title>
  <path
    d="M8.90875 8.11596C8.71265 8.32626 8.3816 8.33308 8.177 8.13104L4.5 4.5L8.177 0.868959C8.3816 0.66692 8.71265 0.673742 8.90875 0.884039C9.09829 1.0873 9.0903 1.40487 8.89079 1.59835L5.89873 4.5L8.89079 7.40165C9.0903 7.59513 9.09829 7.9127 8.90875 8.11596Z"
    fill="currentColor" />
  <path
    d="M5.90875 8.11596C5.71265 8.32626 5.3816 8.33308 5.177 8.13104L1.5 4.5L5.177 0.86896C5.3816 0.66692 5.71265 0.673742 5.90875 0.884039V0.884039C6.09829 1.0873 6.0903 1.40487 5.89079 1.59835L2.89873 4.5L5.89079 7.40165C6.0903 7.59513 6.09829 7.9127 5.90875 8.11596V8.11596Z"
    fill="currentColor" />
</svg>;

export default DoubleLeftArrow;