import { useTranslation } from 'react-i18next';
import { BodyLight, H2 } from '../../theme';
import styled from 'styled-components';
import LanguageSelector from '../controls/LanguageSelector';

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 25px;
`;

function SettingsTabLanguage() {
  const { t } = useTranslation();


  return (
    <div>
      <H2>
        {t('settings.language.tab-label', 'Language')}
      </H2>
      <Row>
        <div>
          <span>{t('settings.language.ui-language.title', 'Application Language')}</span>
          <BodyLight>
            {t('settings.language.ui-language.description', 'Change the current UI language, this does not affect your transcriptions or AI Outputs.')}
          </BodyLight>
        </div>

        <LanguageSelector />
      </Row>
    </div>
  );
}


export { SettingsTabLanguage };