
import { disconnectGoogleCalendar, } from '../../utils/genericUtils';
import { useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ThreeDots } from 'react-loader-spinner';
import { Trans, useTranslation } from 'react-i18next';
import { HR } from '../../theme';
import { connectedToEmailBadge } from './SettingsTabIntegrations';
import CalendarIntegrationInPopup from './CalendarIntegrationsPopup';
import styled from 'styled-components';

const ZoomOption = styled.div<{$selected: boolean}>`
  background: var(--backgroundSecondary);
  border: 1px solid var(--textPrimary);
  border-radius: 8px;
  display: flex;
  padding: 24px;
  cursor: pointer;
  margin-top: 20px;

  ${({ $selected }) => $selected && `
    background: var(--background);
    border: 2px solid var(--brand-primary);
  `}
`;

type BigRadioProps = {
  selected: boolean
}

const BigRadio = ({ selected }: BigRadioProps) => {
  if(selected) {
    return (
      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="15" cy="15" r="14" fill="transparent" stroke="var(--brand-primary)" strokeWidth="2" />
        <circle cx="15" cy="15" r="5" fill="var(--brand-primary)" />
      </svg>
    )
  }

  return (
    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="15.5" cy="15" r="14.5" fill="transparent" stroke="#2C2C2C" />
    </svg>
  )
}

interface Props {
  email_google_calendar: string,
  forceRenewUserData: () => void,
  is_integration_active_google_calendar: boolean,
  isZoomIntegrationStatusLoading: boolean,
  onZoomIntegrationStatusChange: (string) => void,
  zoom_integration_status: string,
}


const ManageZoom = ({ is_integration_active_google_calendar, email_google_calendar, zoom_integration_status, onZoomIntegrationStatusChange, forceRenewUserData, isZoomIntegrationStatusLoading }: Props) => {

  const navigate = useNavigate();
  const { t } = useTranslation();

  const botShouldJoinAllCalls = zoom_integration_status === 'ZOOM_INTEGRATION_STATUS_JOIN_ALL_MEETINGS';

  return <>
    <div style={{ marginBottom: 10, cursor: 'pointer' }} onClick={() => { navigate('/settings/integrations'); }}>
      <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.81749 0.768078C8.42529 0.347484 7.7632 0.33384 7.35401 0.737919L0 8L7.35401 15.2621C7.7632 15.6662 8.42529 15.6525 8.81749 15.2319C9.19657 14.8254 9.18061 14.1903 8.78158 13.8033L2.79747 8L8.78158 2.19671C9.18061 1.80974 9.19657 1.17461 8.81749 0.768078Z" fill="red" />
      </svg>
      <span style={{ fontSize: 14, fontWeight: 500, position: 'relative', left: 8, bottom: 2 }}>
        {t('back', { ns: 'Actions', defaultValue: 'Back' })}
      </span>
    </div>
    <div id="settings-integrations" className="integrations-manage-zoom">
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
        <h2 style={{ margin: 0 }}>Zoom integration</h2>
        {/* @ts-expect-error window */}
        <a onClick={() => window.open('https://www.brieflyai.com/zoom-documentation', '_blank').open()} style={{ cursor: 'pointer', margin: 0 }}>
          {t('settings.zoom-admin.faq', 'FAQ')}
        </a>
      </div>

      <HR />

      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
        <h3>{t('settings.zoom-admin.calendar.title', 'Connect your Calendar')}</h3>
        <div style={{display: 'flex', gap: '0.5rem'}}>
          {is_integration_active_google_calendar ? connectedToEmailBadge(email_google_calendar, t) : connectedToEmailBadge('Not connected', t)}
          {is_integration_active_google_calendar ? <a style={{ cursor: 'pointer' }} onClick={disconnectGoogleCalendar}>
            {t('disconnect', { ns: 'Actions', defaultValue: 'Disconnect' })}
          </a> : ''}
        </div>
      </div>

      <p style={{ marginTop: 0 }}>
        {t('settings.zoom-admin.calendar.description', 'Briefly Bot functions exclusively with meetings scheduled via the calendar account that\'s linked to your Briefly profile. Read the instruction on')}
        {' '}
        <a href="https://www.brieflyai.com/zoom-documentation" target="_blank" style={{ textDecoration: 'underline', color: '#E44867' }} rel="noreferrer">
          {t('settings.zoom-admin.calendar.description-link', 'how to correctly setup Zoom calls in Google Calendar')}
        </a>.
      </p>

      {is_integration_active_google_calendar ? <>
        <div>
          <h3>{t('settings.zoom-admin.join-mode.title', 'How should bot join your calls?')}</h3>
          {isZoomIntegrationStatusLoading ? <>
            <div style={{ height: 220, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <ThreeDots
                height="20"
                width="20"
                radius="9"
                color="var(--brand-primary)"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                visible={true} />
            </div>
          </> : <>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <ZoomOption $selected={!botShouldJoinAllCalls} className="hubspot-option" onClick={() => {
                onZoomIntegrationStatusChange('ZOOM_INTEGRATION_STATUS_INVITE_ONLY');
              }}>
                <div style={{ display: 'inline-flex', width: 30 }}>
                  <BigRadio selected={!botShouldJoinAllCalls} />
                </div>
                <div style={{ display: 'inline-block', width: 'calc(100% - 40px)', marginLeft: 10 }}>
                  <span>{t('settings.zoom-admin.join-mode.invite.title', 'Invite as Needed')}</span>
                  <br />
                  <span style={{ display: 'inline-block', marginTop: 5 }}>
                    <Trans
                      i18nKey={'settings.zoom-admin.join-mode.invite.description'}
                      values={{ botEmail: 'bot@breflyai.com' }}
                      defaults='You decide when Briefly Note-Taker joins by adding <strong><i> {{ botEmail }} </i></strong> to meeting invites. Remember, the invite must include a Zoom meeting link.' />
                  </span>
                </div>
              </ZoomOption>

              <ZoomOption $selected={botShouldJoinAllCalls} className="hubspot-option" onClick={() => {
                onZoomIntegrationStatusChange('ZOOM_INTEGRATION_STATUS_JOIN_ALL_MEETINGS');
              }}>
                <div style={{ display: 'inline-flex', width: 30 }}>
                  <BigRadio selected={botShouldJoinAllCalls} />
                </div>
                <div style={{ display: 'inline-block', width: 'calc(100% - 40px)', marginLeft: 10 }}>
                  <span>{t('settings.zoom-admin.join-mode.auto.title', 'Automatic Attendance')}</span>
                  <br />
                  <span style={{ display: 'inline-block', marginTop: 5 }}>
                    {t('settings.zoom-admin.join-mode.auto.description', 'BrieflyBot will attend all meetings listed in your calendar that have a Zoom link. No need for manual invites.')}
                  </span>
                </div>
              </ZoomOption>
            </div>
          </>}
        </div>

        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: 10
        }}>
          <p style={{ fontSize: 14, marginTop: 0, margin: 0 }}>{t('settings.zoom-admin.email.label', 'A summary email will be sent after the call ends as per your settings')}</p>
          <a onClick={() => navigate('/settings/ai-summary-email')} style={{ cursor: 'pointer' }}>
            {t('settings.zoom-admin.email.link', 'Manage AI summary email')}
          </a>
        </div>
      </> : <>

        <p>{t('settings.zoom-admin.no-calendar.title', 'Link your calendar to track your Zoom meetings:')}</p>

        <GoogleOAuthProvider clientId="576404817524-ss93f3dua36hvaum9oi4vqrmu8anc6o3.apps.googleusercontent.com">
          <CalendarIntegrationInPopup forceRenewUserData={forceRenewUserData} style={{ width: 240 }} />
        </GoogleOAuthProvider>

      </>}

    </div>
  </>;
};

export default ManageZoom;