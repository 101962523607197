import { ButtonLink } from '../../theme';

export function BlockedAccessState({ organizationData }) {
  return <div className="edge-case-state" style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <div style={{ maxWidth: 500, textAlign: 'center' }}>
      <img src={organizationData?.logo} width="100" height="100" style={{ borderRadius: '100%' }} />

      <h1 style={{ fontSize: 20, marginTop: 25, lineHeight: '28px' }}>Welcome to Briefly! Your organization already has a Briefly Team Account.</h1>

      <h6 style={{ marginTop: 20, marginBottom: 20, fontSize: 16, }}>You need to request access to join {organizationData?.name} Team account.</h6>

      <p style={{ fontSize: 14, color: 'var(--textSecondary)', marginBottom: 20 }} className="blocked-access-text">
        {organizationData?.free_access_blocked_message}
      </p>

      <ButtonLink style={{ margin: "auto" }} to="https://brieflyai.com" target="_blank" rel="noreferrer">
        Find out more about Briefly
      </ButtonLink>
    </div>
  </div>;
}

export default BlockedAccessState;