import React from 'react';
import { SvgSize } from './types';

const SearchIcon = (props: SvgSize) => (
  <svg width={props?.size ?? '12'} height={props?.size ?? '12'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" fill="none" {...props}>
    <title>Search Icon</title>
    <path d="M4.74338 9.22315C5.76731 9.22315 6.72339 8.89932 7.50059 8.3596L10.8095 11.8021C10.9452 11.934 11.1241 12 11.3092 12C11.7101 12 12 11.7002 12 11.3164C12 11.1365 11.9383 10.9685 11.8026 10.8366L8.51218 7.4061C9.12284 6.62651 9.48676 5.66102 9.48676 4.61157C9.48676 2.07491 7.35255 0 4.74338 0C2.12804 0 0 2.07491 0 4.61157C0 7.14824 2.12804 9.22315 4.74338 9.22315ZM4.74338 7.65767C2.9037 7.65767 1.60467 6.27 1.60467 4.61157C1.60467 2.85 2.9037 1.56548 4.74338 1.56548C6.38814 1.56548 7.88209 2.85 7.88209 4.61157C7.88209 6.27 6.38814 7.65767 4.74338 7.65767Z" fill="currentColor" />
  </svg>
);

export default SearchIcon;
