import styled from "styled-components";

const Label = styled.label`
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
  &:disabled {
    color: var(--textSecondary);
    cursor: not-allowed;
  }
`;
const Input = styled.input`
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  color: currentColor;
  width: 1.5em;
  height: 1.5em;
  border: var(--secondaryBorder);
  border-radius: var(--smallRadius);
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  &:before {
    content: "";
    width: 1em;
    height: 1em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    background-color: var(--brand-primary);
  }

  &:checked {
    &:before {
      transform: scale(1);
    }
  }
`;


interface Props {
  isChecked: boolean;
  label: string;
  setIsChecked: (boolean) => void;
}

export function Checkbox({ isChecked, setIsChecked, label }: Props) {
  return (
    <Label>
      <Input type="checkbox" checked={isChecked} onClick={() => {
        setIsChecked(!isChecked);
      }} />
      <span>{label}</span>
    </Label>
  );

};
export default Checkbox;