import { colors } from "../../theme";
import styled, { css } from 'styled-components';

export { Button, Body, Title } from '../../theme';

export const Container = styled.div`
display: flex;
flex-direction: column;
gap: 22px;
`;

export const Hr = styled.div`
display: flex;
align-items: center;
gap: 8px;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
letter-spacing: 0.15px;
color: ${colors.gray600};
&:before {
  content: '';
  display: inline-block;
  border-top: 1px solid;
  border-color: ${colors.gray500};
  flex-grow: 1;
}
&:after {
  content: '';
  display: inline-block;
  border-top: 1px solid;
  border-color: ${colors.gray500};
  flex-grow: 1;
}
`;

export const FormGrid = styled.form`
display: grid;
grid-template-columns: 1fr 1fr;
gap: 10px 18px;
`;

export const Label = styled.label<{ $fullWidth?: boolean; }>`
display: flex;
flex-direction: column;
gap: 6px;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
letter-spacing: 0.15px;

${({ $fullWidth }) => $fullWidth && css`
  grid-column: 1 / -1;
`}
`;

export const Actions = styled.div`
margin-top: 16px;
grid-column: 1 / -1;
display: flex;
gap: 8px;
justify-content: center;
`;

export const Input = styled.input`
box-sizing: border-box;
color: ${colors.gray900};
border-radius: 4px;
background-color: ${colors.gray200};
padding: 8px 16px;
border: 1px solid ${colors.gray500};
width: 100%;
`;

export const FullRow = styled.div`
grid-column: 1 / -1;
`;

export const RadioContainer = styled.div`
display: flex;
align-items: center;
gap: 4px;
accent-color: ${colors.brandBriefly};
cursor: pointer;
`;

