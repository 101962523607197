import set from 'lodash/set';

export default function formEntriesToBody(formEntries: FormData): Record<string, unknown> {
  const body = Array.from(formEntries).reduce((result, [key, value]) => {
    return set(result, key, value)
  }, {});

  Object.entries(body).forEach(([key, value]) => {
    if (!Array.isArray(value)) return;

    body[key] = value.filter((n) => n != null);
  })

  return body
}


