import React from "react";
import { useTranslation } from 'react-i18next';

import { highlightText } from '../../utils/genericUtils';
import { Body } from "../../theme";

import styled from "styled-components";
import { Container, Heading, Section } from "./SummarySection";


const ListItem = styled.div`
  background: var(--backgroundSecondary);
  border-radius: var(--standardRadius);
  padding: 16px;
  font-size: 12px;
  line-height: 18px;
  color: var(--textSecondary);
  margin: 0;
`;

const Label = styled.div`
  margin-left: 0;
  margin-bottom : 6px;
  font-size: 14px;
  font-weight: 700;
  color: var(--textPrimary);
`;


interface FieldMappingFieldContext {
  summary?: string[],
  completion: {
    field_mapping: string[],
  };
}

interface FieldMapping {
  id: number,
  label: string,
  prompt: string,
}

interface FieldGroup {
  name: string,
  field_mappings: FieldMapping[],
}

interface WorkflowNotification {
  field_group: FieldGroup,
}

interface WorkflowRun {
  fields: Record<number, FieldMappingFieldContext>,
  workflowNotification: WorkflowNotification,
}

interface Props {
  workflowRun: WorkflowRun,
  searchWords?: string,
}

export default function LiveChecklistSummaries({ workflowRun, searchWords }: Props) {
  const { t } = useTranslation();
  const { fields, workflowNotification: { field_group: fieldGroup, field_group: { field_mappings } } } = workflowRun;

  return (
    <Container>
      <Heading>✅ {fieldGroup.name} Checklist</Heading>
      <Section>
        {field_mappings.map((fieldMapping) => {
          const fieldValue = fields[fieldMapping.id];

          const points = fieldValue ? fieldValue.summary || fieldValue.completion.field_mapping : ['-'];
          const text = points.join("\n");
          // @ts-expect-error todo
          const isError = text?.error;

          return (
            <ListItem key={fieldMapping.id}>
              <Label>{fieldMapping.label}</Label>
              {isError ?
                t('meeting.ia-summary.gen-failed.message', 'OpenAI error. Please try again later...') :
                text ? (
                  <Body>{searchWords ? highlightText(text, searchWords, { background: '#F9C338', color: 'black' }) : text}</Body>
                ) : (
                  <Body>{t('meeting.ia-summary.action-items.no-text', 'Please ensure that you have uploaded the transcript in the correct format as action items are displayed for each meeting participant.')}</Body>
                )}
            </ListItem>
          );
        })}
      </Section>
    </Container>
  );
}
