import React from "react";
import { SvgSize } from './types';


const LogOut = (props: SvgSize) => <svg width={props?.size ?? '14'} height={props?.size ?? '14'} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
  <title>Log out Icon</title>
  <path
    d="M1.38028 2.24674V11.7533H6.31413C6.69292 11.7533 7 12.0603 7 12.4391V12.4391C7 12.8179 6.69292 13.125 6.31413 13.125H1.38028C1.00782 13.125 0.679186 12.9868 0.394366 12.7103C0.131455 12.4338 0 12.1148 0 11.7533V2.24674C0 1.8852 0.131455 1.56619 0.394366 1.28971C0.679186 1.01324 1.00782 0.875 1.38028 0.875H6.31413C6.69292 0.875 7 1.18208 7 1.56087V1.56087C7 1.93967 6.69292 2.24674 6.31413 2.24674H1.38028ZM10.0348 4.05416C10.3027 3.79405 10.7292 3.79505 10.9959 4.05641L13.7503 6.75535C13.8874 6.88965 13.8874 7.11034 13.7503 7.24465L11.0097 9.93012C10.7366 10.1977 10.2988 10.1951 10.0289 9.92438V9.92438C9.75473 9.64938 9.75839 9.20329 10.037 8.93281L11.338 7.66992H4.87649C4.50651 7.66992 4.20657 7.36999 4.20657 7V7C4.20657 6.63001 4.50651 6.33008 4.87649 6.33008H11.338L10.0303 5.0376C9.75598 4.76646 9.75798 4.32282 10.0348 4.05416V4.05416Z"
    fill="currentColor" />
</svg>;

export default LogOut;