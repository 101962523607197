// ZoomOAuth.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { authCalendar, setupZoom } from '../utils/genericUtils';
import RobotPicture from '../images/robot_picture.png';
import { useUserData } from './hooks/useUserData';
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';
import QuickCheck1Image from '../images/quick-check-1.png';
import QuickCheck2Image from '../images/quick-check-2.png';
import { GoogleCalendarLogo, ZoomLogo } from './Icons';
import { Trans, useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { connectedToEmailBadge } from './Settings/SettingsTabIntegrations';
import { Button as ButtonBase } from '../theme';
import styled from 'styled-components';
import OutlineButton from './Reusable/OutlineButton';

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
`;

const Button = styled(ButtonBase)`
  width: 300px;
`;

interface OnboardingStepProps {
  goToPrevStep: () => void;
  closePopup: () => void;
  goToNextStep: () => void;
}


function ZoomOnboardingStep1({ closePopup, goToNextStep }: OnboardingStepProps) {
  const { t } = useTranslation();

  return <div>
    <h1 style={{ marginBottom: 20, "fontSize": "24px", "fontStyle": "normal", "fontWeight": "900", "lineHeight": "normal" }}>
      {t('onboarding-zoom.step1.title', 'Set up your Briefly note-taker')}
    </h1>
    <div style={{ display: 'flex' }}>
      <div className="left-sidebar" style={{}}>
        <span style={{ marginBottom: 18, display: 'flex', justifyContent: 'center', alignItems: 'center', "color": "var(--textPrimary)", "fontSize": "16px", "fontStyle": "normal", "fontWeight": "500", "lineHeight": "22px", "letterSpacing": "0.15px" }}>
          <ZoomLogo size="27" />
          <span style={{ marginLeft: 7, position: 'relative', top: 1, "color": "var(--textPrimary)", "fontSize": "16px", "fontStyle": "normal", "fontWeight": "500", "lineHeight": "22px", "letterSpacing": "0.15px" }}>
            {t('onboarding-zoom.step1.preview.title', 'Zoom Preview')}
          </span>
        </span>

        <div className="robot-container" style={{ marginBottom: 12, maxWidth: '100%', overflowX: 'hidden', margin: "auto", paddingTop: 25, paddingBottom: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', width: 200, maxHeight: 167, "borderRadius": "14px", "background": " linear-gradient(90deg, #B700AF 0%, #F16617 100%)" }}>
          <img src={RobotPicture} width="80" />
        </div>

        <div style={{ marginTop: 12, "textAlign": "center", "fontSize": "14px", "fontStyle": "normal", "fontWeight": "700", "lineHeight": "20px", "letterSpacing": "0.15px" }}>
          Briefly note-taker
        </div>
      </div>
      <div style={{ paddingLeft: 25 }}>
        <h6 style={{ margin: 0, "fontSize": "16px", "fontStyle": "normal", "fontWeight": "500", "lineHeight": "22px", "letterSpacing": "0.15px" }}>
          {t('onboarding-zoom.step1.instructions.title', 'How does it work?')}
        </h6>
        <h6 style={{ margin: 0, marginTop: 8, "fontSize": "14px", "fontStyle": "normal", "fontWeight": "700", "lineHeight": "20px", "letterSpacing": "0.15px" }}>
          🤖 {t('onboarding-zoom.step1.instructions.step1', { email: 'bot@brieflyai.com', defaultValue: 'Invite {{ email }} to your calendar' })}
        </h6>
        <h6 style={{ margin: 0, marginTop: 8, "fontSize": "14px", "fontStyle": "normal", "fontWeight": "700", "lineHeight": "20px", "letterSpacing": "0.15px" }}>
          ✍️ {t('onboarding-zoom.step1.instructions.step2.title', 'Meeting starts')}
        </h6>
        <p style={{ margin: 0, marginTop: 4, color: "var(--textSecondary)", "fontSize": "14px", "fontStyle": "normal", "fontWeight": "400", "lineHeight": "20px", "letterSpacing": "0.15px" }}>
          {t('onboarding-zoom.step1.instructions.step2.description', 'Your note-taker will join automatically and start transcribing')}
        </p>
        <h6 style={{ margin: 0, marginTop: 8, "fontSize": "14px", "fontStyle": "normal", "fontWeight": "700", "lineHeight": "20px", "letterSpacing": "0.15px" }}>
          ️🛡️ {t('onboarding-zoom.step1.instructions.step3.title', 'Recording permission')}
        </h6>
        <p style={{ margin: 0, marginTop: 4, color: "var(--textSecondary)", "fontSize": "14px", "fontStyle": "normal", "fontWeight": "400", "lineHeight": "20px", "letterSpacing": "0.15px" }}>
          {t('onboarding-zoom.step1.instructions.step3.description', 'The note-taker will ask the host to allow recording')}
        </p>
        <h6 style={{ margin: 0, marginTop: 8, "fontSize": "14px", "fontStyle": "normal", "fontWeight": "700", "lineHeight": "20px", "letterSpacing": "0.15px" }}>
          📢 {t('onboarding-zoom.step1.instructions.step4.title', 'Informing participants')}
        </h6>
        <p style={{ margin: 0, marginTop: 4, color: "var(--textSecondary)", "fontSize": "14px", "fontStyle": "normal", "fontWeight": "400", "lineHeight": "20px", "letterSpacing": "0.15px" }}>
          {t('onboarding-zoom.step1.instructions.step4.description', 'Attendees will be messaged letting them know you are using Briefly')}
        </p>

      </div>
    </div>
    <ButtonWrapper>
      <Button onClick={goToNextStep}>
        {t('onboarding-zoom.step1.start', 'Start Zoom integration setup')}
      </Button>

      <Button transparent onClick={closePopup} >
        {t('onboarding-zoom.step1.quit', 'Quit Setup')}
      </Button>
    </ButtonWrapper>
  </div>;
}

function ZoomOnboardingStep2({ closePopup, goToNextStep }: OnboardingStepProps) {

  const [{ is_integration_active_google_calendar, email_google_calendar }, forceRenewUserData] = useUserData();

  const { t } = useTranslation();

  function onCalendarLoginSuccess(data) {
    authCalendar(data.code, () => {
      setTimeout(() => {
        forceRenewUserData();
      }, 500);
    });
  }

  const calendarLogin = useGoogleLogin({
    onSuccess: codeResponse => onCalendarLoginSuccess(codeResponse),
    flow: 'auth-code',
    scope: "openid profile email https://www.googleapis.com/auth/calendar.readonly"
  });

  return <div style={{ textAlign: 'center' }}>
    <GoogleCalendarLogo size={100} />
    <h1 style={{ "textAlign": "center", "fontSize": "24px", "fontStyle": "normal", "fontWeight": "900", "lineHeight": "normal" }}>
      {is_integration_active_google_calendar ? t('onboarding-zoom.step2.connect-successful', 'Connection successful!') : t('onboarding-zoom.step2.connect-calendar', 'Connect your calendar')}
    </h1>
    <p style={{ "color": "var(--textPrimary)", "textAlign": "center", "fontSize": "16px", "fontStyle": "normal", "fontWeight": "500", "lineHeight": "22px", "letterSpacing": "0.15px" }}>
      {is_integration_active_google_calendar ? <>
        {t('onboarding-zoom.step2.connected-label', 'Your calendar is now linked to ')}{connectedToEmailBadge(email_google_calendar)}
      </> : t('onboarding-zoom.step2.description', 'Briefly uses your calendar to know which meetings to join and identify meeting participants.')}
    </p>

    <ButtonWrapper>
      {is_integration_active_google_calendar ? <Button onClick={goToNextStep} >
        {t('onboarding-zoom.step2.setup-notetaker', 'Setup Briefly note-taker')}
      </Button> : <Button onClick={calendarLogin} >
        <GoogleCalendarLogo size="18" /> {t('onboarding-zoom.step2.connect-button', 'Connect Google Calendar')}
      </Button>}

      <Button onClick={closePopup} >
        {t('onboarding-zoom.step1.quit', 'Quit Setup')}
      </Button>
    </ButtonWrapper>
  </div>;
}

interface ZoomOnboardingStep3Props extends OnboardingStepProps {
  botShouldJoinAllCalls: boolean;
  setBotShouldJoinAllCalls: (should: boolean) => void;
}

function ZoomOnboardingStep3({ closePopup, goToNextStep, setBotShouldJoinAllCalls, botShouldJoinAllCalls }: ZoomOnboardingStep3Props) {
  const { t } = useTranslation();

  return <div>
    <>
      <h1 style={{ marginBottom: 20, "fontSize": "24px", "fontStyle": "normal", "fontWeight": "900", "lineHeight": "normal" }}>
        {t('onboarding-zoom.step3.title', 'Set up your Briefly note-taker')}
      </h1>
      <h6 style={{ marginTop: 25, marginBottom: 20, "fontSize": "16px", "fontStyle": "normal", "fontWeight": "500", "lineHeight": "22px", "letterSpacing": "0.15px" }}>
        {t('onboarding-zoom.step3.subtitle', 'How do you want Briefly note-taker to join your Zoom calls?')}
      </h6>
      <div>
        <div>
          <div className="hubspot-option" style={{
            background: !botShouldJoinAllCalls ? 'var(--backgroundSecondary)' : 'var(--background)',
            borderRadius: 8,
            border: !botShouldJoinAllCalls ? '2px solid var(--brand-primary)' : 'var(--standardBorder)',
            display: 'flex',
            padding: 24,
            paddingLeft: 16,
            cursor: 'pointer'
          }} onClick={() => {
            setBotShouldJoinAllCalls(false);
          }}>
            <div style={{ display: 'inline-flex', width: 30 }}>
              {!botShouldJoinAllCalls ?
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="15" cy="15" r="14" fill="none" stroke="var(--brand-primary)" strokeWidth="2" />
                  <circle cx="15" cy="15" r="5" fill="var(--brand-primary)" />
                </svg>
                :
                <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="15.5" cy="15" r="14.5" fill="none" stroke="var(--gray600)" />
                </svg>
              }
            </div>
            <div style={{ display: 'inline-block', width: 'calc(100% - 40px)', marginLeft: 10 }}>
              <span>{t('onboarding-zoom.step3.invite.title', 'Invite as Needed')}</span>
              <br />
              <span style={{ display: 'inline-block', marginTop: 5 }}>
                {t('onboarding-zoom.step3.invite.description', 'You decide when Briefly note-taker joins by adding bot@brieflyai.com to meeting invites. Remember, the invite must include a Zoom meeting link.')}
              </span>
            </div>
          </div>

          <div className="hubspot-option" style={{
            background: botShouldJoinAllCalls ? 'var(--backgroundSecondary)' : 'var(--background)',
            borderRadius: 8,
            border: botShouldJoinAllCalls ? '2px solid var(--brand-primary)' : 'var(--standardBorder)',
            display: 'flex',
            padding: 24,
            paddingLeft: 16,
            cursor: 'pointer',
            marginTop: 20
          }} onClick={() => {
            setBotShouldJoinAllCalls(true);
          }}>
            <div style={{ display: 'inline-flex', width: 30 }}>
              {botShouldJoinAllCalls ?
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="15" cy="15" r="14" fill="none" stroke="var(--brand-primary)" strokeWidth="2" />
                  <circle cx="15" cy="15" r="5" fill="var(--brand-primary)" />
                </svg>
                :
                <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="15.5" cy="15" r="14.5" fill="none" stroke="var(--gray600)" />
                </svg>
              }
            </div>
            <div style={{ display: 'inline-block', width: 'calc(100% - 40px)', marginLeft: 10 }}>
              <span>{t('onboarding-zoom.step3.auto.title', 'Automatic Attendance')}</span>
              <br />
              <span style={{ display: 'inline-block', marginTop: 5 }}>
                {t('onboarding-zoom.step3.auto.description', 'Briefly note-taker will automatically join any meeting in your calendar that contains a Zoom link. No need for manual invites.')}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
    <ButtonWrapper>
      {<Button onClick={goToNextStep}>
        {t('onboarding-zoom.step3.confirm', 'Confirm and continue')}
      </Button>}

      <Button onClick={closePopup} transparent>
        {t('onboarding-zoom.step1.quit', 'Quit Setup')}
      </Button>
    </ButtonWrapper>
  </div>;
}

function ZoomOnboardingStep4({ closePopup, goToNextStep }: OnboardingStepProps) {
  const { t } = useTranslation();
  const [isCheck2, setIsCheck2] = useState(false);

  if (!isCheck2) {
    return <div style={{ textAlign: 'center' }}>
      <div style={{ textAlign: 'left' }}>
        <h1 style={{ marginBottom: 20, "fontSize": "24px", "fontStyle": "normal", "fontWeight": "900", "lineHeight": "normal" }}>
          {t('onboarding-zoom.step4.title', 'Quick check: Zoom App in Google Calendar')}
        </h1>
        <h6 className='subtitle' style={{ marginTop: 25, marginBottom: 20, "fontSize": "16px", "fontStyle": "normal", "fontWeight": "500", "lineHeight": "22px", "letterSpacing": "0.15px" }}>
          <Trans i18nKey="onboarding-zoom.step4.subtitle" defaults='Do you have the <strong>Zoom add-on</strong> in your Google Calendar?' />
        </h6>
        <p style={{ color: "var(--textSecondary)", "fontSize": "14px", "fontStyle": "normal", "fontWeight": "400", "lineHeight": "20px", "letterSpacing": "0.15px" }}>
          {t('onboarding-zoom.step4.details', 'Briefly note-taker will not join Zoom meetings if the add-on is not installed in your Google Workspace.')}
        </p>
      </div>
      <img src={QuickCheck1Image} width="342" style={{ borderRadius: 8 }} />
      <ButtonWrapper>
        <div style={{ display: "flex", gap: "10px" }}>
          <OutlineButton medium onClick={() => window.open("https://workspace.google.com/marketplace/app/zoom_for_google_workspace/364750910244", '_blank')?.focus()}>
            {t('onboarding-zoom.step4.not-configured', 'No, get Zoom add-on Now')}

            <svg style={{ top: 0, left: 0 }} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_2598_1381)">
                <path d="M7.17578 1.33789C7.17578 1.01321 7.43899 0.75 7.76367 0.75H10.9658C11.1227 0.75 11.25 0.877262 11.25 1.03425V4.23633C11.25 4.56101 10.9868 4.82422 10.6621 4.82422V4.82422C10.3374 4.82422 10.0742 4.56101 10.0742 4.23633V2.74609L6.03512 6.72136C5.80809 6.9448 5.44335 6.94335 5.21811 6.71811V6.71811C4.99032 6.49032 4.99179 6.12054 5.22139 5.89457L9.25391 1.92578H7.76367C7.43899 1.92578 7.17578 1.66257 7.17578 1.33789V1.33789ZM8.76172 10.0742V6.58789C8.76172 6.26321 9.02493 6 9.34961 6V6C9.67429 6 9.9375 6.26321 9.9375 6.58789V10.0742C9.9375 10.3841 9.81901 10.6576 9.58203 10.8945C9.34505 11.1315 9.07161 11.25 8.76172 11.25H1.92578C1.59766 11.25 1.3151 11.1406 1.07812 10.9219C0.859375 10.6849 0.75 10.4023 0.75 10.0742V3.23828C0.75 2.91016 0.859375 2.63672 1.07812 2.41797C1.3151 2.18099 1.59766 2.0625 1.92578 2.0625H5.41211C5.73679 2.0625 6 2.32571 6 2.65039V2.65039C6 2.97507 5.73679 3.23828 5.41211 3.23828H1.92578V10.0742H8.76172Z" fill="currentColor" />
              </g>
              <defs>
                <clipPath id="clip0_2598_1381">
                  <rect width="12" height="12" fill="currentColor" />
                </clipPath>
              </defs>
            </svg>


          </OutlineButton>
          <ButtonBase medium onClick={() => setIsCheck2(true)}>
            {t('onboarding-zoom.step4.configured', 'Yes, continue')}
          </ButtonBase>
        </div>
        <ButtonBase transparent onClick={closePopup} >
          {t('onboarding-zoom.step1.quit', 'Quit Setup')}
        </ButtonBase>
      </ButtonWrapper>
    </div>;
  } else {
    return <div style={{ textAlign: 'center' }}>
      <div style={{ textAlign: 'left' }}>
        <h1 style={{ marginBottom: 20, "fontSize": "24px", "fontStyle": "normal", "fontWeight": "900", "lineHeight": "normal" }}>
          {t('onboarding-zoom.step4.5.title', 'Quick check: Captions in Zoom')}
        </h1>
        <h6 className='subtitle' style={{ marginTop: 25, marginBottom: 20, "fontSize": "16px", "fontStyle": "normal", "fontWeight": "500", "lineHeight": "22px", "letterSpacing": "0.15px" }}>
          <Trans i18nKey="onboarding-zoom.step4.5.subtitle" defaults="Are <strong>automated captions</strong> enabled on your Zoom calls?" />
        </h6>
        <p style={{ color: "var(--textSecondary)", "fontSize": "14px", "fontStyle": "normal", "fontWeight": "400", "lineHeight": "20px", "letterSpacing": "0.15px" }}>
          {t('onboarding-zoom.step4.5.description', 'Briefly note-taker relies on captions to transcribe your meetings accurately. Please make sure this feature is activated in your Zoom online settings.')}
        </p>
      </div>
      <img src={QuickCheck2Image} width="342" style={{ borderRadius: 8 }} />
      <ButtonWrapper>
        <div style={{ display: "flex", gap: "10px" }}>
          <OutlineButton medium onClick={() => window.open("https://us02web.zoom.us/profile/setting", '_blank')?.focus()} >
            {t('onboarding-zoom.step4.5.not-configured', 'No, update Zoom settings now')}

            <svg style={{ top: 0, left: 0 }} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_2598_1381)">
                <path d="M7.17578 1.33789C7.17578 1.01321 7.43899 0.75 7.76367 0.75H10.9658C11.1227 0.75 11.25 0.877262 11.25 1.03425V4.23633C11.25 4.56101 10.9868 4.82422 10.6621 4.82422V4.82422C10.3374 4.82422 10.0742 4.56101 10.0742 4.23633V2.74609L6.03512 6.72136C5.80809 6.9448 5.44335 6.94335 5.21811 6.71811V6.71811C4.99032 6.49032 4.99179 6.12054 5.22139 5.89457L9.25391 1.92578H7.76367C7.43899 1.92578 7.17578 1.66257 7.17578 1.33789V1.33789ZM8.76172 10.0742V6.58789C8.76172 6.26321 9.02493 6 9.34961 6V6C9.67429 6 9.9375 6.26321 9.9375 6.58789V10.0742C9.9375 10.3841 9.81901 10.6576 9.58203 10.8945C9.34505 11.1315 9.07161 11.25 8.76172 11.25H1.92578C1.59766 11.25 1.3151 11.1406 1.07812 10.9219C0.859375 10.6849 0.75 10.4023 0.75 10.0742V3.23828C0.75 2.91016 0.859375 2.63672 1.07812 2.41797C1.3151 2.18099 1.59766 2.0625 1.92578 2.0625H5.41211C5.73679 2.0625 6 2.32571 6 2.65039V2.65039C6 2.97507 5.73679 3.23828 5.41211 3.23828H1.92578V10.0742H8.76172Z" fill="currentColor" />
              </g>
              <defs>
                <clipPath id="clip0_2598_1381">
                  <rect width="12" height="12" fill="currentColor" />
                </clipPath>
              </defs>
            </svg>
          </OutlineButton>
          <ButtonBase onClick={() => goToNextStep()}>
            {t('onboarding-zoom.step4.configured', 'Yes, continue')}
          </ButtonBase>
        </div>
        <ButtonBase transparent onClick={closePopup} >
          {t('onboarding-zoom.step1.quit', 'Quit Setup')}
        </ButtonBase>
      </ButtonWrapper >
    </div >;
  }
}

interface ZoomOnboardingStep5Props extends OnboardingStepProps {
  botShouldJoinAllCalls: boolean;
}

function ZoomOnboardingStep5({ closePopup, botShouldJoinAllCalls }: ZoomOnboardingStep5Props) {

  const navigate = useNavigate();
  const { t } = useTranslation();

  return <div>
    <h1 style={{ marginBottom: 20, "fontSize": "24px", "fontStyle": "normal", "fontWeight": "900", "lineHeight": "normal" }}>
      {t('onboarding-zoom.done.title', 'You’re all set')}
    </h1>
    <div style={{ display: 'flex' }}>
      <div className="left-sidebar">
        <span style={{ marginBottom: 18, display: 'flex', justifyContent: 'center', alignItems: 'center', "fontSize": "16px", "fontStyle": "normal", "fontWeight": "500", "lineHeight": "22px", "letterSpacing": "0.15px" }}>
          <ZoomLogo size={27} />
          <span style={{ marginLeft: 7, position: 'relative', top: 1, "fontSize": "16px", "fontStyle": "normal", "fontWeight": "500", "lineHeight": "22px", "letterSpacing": "0.15px" }}>
            {t('onboarding-zoom.step1.preview.title', 'Zoom Preview')}
          </span>
        </span>

        <div className="robot-container" style={{ marginBottom: 12, maxWidth: '100%', overflowX: 'hidden', margin: "auto", paddingTop: 25, paddingBottom: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', width: 200, maxHeight: 167, "borderRadius": "14px", "background": " linear-gradient(90deg, #B700AF 0%, #F16617 100%)" }}>
          <img src={RobotPicture} width="80" />
        </div>

        <div style={{ marginTop: 12, "textAlign": "center", "fontSize": "14px", "fontStyle": "normal", "fontWeight": "700", "lineHeight": "20px", "letterSpacing": "0.15px" }}>
          Briefly note-taker
        </div>
      </div>
      <div style={{ paddingLeft: 25 }}>
        <h6 style={{ margin: 0, "fontSize": "16px", "fontStyle": "normal", "fontWeight": "500", "lineHeight": "22px", "letterSpacing": "0.15px" }}>
          {t('onboarding-zoom.step1.instructions.title', 'How does it work?')}
        </h6>
        {!botShouldJoinAllCalls ? <h6 style={{ margin: 0, marginTop: 8, "fontSize": "14px", "fontStyle": "normal", "fontWeight": "700", "lineHeight": "20px", "letterSpacing": "0.15px" }}>
          🤖 {t('onboarding-zoom.step1.instructions.step1', { email: 'bot@brieflyai.com', defaultValue: 'Invite {{ email }} to your calendar' })}
        </h6> : ''}
        <h6 style={{ margin: 0, marginTop: 8, "fontSize": "14px", "fontStyle": "normal", "fontWeight": "700", "lineHeight": "20px", "letterSpacing": "0.15px" }}>
          ✍️ {t('onboarding-zoom.step1.instructions.step2.title', 'Meeting starts')}
        </h6>
        <p style={{ margin: 0, marginTop: 4, color: "var(--textSecondary)", "fontSize": "14px", "fontStyle": "normal", "fontWeight": "400", "lineHeight": "20px", "letterSpacing": "0.15px" }}>
          {t('onboarding-zoom.step1.instructions.step2.description', 'Your note-taker will join automatically and start transcribing')}
        </p>
        <h6 style={{ margin: 0, marginTop: 8, "fontSize": "14px", "fontStyle": "normal", "fontWeight": "700", "lineHeight": "20px", "letterSpacing": "0.15px" }}>
          ️🛡️ {t('onboarding-zoom.step1.instructions.step3.title', 'Recording permission')}
        </h6>
        <p style={{ margin: 0, marginTop: 4, color: "var(--textSecondary)", "fontSize": "14px", "fontStyle": "normal", "fontWeight": "400", "lineHeight": "20px", "letterSpacing": "0.15px" }}>
          {t('onboarding-zoom.step1.instructions.step3.description', 'The note-taker will ask the host to allow recording')}
        </p>
        <h6 style={{ margin: 0, marginTop: 8, "fontSize": "14px", "fontStyle": "normal", "fontWeight": "700", "lineHeight": "20px", "letterSpacing": "0.15px" }}>
          📢 {t('onboarding-zoom.step1.instructions.step4.title', 'Informing participants')}
        </h6>
        <p style={{ margin: 0, marginTop: 4, color: "var(--textSecondary)", "fontSize": "14px", "fontStyle": "normal", "fontWeight": "400", "lineHeight": "20px", "letterSpacing": "0.15px" }}>
          {t('onboarding-zoom.step1.instructions.step4.description', 'Attendees will be messaged letting them know you are using Briefly')}
        </p>
        <p style={{ display: 'flex', background: 'var(--backgroundSecondary)', padding: '6px', border: "var(--standardBorder)", borderRadius: 4, gap: 4, alignItems: 'center' }}>
          <span style={{ flex: 1, color: 'var(--textSecondary)' }}>
            {t('onboarding-zoom.done.email-label', 'A summary email will be sent after the call ends as per your settings')}
          </span>
          <a onClick={() => {
            closePopup();
            navigate('/settings/ai-summary-email');
          }} style={{ cursor: 'pointer', "color": "var(--color-brand-briefly, var(--brand-primary))", "fontSize": "14px", "fontStyle": "normal", "fontWeight": "700", "lineHeight": "20px", "letterSpacing": "0.15px", "textDecorationLine": "underline" }}>
            {t('manage', { ns: 'Actions', defaultValue: 'Manage' })}
          </a>
        </p>

      </div>
    </div>
    <ButtonWrapper>
      <Button onClick={() => {
        closePopup();
        navigate('/settings/integrations/zoom');
      }}>
        {t('onboarding-zoom.done.complete', 'Complete Setup')}
      </Button>
    </ButtonWrapper>
  </div>;
}

const loadConfigSteps = (t: TFunction) => [
  {
    component: ZoomOnboardingStep1,
    label: t('onboarding-zoom.steps.setup', 'Zoom setup')
  },
  {
    component: ZoomOnboardingStep2,
    label: t('onboarding-zoom.steps.calendar', 'Connect calendar')
  },
  {
    component: ZoomOnboardingStep3,
    label: t('onboarding-zoom.steps.bot-attendance', 'Bot attendance')
  },
  {
    component: ZoomOnboardingStep4,
    label: t('onboarding-zoom.steps.integration', 'Integration checks')
  },
  {
    component: ZoomOnboardingStep5,
    label: t('onboarding-zoom.steps.complete', 'Complete setup')
  },
];

interface ConnectionOnboardingPopupProps {
  onClose: () => void;
}

function ZoomOnboardingPopup({ onClose }: ConnectionOnboardingPopupProps) {
  const { t } = useTranslation();

  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const [_, forceRenewUserData] = useUserData();

  const [botShouldJoinAllCalls, setBotShouldJoinAllCalls] = useState(false);
  const configSteps = loadConfigSteps(t);

  const currentStep = configSteps[currentStepIndex];
  const CurrentStepComponent = currentStep.component;

  useEffect(() => {
    if (currentStepIndex === 4) {
      setupZoom(botShouldJoinAllCalls, () => {
        forceRenewUserData();
      });
    }
  }, [currentStepIndex]);


  return <GoogleOAuthProvider clientId="576404817524-ss93f3dua36hvaum9oi4vqrmu8anc6o3.apps.googleusercontent.com">
    <div className="transcript-info-popup-container zoom-onboarding">
      <div className="popup-background" onClick={() => onClose()}></div>
      <div className="popup-content" style={{ padding: 20 }}>

        <div className="header steps" style={{ marginBottom: 25 }}>
          {configSteps.map(({ label }, i) => {
            const stepIsActive = currentStepIndex >= i;
            return <div key={i} className={"step" + (stepIsActive ? " active" : "")}>
              {i > 0 ? <div className="connector"></div> : ''}
              <div className="step-body">
                <div className="circle">{i + 1}</div>
                <div className="label">{label}</div>
              </div>

            </div>;
          })}
        </div>

        <CurrentStepComponent botShouldJoinAllCalls={botShouldJoinAllCalls} setBotShouldJoinAllCalls={setBotShouldJoinAllCalls} closePopup={onClose} goToPrevStep={() => setCurrentStepIndex(currentStepIndex - 1)} goToNextStep={() => setCurrentStepIndex(currentStepIndex + 1)} />

      </div>
    </div>
  </GoogleOAuthProvider>;
};

export default ZoomOnboardingPopup;
