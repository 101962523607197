import { useTranslation } from "react-i18next";
import { Link as A } from "react-router-dom";

import { BuildingIcon, Cog, EmailIcon, StarIcon, WorldIcon } from '../Icons';
import Accordion from '../Reusable/Accordion';
import { I18N_SUPPORT } from '../../config/config';
import { UserData } from "../MeetingCall/Types";

import styled from 'styled-components';
const App = styled.div`
  display: flex;
  gap: 8px;
  padding: 12px 16px;
  border: solid transparent 1px;
  border-radius: var(--standardRadius);

  &:first-child {
    margin-top: 0.8rem;
  }

  &:hover {
    border: var(--standardBorder);
  }
`;

const Link = styled(A)`
  display: flex;
  gap: 8px;
  align-items: center;
`;

interface Props extends Pick<UserData, "features" | "administering_organization"> {
  isActive: boolean;
}

const SettingsDropdown = ({ administering_organization, features, isActive }: Props) => {
  const { t } = useTranslation();

  const SETTINGS_CONFIG = [
    {
      icon: <EmailIcon width="20" color="var(--brand-primary" />,
      label: t('settings.ai-summary.tab-label', 'AI summary email'),
      path: '/settings/ai-summary-email',
      isVisible: true,
    },
    {
      icon: <StarIcon size="20" color="var(--brand-primary" />,
      label: t('settings.subscription.tab-label', 'Subscriptions'),
      path: '/settings/subscriptions',
      isVisible: Boolean(features?.manage_subscription),
    },
    {
      icon: <BuildingIcon size="20" color="var(--brand-primary" />,
      label: t('settings.organization.tab-label', 'Organization'),
      path: '/settings/organization',
      isVisible: administering_organization != null,
    },
    {
      icon: <WorldIcon width="20" color="var(--brand-primary" />,
      label: t('settings.language.tab-label', 'Language'),
      path: '/settings/language',
      isVisible: I18N_SUPPORT,
    },
  ];

  return (
    <Accordion
      title={t('root-sidebar.settings', 'Settings')}
      icon={<Cog />}
      isActive={isActive}
    >
      {SETTINGS_CONFIG.map((integration) => {
        const { path, icon, isVisible, label } = integration;
        return (
          isVisible ? (
            <App key={label}>
              <Link to={path}>{icon}<span>{label}</span></Link>
            </App>
          ) : null
        );
      })}
    </Accordion>
  );
};

export default SettingsDropdown;