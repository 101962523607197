import styled, { css } from "styled-components";
import { AttendeeByColor } from "../MeetingCall/Types";

const Container = styled.div`
  padding-left: 4px;
`;

const Attendee = styled.div<{ $color: string, $size?: string }>`
  ${({ $color, $size }) => css`
    background: ${$color};
    width: ${$size || '24px'};
    height: ${$size || '24px'};
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    padding: 4px;
    margin-left: -8px;
    outline: 3px solid var(--white);
  `}

`;

interface Props {
  attendeesByColor: [AttendeeByColor] | [];
  className?: string;
  limit?: number;
  size?: string;
}


const Attendees = ({ attendeesByColor, className, limit, size }: Props) => {
  const isOverLimit = limit && limit < attendeesByColor.length;
  const attendees = attendeesByColor?.slice(0, isOverLimit ? limit : attendeesByColor.length);

  const numRemaining = limit ? attendeesByColor.length - limit : 0;

  return (
    <Container className={className}>
      {
        attendees?.map((attendee) => {
          const initials = (attendee.name[0] + (attendee.name.split(' ')?.[1]?.[0] || '')).toUpperCase();
          return <Attendee key={attendee.name} $color={attendee.deviceColor} $size={size}>{initials}</Attendee>;
        })}
      {numRemaining > 0 && <Attendee $color='#B4B4B4' $size={size}>+{numRemaining}</Attendee>}
    </Container>
  );

};

export default Attendees;
