import React, { ReactElement, useState } from 'react';
import { Button, ButtonProps } from '../../theme';

interface Props extends ButtonProps {
  initialText: string | ReactElement,
  clickedText: string | ReactElement,
  onClick: () => void,
  style?: React.CSSProperties,
  className?: string;
  disabled?: boolean;
}

const ButtonWithTimeoutReplaceOnClick = ({ initialText, clickedText, onClick, style = {}, className = "", disabled, ...rest }: Props) => {
  const [isClicked, setIsClicked] = useState(false);

  if (isClicked) {
    return <Button style={style} className={className} disabled={disabled} {...rest} >
      {clickedText}
    </Button>;
  } else {
    return <Button disabled={disabled} onClick={() => {
      setIsClicked(true);
      setTimeout(() => {
        setIsClicked(false);
      }, 1000);

      onClick();
    }} style={style} className={className} {...rest}>
      {initialText}
    </Button>;
  }
};

export default ButtonWithTimeoutReplaceOnClick;