import { useState, useRef, useEffect } from 'react';
import { useOutletContext } from "react-router-dom";
import { getUserAccessToken, uploadTranscriptRequest, trackEvent } from '../utils/genericUtils';
import DatePicker, { registerLocale } from 'react-datepicker';
import { FileUploader } from "react-drag-drop-files";
import { Tooltip } from 'react-tooltip';
import { es, ptBR } from 'date-fns/locale';

import { useTranslation } from 'react-i18next';
import { TrashCanIcon } from './Icons';
import { Button } from '../theme';

const fileTypes = ["TXT", "VTT"];

registerLocale('es', es);
registerLocale('pt-BR', ptBR);

export default function UploadTranscript() {

  const { setShowZoomTranscriptInfoPopup, setShowMsTeamsTranscriptInfoPopup } = useOutletContext();

  const { t, i18n } = useTranslation();

  const [meetingName, setMeetingName] = useState("");

  const [startDate, setStartDate] = useState(new Date());

  const [isMeetingNameError, setIsMeetingNameError] = useState(false);

  const fileContainerRef = useRef();

  useEffect(() => {
    if (fileContainerRef.current) {
      fileContainerRef.current.querySelector('label:not(#ignore)').onclick = e => {
        if (!document.getElementById("open-file").matches(':hover')) {
          e.preventDefault();
          e.stopPropagation();
          if (document.getElementById("show-zoom-info").matches(':hover')) {
            setShowZoomTranscriptInfoPopup(true);
          }
          if (document.getElementById("show-ms-teams-info").matches(':hover')) {
            setShowMsTeamsTranscriptInfoPopup(true);
          }
        }
      };
    }
  }, fileContainerRef);

  const TAB_FILE = 'TAB_FILE';
  const TAB_TEXT = 'TAB_TEXT';

  const TAB_CONFIG = [
    {
      id: TAB_FILE,
      label: t('upload-transcript.meeting.tabs.file', 'Upload file')
    },
    {
      id: TAB_TEXT,
      label: t('upload-transcript.meeting.tabs.paste', 'Paste transcript')
    }
  ];

  const [file, setFile] = useState(null);
  const [pastedText, setPastedText] = useState('');

  const [currentTab, setCurrentTab] = useState(TAB_FILE);


  const handleChange = (file) => {
    setFile(file);
    setPastedText('');
  };

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  function uploadTranscriptContent(filecontent, meetingName) {
    getUserAccessToken(({ access }) => {
      uploadTranscriptRequest(filecontent, meetingName, startDate, access).then(response => {
        if (response.error) {
          //
        } else {
          window.location = '/meeting/bkend-' + response.transcriptId + '?transcript=1';
        }
      });
    });
  }


  function uploadTranscript() {
    if (currentTab === TAB_TEXT) {
      trackEvent("briefly-upload-transcript-text", {
        text: pastedText,
        meetingName: meetingName,
        meetingStartDate: startDate
      });
      uploadTranscriptContent(pastedText, meetingName);
      return;
    }

    var reader = new FileReader();

    reader.onload = function (evt) {
      if (evt.target.readyState != 2) return;
      if (evt.target.error) {
        alert('Error while reading file');
        return;
      }

      var filecontent = evt.target.result;

      setIsFormSubmitted(true);

      trackEvent("briefly-upload-transcript-file", {
        filecontent,
        meetingName: meetingName ? meetingName : file.name,
        meetingStartDate: startDate,
        filename: file.name
      });

      uploadTranscriptContent(filecontent, meetingName ? meetingName : file.name);
    };

    reader.readAsText(file);
  }

  const tabContentFile = <div ref={fileContainerRef}>
    <FileUploader handleChange={handleChange} name="file" types={fileTypes}>
      <div className={"upload-transcript-dropbox " + (file ? 'file-selected' : '')}>
        <svg width="65" height="85" viewBox="0 0 65 85" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M61.9849 9.67188H31.9686L9.49601 76.4195V81.9034C9.49593 82.5915 9.7686 83.2509 10.2529 83.7337C10.7372 84.2165 11.3926 84.4823 12.0721 84.4716L60.992 83.7435C62.3642 83.7256 63.4727 82.6047 63.4923 81.2154L64.5247 12.3002C64.5398 11.6084 64.2789 10.9397 63.8009 10.445C63.323 9.95042 62.6683 9.67171 61.9849 9.67188Z" fill="var(--white)" />
          <path fillRule="evenodd" clipRule="evenodd" d="M61.9849 9.67188H31.9686L9.49601 76.4195V81.9034C9.49593 82.5915 9.7686 83.2509 10.2529 83.7337C10.7372 84.2165 11.3926 84.4823 12.0721 84.4716L60.992 83.7435C62.3642 83.7256 63.4727 82.6047 63.4923 81.2154L64.5247 12.3002C64.5398 11.6084 64.2789 10.9397 63.8009 10.445C63.323 9.95042 62.6683 9.67171 61.9849 9.67188Z" stroke="var(--textPrimary)" strokeWidth="0.77976" strokeLinecap="round" strokeLinejoin="round" />
          <path fillRule="evenodd" clipRule="evenodd" d="M0.474665 4.75598V73.6377C0.474849 74.4648 0.808285 75.2559 1.39789 75.8283C1.98749 76.4007 2.78221 76.7048 3.59833 76.6702L50.493 74.6663C52.082 74.5992 53.3428 73.2875 53.3659 71.6773L54.118 16.9226L37.6618 0.527893L3.35754 1.71015C1.74302 1.77313 0.468135 3.12008 0.474665 4.75598Z" fill="var(--white)" />
          <path fillRule="evenodd" clipRule="evenodd" d="M0.474665 4.75598V73.6377C0.474849 74.4648 0.808285 75.2559 1.39789 75.8283C1.98749 76.4007 2.78221 76.7048 3.59833 76.6702L50.493 74.6663C52.082 74.5992 53.3428 73.2875 53.3659 71.6773L54.118 16.9226L37.6618 0.527893L3.35754 1.71015C1.74302 1.77313 0.468135 3.12008 0.474665 4.75598V4.75598Z" stroke="var(--textPrimary)" strokeWidth="0.77976" strokeLinecap="round" strokeLinejoin="round" />
          <path fillRule="evenodd" clipRule="evenodd" d="M37.6519 0.541199V14.2908C37.6511 14.9926 37.926 15.6659 38.4161 16.1621C38.9062 16.6584 39.5712 16.9368 40.2643 16.9359H54.118L37.6519 0.541199Z" fill="var(--white)" />
          <path fillRule="evenodd" clipRule="evenodd" d="M37.6519 0.541199V14.2908C37.6511 14.9926 37.926 15.6659 38.4161 16.1621C38.9062 16.6584 39.5712 16.9368 40.2643 16.9359H54.118L37.6519 0.541199Z" stroke="var(--textPrimary)" strokeWidth="0.77976" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M9.49272 12.237L28.0071 11.5557" stroke="var(--textPrimary)" strokeWidth="0.77976" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M9.49272 19.6946L28.0071 19.1269" stroke="var(--textPrimary)" strokeWidth="0.77976" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M10.0898 27.6564L44.8591 28.2576" stroke="var(--textPrimary)" strokeWidth="0.77976" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M10.0898 36.0926L44.8591 36.7906" stroke="var(--textPrimary)" strokeWidth="0.77976" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M10.0898 45.1532H43.7937" stroke="var(--textPrimary)" strokeWidth="0.77976" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M30.0258 54.2137H43.7937" stroke="var(--textPrimary)" strokeWidth="0.77976" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M30.9758 62.3828L43.7937 61.6614" stroke="var(--textPrimary)" strokeWidth="0.77976" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

        <h5>
          {t('upload-transcript.meeting.upload.title', 'Drop your transcript file here or')}<Button disabled={file} inline id="open-file" >{t('upload-transcript.meeting.upload.button', 'Choose a file')}</Button>
        </h5>
        <p>
          {t('upload-transcript.meeting.upload.detail.part1', 'Only .txt and .vtt files allowed. For better results make sure the file is in the correct format')}{' '}
          <span id="show-zoom-info">{t('upload-transcript.meeting.upload.detail.part2', 'for Zoom')}</span>{' '}
          {t('upload-transcript.meeting.upload.detail.part3', 'and')}{' '}
          <span id="show-ms-teams-info">{t('upload-transcript.meeting.upload.detail.part4', 'for MS Teams')}</span>
        </p>

      </div>
    </FileUploader>

    {file ? <div className="selected-file">
      <div>
        <div>
          <svg width="20" height="29" viewBox="0 0 20 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0.500009 2.29827V26.9153C0.500075 27.2109 0.619239 27.4936 0.829951 27.6982C1.04066 27.9028 1.32468 28.0114 1.61635 27.9991L18.3756 27.2829C18.9435 27.2589 19.3941 26.7901 19.4024 26.2147L19.6711 6.64639L13.79 0.787231L1.5303 1.20975C0.953297 1.23226 0.497675 1.71363 0.500009 2.29827Z" fill="var(--white)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M0.500009 2.29827V26.9153C0.500075 27.2109 0.619239 27.4936 0.829951 27.6982C1.04066 27.9028 1.32468 28.0114 1.61635 27.9991L18.3756 27.2829C18.9435 27.2589 19.3941 26.7902 19.4024 26.2147L19.6711 6.64639L13.79 0.787231L1.5303 1.20975C0.953297 1.23226 0.497675 1.71363 0.500009 2.29827V2.29827Z" stroke="var(--textPrimary)" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M13.7865 0.791992V5.70585C13.7862 5.95666 13.8844 6.19728 14.0596 6.37463C14.2347 6.55198 14.4724 6.65147 14.7201 6.65115H19.6711L13.7865 0.791992Z" fill="var(--white)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M13.7865 0.791992V5.70585C13.7862 5.95666 13.8844 6.19728 14.0596 6.37463C14.2347 6.55198 14.4724 6.65147 14.7201 6.65115H19.6711L13.7865 0.791992Z" stroke="var(--textPrimary)" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3.7229 4.97188L10.3396 4.72839" stroke="var(--textPrimary)" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3.7229 7.63711L10.3396 7.4342" stroke="var(--textPrimary)" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3.93625 10.4824L16.3622 10.6973" stroke="var(--textPrimary)" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3.93625 13.4973L16.3622 13.7468" stroke="var(--textPrimary)" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3.93625 16.7355H15.9814" stroke="var(--textPrimary)" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.061 19.9735H15.9814" stroke="var(--textPrimary)" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.4005 22.8931L15.9814 22.6353" stroke="var(--textPrimary)" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
        <div className="file-name">
          {file.name}
        </div>
        <div className="delete-icon" onClick={() => setFile(null)}>
          <TrashCanIcon color="var(--brand-primary)" />
        </div>
      </div>
    </div> : ''}
  </div >;

  const tabContentText = <textarea placeholder="Paste your transcript here..." value={pastedText} onChange={e => { setPastedText(e.target.value); setFile(null); }} />;

  const submitIsEnabled = ((currentTab === TAB_FILE && file) || (currentTab === TAB_TEXT && pastedText.trim().length > 0));
  const anyTranscriptPasted = currentTab === TAB_FILE || (!isFormSubmitted && meetingName.trim().length > 0);

  const uploadHandler = anyTranscriptPasted ? uploadTranscript : () => setIsMeetingNameError(true);
  const handleKeyPress = ({ e, id }) => {
    if (e.keyCode === 13) setCurrentTab(id);
  };

  return <main id="upload-transcript-outer-container">
    <div id="upload-transcript-container">
      <h5>{t('upload-transcript.title', 'Upload any transcript to Briefly')}</h5>
      <div className="pink">
        <p>{t('upload-transcript.help.label', 'Do you need help exporting your transcript?')}</p>
        <div>
          <div tabIndex={0} onClick={() => {

            trackEvent("briefly-how-to-get-zoom-transcripts-click", {
              source: 'UPLOAD_TRANSCRIPT'
            });

            setShowZoomTranscriptInfoPopup(true);
          }}>
            {t('upload-transcript.help.zoom', 'How to get transcripts for Zoom')}
          </div>
          <div tabIndex={0} onClick={() => {

            trackEvent("briefly-how-to-get-ms-teams-transcripts-click", {
              source: 'UPLOAD_TRANSCRIPT'
            });

            setShowMsTeamsTranscriptInfoPopup(true);
          }}>
            {t('upload-transcript.help.teams', 'How to get transcripts for MS Teams')}
          </div>
        </div>
      </div>

      <input
        type="text"
        placeholder={t("upload-transcript.meeting.name-placeholder", "Add a name for your meeting")}
        value={meetingName}
        onChange={e => { setMeetingName(e.target.value); setIsMeetingNameError(false); }}
        style={isMeetingNameError ? { borderColor: 'red' } : {}} />

      <div className='extended-datepicker' style={{ marginTop: 20 }}>
        <DatePicker
          showIcon
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          locale={i18n.language}
          showTimeSelect
          timeFormat="p"
          timeIntervals={5}
          dateFormat="MMM d, yyyy h:mm aa"
        />
      </div>

      <div style={{ marginTop: 20, marginBottom: 20 }}>
        <div className="upload-transcript-tabs">
          {TAB_CONFIG.map(({ id, label }) => {
            return (
              <div onKeyDown={(e) => handleKeyPress({ e, id })} tabIndex={0} key={id} className={id === currentTab ? 'active' : ''} onClick={() => setCurrentTab(id)}>
                {label}
              </div>
            );
          })}
        </div>
        <div className="upload-transcript-tab-content">
          {currentTab === TAB_FILE ? tabContentFile : tabContentText}
        </div>
      </div>
      <Button style={{ margin: "auto" }} disabled={!submitIsEnabled} data-tooltip-id="tooltip-button" data-tooltip-content="Add a name for your meeting" data-tooltip-place="top"
        onClick={() => {
          uploadHandler();
        }}>{t('upload-transcript.meeting.upload-button', 'Upload transcript')}</Button>

      {!anyTranscriptPasted && <Tooltip id="tooltip-button" openOnClick={true} />}

    </div>
  </main>;
}
