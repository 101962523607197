import { BACKEND_URL } from '../config/config';
import { wait } from './async';
import { getUserAccessTokenPromise, csrfFetch } from './genericUtils';

interface RequestParams {
  path: string,
  signal?: AbortSignal,
}

interface PostParams extends RequestParams {
  body: Record<string, unknown>,
  method?: 'POST' | 'PATCH',
}

export type ErrorsObject<T extends string> = Partial<Record<T, string[]>>

export class APIValidationError<T extends string> extends Error {
  errors: ErrorsObject<T>;

  constructor(errorsObj: ErrorsObject<T>) {
    super()
    this.errors = errorsObj;
    this.name = 'APIValidationError';
  }
}


export function isAPIValidationError<T extends string>(obj: unknown): obj is APIValidationError<T> {
  return (obj as APIValidationError<T>)?.name === 'APIValidationError';
}


export async function apiGet<ReturnObject, Name extends string>({ path, signal }: RequestParams): Promise<Record<Name, ReturnObject>> {
  const {access: accessToken} = await getUserAccessTokenPromise();

  const response = await csrfFetch(`${BACKEND_URL}/api${path}`, 'GET', {
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
    referrerPolicy: "no-referrer",
    signal,
  });

  if (!response.ok) {
    throw new Error("API GET Failed");
  }

  return response.json();
}

export async function apiPost<Fields extends string, ReturnObject, Name extends string>({
  path, body, method = 'POST', signal
}: PostParams): Promise<Record<Name, ReturnObject>> {
  const {access: accessToken} = await getUserAccessTokenPromise();

  const response = await csrfFetch(`${BACKEND_URL}/api${path}`, method, {
    cache: "no-cache",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
    referrerPolicy: "no-referrer",
    signal,
  });

  if (response.status === 422) {
    const json = await response.json();
    throw new APIValidationError<Fields>(json.errors)
  }

  if (!response.ok) {
    throw new Error("API POST Failed");
  }

  return response.json();
}

export async function apiPatch<Fields extends string, ReturnObject, Name extends string>({
  path,
  body
}): Promise<Record<Name, ReturnObject>> {
  return apiPost<Fields, ReturnObject, Name>({ path, body, method: "PATCH" })
}

export async function apiPostStream<C>({ path, body, method = "POST" }, onChunk: (value: C) => void) {
  const {access: accessToken} = await getUserAccessTokenPromise();

  const response = await csrfFetch(`${BACKEND_URL}/api${path}`, method, {
    cache: "no-cache",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
    referrerPolicy: "no-referrer",
  }) as Response;

  if (!response.ok || response.body == null) {
    throw new Error("API Stream failed");
  }

  const reader = response.body.getReader();

  let done = false;
  let value: Uint8Array | undefined;
  let lastIncompleteChunk = '';

  while (!done) {
    ({ value, done } = await reader.read());
    if (value) {
      const text = new TextDecoder().decode(value);
      const [firstChunk, ...splittedText] = text.split("\n\n\n\n");
      
      onChunk(JSON.parse(lastIncompleteChunk + firstChunk));
      lastIncompleteChunk = '';

      const lastChunk = splittedText.pop() ?? '';
      
      for (const text of splittedText) {
        if (!text || text === '') continue;
        try {
          // Allow the typing effect to be seen by the users
          await wait(50);
          const validJSON = JSON.parse(text);
          onChunk(validJSON);
        } catch (e) {
          console.log("Parse failed", { text });
          throw e;
        }
      }

      if(lastChunk === '') continue;

      try {
        const validChunk = JSON.parse(lastChunk);
        onChunk(validChunk);
      } catch {
        lastIncompleteChunk = lastChunk;
      }
    }
  }

  return response
}
