import { useState, useRef } from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { trackEvent } from '../../utils/genericUtils';
import Popover from '../Reusable/Popover';
const SHOW_UPLOAD_TIP_KEY = 'SHOW_UPLOAD_TIP_KEY';

import styled, { css } from 'styled-components';
import { Transcript } from '../Icons';

const Upload = styled(Link) <{ $isActive?: boolean; }>`
  display: flex;
  gap: 10px;
  padding: 12px 16px;
  border-radius: var(--standardRadius);
  border: 1px solid var(--background);
  ${({ $isActive }) => $isActive && css`
    background: linear-gradient(var(--focusGradientSecondary));
    border: var(--standardBorder);
  `}

  &:hover {
    border: var(--standardBorder);
  }
`;

interface Props {
  isActive: boolean;
}

const UploadTranscript = ({ isActive }: Props) => {
  const { t } = useTranslation();
  const uploadRef = useRef(null);
  const [showUploadTip, setShowUploadTip] = useState(!localStorage.getItem(SHOW_UPLOAD_TIP_KEY));

  const handleClick = () => {
    trackEvent("briefly-upload-transcript-navigate", {});
    setShowUploadTip(false);
    localStorage.setItem(SHOW_UPLOAD_TIP_KEY, '1');
  };

  const handleCloseTip = () => {
    setShowUploadTip(false);
    localStorage.setItem(SHOW_UPLOAD_TIP_KEY, '1');
  };

  return (
    <>
      <Upload $isActive={isActive} ref={uploadRef} onClick={handleClick} to='/upload-transcript'>
        <Transcript /> {t('root-sidebar.upload.button', 'Upload a transcript')}
      </Upload>

      {showUploadTip && (
        <Popover hasArrow style={{ width: "220px" }} side="bottom" defaultOpen={showUploadTip} anchorRef={uploadRef} onOpenChange={handleCloseTip}>
          <div style={{ fontWeight: 700, color: "var(--brand-tertiary)" }}>NEW</div>
          <p style={{ marginBottom: 0 }}>{t('root-sidebar.upload.tip', 'You can now upload any transcript or text into Briefly')}</p>
        </Popover>
      )}
    </>
  );
};

export default UploadTranscript;
