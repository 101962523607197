/* eslint-disable react/prop-types */

import { GoogleOAuthProvider } from '@react-oauth/google';
import { useTranslation } from 'react-i18next';
import { GoogleCalendarLogo, XIcon } from '../Icons';

import CalendarIntegrationInPopup from './CalendarIntegrationsPopup';

export default function HubspotSalesForceNoCalendarConnectedPopup({ integrationName, setShowHubspotCalendarRequiredPopup, calendarIsIntegrated, forceRenewUserData, email_google_calendar }) {
  const { t } = useTranslation();

  return <>
    <div className="transcript-info-popup-container have-you-installed-slack">
      <div className="popup-background" onClick={() => setShowHubspotCalendarRequiredPopup(false)} style={{ background: 'rgba(0,0,0,.5)' }}></div>
      <div className="popup-content">
        <div className="close-icon" onClick={() => setShowHubspotCalendarRequiredPopup(false)}>
          <XIcon color="var(--brand-primary)" />
        </div>

        <h1 style={{ fontSize: 24 }}> <GoogleCalendarLogo size="28" style={{ position: 'relative', left: -3, top: 3 }} /> Google Calendar is not connected</h1>

        <p>
          {t('settings.integrations.no-hubspot-calendar.title', { integrationName, defaultValue: 'Google Calendar is required for automatic contact detection in {{integrationName}} integration:' })}
          <ul style={{ paddingLeft: 53 }}>
            <li>{t('settings.integrations.no-hubspot-calendar.step1', { integrationName, defaultValue: 'First Google Calendar event attendee of Google Meet meeting, whose email exists as {{integrationName}} contact, is associated with created {{integrationName}} meeting.' })}</li>
            <li>{t('settings.integrations.no-hubspot-calendar.step2', 'If contact could not be detected, Briefly will send message in Slack asking which contact to associate meeting with (if Slack is connected to Briefly account)')}</li>
          </ul>
        </p>


        <GoogleOAuthProvider clientId="576404817524-ss93f3dua36hvaum9oi4vqrmu8anc6o3.apps.googleusercontent.com">
          <CalendarIntegrationInPopup style={{ margin: "auto" }} calendarIsIntegrated={calendarIsIntegrated} forceRenewUserData={forceRenewUserData} email_google_calendar={email_google_calendar} />
        </GoogleOAuthProvider>
      </div>
    </div>
  </>;
}
