import React, { createContext, useEffect, useState } from "react";
import { apiGet } from "./utils/api";
import { ChatThread } from "./components/Chat/ShowChat/types";
import { Meeting } from "./components/Reusable/MeetingFilter/types";

interface LoadArgs {
  chatId: string,
  signal?: AbortSignal,
}

interface ChatThreadContextData {
  chatThreads: ChatThread[],
  transcriptsById: Record<string, Meeting[]>,
  loadChatThread: (args: LoadArgs) => Promise<void>,
  setChatThread: (chatThread: ChatThread) => void,
  loadChatThreads: (signal: AbortSignal) => Promise<void>,
}

const noop = (_: unknown) => Promise.resolve();

export const ChatThreadContext = createContext<ChatThreadContextData>({
  chatThreads: [], 
  loadChatThread: noop,
  transcriptsById: {},
  setChatThread: noop,
  loadChatThreads: noop
});

function ChatThreadProvider({ children }: { children: JSX.Element[] }): JSX.Element {
  const [chatThreads, setChatThreads] = useState<ChatThread[]>([]);
  const [transcriptsById, setTranscriptsById] = useState<Record<string, Meeting[]>>({});

  const setChatThread = (chatThread: ChatThread) => {
    let found = false;
    const threads = chatThreads.map((ct) => {
      if (ct.uuid !== chatThread.uuid) {
        return ct;
      }

      found = true
      return chatThread;
    })

    if (!found) threads.push(chatThread);

    setChatThreads(threads);
  }

  const loadChatThread = async ({ chatId, signal }: LoadArgs) => {
    const { chat_thread: chatThread, transcripts } = await apiGet<ChatThread | Meeting[], 'chat_thread' | 'transcripts'>({
      path: `/chat-threads/${chatId}`,
      signal
    }) as { chat_thread: ChatThread, transcripts: Meeting[] };

    setChatThread(chatThread);
    setTranscriptsById((current) => ({ ...current, [chatId]: transcripts }));
  }

  const loadChatThreads = async (signal: AbortSignal) => {
    const { chat_threads } = await apiGet<ChatThread[], 'chat_threads'>({ path: '/chat-threads', signal })
    setChatThreads(chat_threads);
  };

  useEffect(() => {
    const abortController = new AbortController()

    loadChatThreads(abortController.signal)

    return () => abortController.abort();
  }, []);

  return <ChatThreadContext.Provider value={{ chatThreads, loadChatThread, loadChatThreads, transcriptsById, setChatThread }}>
    {children}
  </ChatThreadContext.Provider>;
};

export default ChatThreadProvider;
