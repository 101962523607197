
import React, { useEffect, useState } from 'react';
import { useUserData } from '../hooks/useUserData';
import { trackEvent, updateUserSettings, } from '../../utils/genericUtils';
import Radio from '../controls/Radio';
import Checkbox from '../controls/Checkbox';
import { SLACK_CLIENT_ID, } from '../../config/config';
import { Trans, useTranslation } from 'react-i18next';
import { CaretIcon, SlackLogo, XIcon } from '../Icons';
import { Button, ButtonLink, HR, FlexCenter10, H2, BodyLight, FlexColumn10 } from '../../theme';
import { connectedToEmailBadge } from './SettingsTabIntegrations';
import styled from 'styled-components';

const BackButton = styled(ButtonLink)`
  margin-bottom: 16px;
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 25px;
`;
const Integration = styled.span`
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 600;
`;
const Switch = styled.label`
  align-self: center;
`;
const ManageSlack = () => {
  const [showHaveYouInstalledSlackAppPopup, setShowHaveYouInstalledSlackAppPopup] = useState(false);
  const [dontShowHaveYouInstalledSlackAgain, setDontShowHaveYouInstalledSlackAgain] = useState(false);
  const [{
    is_subscribed_to_slack_pre_briefs, is_subscribed_to_slack_summaries, is_summaries_dm, is_briefs_dm, is_integration_active_briefly_success, slack_integration_is_visible, slack_workspace_name, admin_user_subscription_is_active, admin_user, subscription_is_active,
  }, forceRenewUserData] = useUserData();

  const userIsPaid = (subscription_is_active || (!!admin_user && admin_user_subscription_is_active));

  const { t } = useTranslation();

  useEffect(() => {
    if (!localStorage.getItem('HAVE_YOU_INSTALLED_SLACK_TIP_CLOSED') && userIsPaid && !slack_workspace_name) {
      setShowHaveYouInstalledSlackAppPopup(true);
    }
  }, []);

  return <>
    {showHaveYouInstalledSlackAppPopup && <>
      <div className="transcript-info-popup-container">
        <div className="popup-background" onClick={() => setShowHaveYouInstalledSlackAppPopup(false)} style={{ background: 'rgba(0,0,0,.5)' }}></div>
        <div className="popup-content">
          <div className="close-icon" onClick={() => setShowHaveYouInstalledSlackAppPopup(false)}>
            <XIcon color="var(--brand-primary)" />
          </div>

          <h1>{t('settings.slack.popup.title', 'Have you installed Briefly Slack App?')}</h1>

          <p>
            {t('settings.slack.popup.description', 'You must add Briefly to Slack in order to use these features')}
          </p>

          <Checkbox isChecked={dontShowHaveYouInstalledSlackAgain} label={t("settings.slack.popup.dont-show", "Don’t show this message again")} setIsChecked={isChecked => {
            if (isChecked) {
              localStorage.setItem('HAVE_YOU_INSTALLED_SLACK_TIP_CLOSED', "1");
              setDontShowHaveYouInstalledSlackAgain(true);
            } else {
              localStorage.removeItem('HAVE_YOU_INSTALLED_SLACK_TIP_CLOSED');
              setDontShowHaveYouInstalledSlackAgain(false);
            }
          }} />

          <br />
          <FlexCenter10>
            <ButtonLink secondary onClick={() => {
              trackEvent("briefly-add-to-slack-button-clicked", {
              });
            }} target="_blank" to={`https://slack.com/oauth/v2/authorize?client_id=${SLACK_CLIENT_ID}&scope=app_mentions:read,channels:history,channels:join,channels:manage,channels:read,chat:write,chat:write.customize,chat:write.public,files:write,groups:read,groups:write,im:history,im:read,im:write,links:write,mpim:history,mpim:read,mpim:write,pins:write,reminders:read,reminders:write,users:read,users:read.email,groups:history&user_scope=`} rel="noreferrer">
              <SlackLogo width="28" />{t('settings.slack.popup.add-to-slack', 'Add to Slack')}
            </ButtonLink>
            <Button onClick={() => {
              setShowHaveYouInstalledSlackAppPopup(false);
            }}>
              {t('settings.slack-about.manage', 'Manage Slack integration')}
            </Button>
          </FlexCenter10>
        </div>
      </div>
    </>}

    <BackButton transparent small to='/settings/integrations' >
      <CaretIcon style={{ transform: "rotate(90deg)" }} />
      <span>{t('back', { ns: 'Actions', defaultValue: 'Back' })}</span>
    </BackButton>
    <div>
      <Row>
        <H2>{userIsPaid ? t('settings.slack.title-paid', 'Slack integration settings') : t('settings.slack.title', 'Slack integration')}</H2>
        {slack_workspace_name ? connectedToEmailBadge(slack_workspace_name, t) : <ButtonLink secondary medium onClick={() => {
          trackEvent("briefly-add-to-slack-button-clicked", {});
        }} target="_blank" to={`https://slack.com/oauth/v2/authorize?client_id=${SLACK_CLIENT_ID}&scope=app_mentions:read,channels:history,channels:join,channels:manage,channels:read,chat:write,chat:write.customize,chat:write.public,files:write,groups:read,groups:write,im:history,im:read,im:write,links:write,mpim:history,mpim:read,mpim:write,pins:write,reminders:read,reminders:write,users:read,users:read.email,groups:history&user_scope=`} rel="noreferrer">
          <SlackLogo width="18" />{t('settings.slack.popup.add-to-slack', 'Add to Slack')}
        </ButtonLink>}
      </Row>

      {userIsPaid ? (<>
        <HR />
        <FlexColumn10>
          <Row>
            <div >
              <Integration >
                {t('settings.slack.summaries.title', 'Post-meeting Summaries')}
              </Integration>
              <BodyLight>
                {t('settings.slack.summaries.description', 'After each call receive your AI summary directly into Slack')}
              </BodyLight>
            </div>
            <Switch className="switch">
              <input type="checkbox" checked={is_subscribed_to_slack_summaries} onClick={() => {

                updateUserSettings({ is_subscribed_to_slack_summaries }, () => {
                  forceRenewUserData();

                  if (is_subscribed_to_slack_summaries) {
                    trackEvent("summaries-to-slack-enabled", {
                    });
                  } else {
                    trackEvent("summaries-to-slack-disabled", {
                    });
                  }
                });

              }} />
              <span className="slider round"></span>
            </Switch>

          </Row>
          <Row>
            <div style={{ width: "100%", }}>
              <Integration>
                {t('settings.slack.message-target.title', 'Where would you like to receive this?')}
              </Integration>
              <div style={{ color: "var(--textSecondary)", marginTop: "10px", display: "flex", flexDirection: "column", gap: "6px", alignItems: "flex-end" }} >
                <Radio isChecked={is_summaries_dm} setIsChecked={() => {
                  updateUserSettings({
                    is_summaries_dm: true
                  }, () => {
                    forceRenewUserData();
                  });
                }} label={t("settings.slack.message-target.DM", "DM")} />
                <Radio isChecked={!is_summaries_dm} setIsChecked={() => {
                  updateUserSettings({
                    is_summaries_dm: false
                  }, () => {
                    forceRenewUserData();
                  });
                }} label={t("settings.slack.message-target.channel", "Private channel")} />
              </div>
            </div>
          </Row>
        </FlexColumn10>
      </>
      ) : (<>
        <HR />
        <Row>
          <div>
            <Integration>
              {t('settings.slack.not-pro.title', 'Slack integration is a PRO feature only')}
            </Integration>
            <BodyLight>
              {t('settings.slack.not-pro.upgrade-label', 'Upgrade to a PRO account to use this feature')}
            </BodyLight>
          </div>
          <ButtonLink medium to='/settings/subscriptions'>{t('settings.slack.not-pro.upgrade', 'Upgrade to PRO')}</ButtonLink>
        </Row>
      </>
      )}

      {slack_integration_is_visible &&
        <>
          <HR />
          <Row>
            <div>
              <Integration>
                {t('settings.slack.customer-profiles.title', 'Customer profiles')}
              </Integration>
              <BodyLight>
                <Trans i18nKey='settings.slack.customer-profiles.description'>
                  Create and manage customers profiles with Briefly in Slack. How does this work?
                </Trans>
              </BodyLight>
            </div>
            <Switch className='switch'>
              <input type="checkbox" checked={is_integration_active_briefly_success} onClick={() => {
                updateUserSettings(is_integration_active_briefly_success ? { is_integration_active_briefly_success: true } : {
                  is_integration_active_briefly_success: false,
                  is_subscribed_to_slack_pre_briefs: false
                }, () => {
                  if (is_integration_active_briefly_success) {
                    trackEvent("customer-profiles-enabled", {
                    });
                  } else {
                    trackEvent("customer-profiles-disabled", {
                    });
                    trackEvent("pre-meeting-briefs-disabled", {
                    });
                  }
                  forceRenewUserData();
                });

              }} />
              <span className="slider round"></span>
            </Switch>
          </Row>

          <HR />
          <FlexColumn10>
            <Row>
              <div>
                <Integration>
                  {t('settings.slack.briefing.title', 'Customer Briefs 15 minutes before call')}
                </Integration>
                <BodyLight>
                  {t('settings.slack.briefing.description', 'Before each call starts, get a bespoke briefing about the person you are about to meet with')}
                </BodyLight>
              </div>
              <Switch className="switch">
                <input type="checkbox" checked={is_subscribed_to_slack_pre_briefs} onClick={() => {

                  updateUserSettings(is_subscribed_to_slack_pre_briefs ? {
                    is_integration_active_briefly_success: true,
                    is_subscribed_to_slack_pre_briefs: true
                  } : { is_subscribed_to_slack_pre_briefs: false }, () => {
                    if (is_subscribed_to_slack_pre_briefs) {
                      trackEvent("customer-profiles-enabled", {
                      });
                      trackEvent("pre-meeting-briefs-enabled", {
                      });
                    } else {
                      trackEvent("pre-meeting-briefs-disabled", {
                      });
                    }
                    forceRenewUserData();
                  });

                }} />
                <span className="slider round"></span>
              </Switch>
            </Row>
            <Row>
              <div style={{ width: "100%", }}>
                <Integration>
                  {t('settings.slack.reminder.title', 'Where would you like to receive the reminder?')}
                </Integration>
                <div style={{ color: "var(--textSecondary)", marginTop: "10px", display: "flex", flexDirection: "column", gap: "6px", alignItems: "flex-end" }}>
                  <Radio isChecked={is_briefs_dm} setIsChecked={() => {
                    updateUserSettings({
                      is_briefs_dm: true
                    }, () => {
                      forceRenewUserData();
                    });
                  }} label={t("settings.slack.message-target.DM", "DM")} />
                  <Radio isChecked={!is_briefs_dm} setIsChecked={() => {
                    updateUserSettings({
                      is_briefs_dm: false
                    }, () => {
                      forceRenewUserData();
                    });
                  }} label={t("settings.slack.message-target.channel", "Private channel")} />
                </div>
              </div>
            </Row>
          </FlexColumn10>
        </>}

    </div>
  </>;
};

export default ManageSlack;