import { Tooltip } from 'react-tooltip';
import { useTranslation } from "react-i18next";
import { useLoaderData, Link as A } from "react-router-dom";

import { AppSquares, GoogleCalendarLogo, GoogleMeetLogo, HubspotCircleLogo, SalesforceLogo, SlackLogo, ZoomLogo } from '../Icons';
import { LoaderData } from '../../types/LoaderData';
import { UserData } from '../MeetingCall/Types';
import styled from 'styled-components';
import Accordion from '../Reusable/Accordion';
import { EXTENSION_URL } from '../../config/config';


const App = styled.div`
  display: flex;
  gap: 8px;
  padding: 12px 16px;
  border: solid transparent 1px;
  border-radius: var(--standardRadius);

  &:hover {
    border: var(--standardBorder);
  }
`;

const Link = styled(A)`
  display: flex;
  gap: 8px;
`;

interface Props {
  userData: UserData;
  isActive: boolean;
}

const Integrations = ({ userData, isActive }: Props) => {
  const { t } = useTranslation();
  const { brieflyIsInstalled, } = useLoaderData() as LoaderData;

  const {
    features,
    is_integration_active_google_calendar,
    is_integration_active_hubspot,
    is_integration_active_salesforce,
    slack_workspace_name,
    zoom_is_setup,
  } = userData;

  const INTEGRATIONS_CONFIG = [
    {
      icon: <GoogleMeetLogo width="20" style={{ pointerEvents: 'none', }} />,
      is_enabled: brieflyIsInstalled,
      label: 'Google Meet',
      path: '/settings/integrations',
      onClick: !brieflyIsInstalled ? () => window.open(EXTENSION_URL, '_blank')?.focus() : undefined
    },
    {
      icon: <ZoomLogo size="20" style={{ pointerEvents: 'none' }} />,
      is_enabled: zoom_is_setup,
      label: 'Zoom',
      path: '/settings/integrations/zoom',
    },
    {
      icon: <GoogleCalendarLogo size="20" style={{ pointerEvents: 'none' }} />,
      is_enabled: is_integration_active_google_calendar,
      label: 'Google Calendar',
      path: '/settings/integrations',
    },
    {
      icon: <SlackLogo width="20" style={{ pointerEvents: 'none' }} />,
      is_enabled: !!slack_workspace_name,
      label: 'Slack',
      path: '/settings/integrations/slack',
    },
    {
      icon: <HubspotCircleLogo size="20" style={{ pointerEvents: 'none' }} />,
      is_enabled: is_integration_active_hubspot,
      label: 'Hubspot',
      path: '/settings/integrations/hubspot',
    },
    {
      icon: <SalesforceLogo width="20" style={{ pointerEvents: 'none' }} />,
      is_enabled: is_integration_active_salesforce,
      label: 'Salesforce',
      path: '/settings/integrations/salesforce',
    }
  ];


  if (!features || !features?.integrations) {
    return <></>;
  }

  return (

    <Accordion
      title={t('root-sidebar.apps', 'My Apps')}
      icon={<AppSquares />}
      defaultOpen
      isActive={isActive}
    >

      {INTEGRATIONS_CONFIG.map((integration, i) => {
        const { path, icon, label, is_enabled, onClick } = integration;

        const tooltipContent = {
          "Google Meet": {
            true: t('root-sidebar.integrations.extension.installed', { integration: "Google Meet", defaultValue: "{{ integration }} installed"}),
            false: t('root-sidebar.integrations.extension.not-installed', { integration: "Google Meet", defaultValue: "{{ integration }} not installed"}),
          },
          "Zoom": {
            true: t('root-sidebar.integrations.connection.connected', { integration: "Zoom", defaultValue: "{{ integration }} connected"}),
            false: t('root-sidebar.integrations.connection.not-connected', { integration: "Zoom", defaultValue: "{{ integration }} not connected"}),
          },
          "Google Calendar": {
            true: t('root-sidebar.integrations.connection.connected', { integration: "Google Calendar", defaultValue: "{{ integration }} connected"}),
            false: t('root-sidebar.integrations.connection.not-connected', { integration: "Google Calendar", defaultValue: "{{ integration }} not connected"}),
          },
          "Slack": {
            true: t('root-sidebar.integrations.connection.connected', { integration: "Slack", defaultValue: "{{ integration }} connected"}),
            false: t('root-sidebar.integrations.connection.not-connected', { integration: "Slack", defaultValue: "{{ integration }} not connected"}),
          },
          "Hubspot": {
            true: t('root-sidebar.integrations.crm.enabled', { integration: "Hubspot", defaultValue: "{{ integration }} enabled"}),
            false: t('root-sidebar.integrations.crm.not-enabled', { integration: "Hubspot", defaultValue: "{{ integration }} not enabled"}),
          },
          "Salesforce": {
            true: t('root-sidebar.integrations.crm.enabled', { integration: "Salesforce", defaultValue: "{{ integration }} enabled"}),
            false: t('root-sidebar.integrations.crm.not-enabled', { integration: "Salesforce", defaultValue: "{{ integration }} not enabled" }),
          }
        };

        return (
          <App key={label}>
            <Link to={path} onClick={onClick} data-tooltip-id={"tooltip-button-" + i}
              data-tooltip-content={tooltipContent[label][is_enabled]}>
              {icon}<span>{label}</span>
            </Link>
            <Tooltip id={"tooltip-button-" + i} place="bottom"
              content={tooltipContent[label][is_enabled]} />
          </App>
        );
      })}
    </Accordion>
  );
};

export default Integrations;