import styled, { css } from 'styled-components';
import { Button } from '../../../theme';
import { MessageType } from './types';
import CopyToClipboard from '../../Reusable/CopyToClipboard';

export const Container = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const SparkleContainer = styled.span`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  left: -40px;
  top: 0px;
  background: linear-gradient(var(--focusGradientPrimary));
  color: white;
  border-radius: 50%;
`;

export const ChatControl = styled.div`
  display: flex;
  background-color: rgba(255, 255, 255, 0.85);
  padding: 15px 20px;
  gap: 20px;
  box-sizing: border-box;
  border-top: var(--standardBorder);
  border-radius: var(--standardRadius);
  margin-top: auto;
`;

export const ChatLogContainer = styled.div`
  border: var(--standardBorder);
  border-radius: var(--standardRadius);
  background-color: var(--gray300);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 300px);
`;

export const ChatScroll = styled.div`
  overflow: scroll;
  flex-basis: 100%;
`;

export const ChatLog = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding-bottom: 30px;
  max-width: 50vw;
  min-width: 700px;
  margin: 0 auto;
`

export const CopyChatButton = styled(CopyToClipboard)`
  position: absolute;
  right: 4px;
  top: 4px;
`;

export const Bubble = styled.li<{ $messageType?: MessageType }>`
  position: relative;
  padding: 16px;
  border-radius: var(--standardRadius);
  margin-top: 20px;

  p {
    margin: 0;
  }

  &.user {
    align-self: flex-end;
    background-color: white;
    padding: 16px;
    margin-left: 2rem;
  }


  &.assistant {
    align-self: flex-start;
    background: var(--secondary100);
    padding-right: 34px;
    margin-right: 2rem;

    ${({ $messageType }) => $messageType === 'fileChangeNote' && (css`
      font-size: 12px;
      color: var(--gray500);
      background: none;
    `)}

    & + .assistant {
      margin-top: 0.5rem;
    }
  }

  a em {
    font-size: 10px;
    vertical-align: text-top;
    text-decoration: underline;
    color: var(--primary600);
  }
`;

const TextAreaCss = (css`
  box-sizing: border-box;
  border: none;
  flex-grow: 1;
  width: 100%;
  font-family: inherit;
  flex-grow: 1;
  resize: none;
  overflow: hidden;
  font-size: 16px;
  &:focus {
    outline: none;
  }

  grid-area: 1 / 1 / 2 / 2;
`);

export const ChatInput = styled.textarea`
  ${TextAreaCss}
`;

export const ChatInputWrapper = styled.div`
  display: grid;
  width: 100%;
  max-height: 6rem;
  overflow-y: auto;
  padding: 0.2rem 0.5rem;
  margin-right: 0.2rem;

  &::after {
    content: attr(data-textarea-content) " ";
    white-space: pre-wrap;
    visibility: hidden;
  }

  & ${ChatInput} {
    resize: none;
    overflow: hidden;
  }

  &::after, & ${ChatInput} {
    ${TextAreaCss}
  }
`

export const Form = styled.form`
  display: flex;
  align-items: center;
  border: var(--standardBorder);
  border-radius: var(--standardRadius);
  width: 100%;
  padding: 0 4px;
  &:focus-within {
    outline: 2px solid var(--brand-primary);
  }
`;

export const SubmitButton = styled(Button)`
  width: 100px;
`;

export const EmptyState = styled.span`
  align-self: center;
  color: var(--gray500);
  margin: 50px 0;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.h1`
  margin: 0;
  font-size: 24px;
`;

export const TitleHelper = styled.span<{ $primary?: boolean }>`
  font-size: 20px;
  font-weight: bold;

  ${({ $primary }) => $primary && css`
    color: var(--primary600);
  `}
`;


export const SubTitle = styled.h2`
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  color: var(--gray500);
`;


