
import React from 'react';
import { SvgSize } from '../types';

const GoogleCalendarLogo = (props: SvgSize) => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 200 200" width={props?.size ?? '12'} height={props?.size ?? '12'}  {...props}>
    <title>Google Calendar Logo</title>
    <path fill="#FFF" d="m152.632 47.368-47.368-5.263-57.895 5.263L42.105 100l5.263 52.632L100 159.211l52.632-6.579 5.263-53.947-5.263-51.317z" /><path fill="#1A73E8" d="M68.961 129.026c-3.934-2.658-6.658-6.539-8.145-11.671l9.132-3.763c.829 3.158 2.276 5.605 4.342 7.342 2.053 1.737 4.553 2.592 7.474 2.592 2.987 0 5.553-.908 7.697-2.724s3.224-4.132 3.224-6.934c0-2.868-1.132-5.211-3.395-7.026s-5.105-2.724-8.5-2.724h-5.276v-9.039h4.736c2.921 0 5.382-.789 7.382-2.368 2-1.579 3-3.737 3-6.487 0-2.447-.895-4.395-2.684-5.855s-4.053-2.197-6.803-2.197c-2.684 0-4.816.711-6.395 2.145s-2.724 3.197-3.447 5.276l-9.039-3.763c1.197-3.395 3.395-6.395 6.618-8.987 3.224-2.592 7.342-3.895 12.342-3.895 3.697 0 7.026.711 9.974 2.145 2.947 1.434 5.263 3.421 6.934 5.947 1.671 2.539 2.5 5.382 2.5 8.539 0 3.224-.776 5.947-2.329 8.184-1.553 2.237-3.461 3.947-5.724 5.145v.539a17.379 17.379 0 0 1 7.342 5.724c1.908 2.566 2.868 5.632 2.868 9.211s-.908 6.776-2.724 9.579c-1.816 2.803-4.329 5.013-7.513 6.618-3.197 1.605-6.789 2.421-10.776 2.421-4.618.013-8.881-1.316-12.815-3.974zM125 83.711l-9.974 7.25-5.013-7.605L128 70.382h6.895v61.197H125V83.711z" /><path fill="#EA4335" d="M152.632 200 200 152.632l-23.684-10.526-23.684 10.526-10.526 23.684L152.632 200z" /><path fill="#34A853" d="M36.842 176.316 47.368 200h105.263v-47.368H47.368l-10.526 23.684z" /><path fill="#4285F4" d="M15.789 0C7.066 0 0 7.066 0 15.789v136.842l23.684 10.526 23.684-10.526V47.368h105.263l10.526-23.684L152.632 0H15.789z" /><path fill="#188038" d="M0 152.632v31.579C0 192.935 7.066 200 15.789 200h31.579v-47.368H0z" /><path fill="#FBBC04" d="M152.632 47.368v105.263H200V47.368l-23.684-10.526-23.684 10.526z" /><path fill="#1967D2" d="M200 47.368V15.789C200 7.065 192.934 0 184.211 0h-31.579v47.368H200z" /></svg>
);

export default GoogleCalendarLogo;

