import styled from 'styled-components';

export const LinkButton = styled.a`
  display: flex;
  gap: 5px;
  text-decoration: none;
  align-items: center;
  color: var(--textPrimary);
`;

export const Page = styled.div`
  background-color: var(--background);
  height: 100vh;
  color: var(--textPrimary);
  display: flex;
  flex-direction: column;
  a {
    color: var(--brand-primary);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--textPrimary);
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 15px;
`;

export const Message = styled.span`
  max-width: 680px;
  text-align: center;
  line-height: 1.4;
`;


