import React, { useState } from 'react';
import { Actions, Button, Hr, FormGrid, Label, FullRow } from './style';
import AsyncSearchSelect, { Option } from '../forms/AsyncSearchSelect';
import { apiGet } from '../../utils/genericUtils';
import { SalesforceContact } from '.';
import { ThreeDots } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const CenteredButton = styled(Button)`
  margin: 0 auto;
`;

interface Props {
  onToggle: () => void,
  onCancel: () => void,
  onSuccess: (salesforceContact: SalesforceContact) => Promise<void>,
}

export default function SelectContactForm({ onToggle, onCancel, onSuccess }: Props): JSX.Element {
  const [contact, setContact] = useState<Option | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const handleAccountSearch = async (value: string) => {
    return apiGet({ path: `/briefly/search-salesforce-contacts?q=${value}` });
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const body = Object.fromEntries(new FormData(e.currentTarget));
      const response: SalesforceContact = await apiGet({ path: `/services/salesforce/Contact/${body.contactId}` });
      await onSuccess(response);
    } finally {
      setIsLoading(false);
    }
  };

  return <FormGrid onSubmit={handleSubmit}>
    <Label $fullWidth>
      <input type="hidden" value={contact?.value || ''} name="contactId" />
      <AsyncSearchSelect onSearch={handleAccountSearch} onChange={setContact} placeholder={t("meeting.salesforce.contact-form.select-form.search", "Search for a contact")} />
    </Label>
    <FullRow>
      <Hr><span>{t('meeting.salesforce.contact-form.select-form.or', 'Or')}</span></Hr>
    </FullRow>
    <FullRow>
      <CenteredButton secondary small onClick={onToggle}>{t('meeting.salesforce.contact-form.select-form.create', 'Create new contact in Salesforce')}</CenteredButton>
    </FullRow>
    <Actions>
      <Button onClick={onCancel} secondary inline small>Cancel</Button>
      <Button inline small disabled={isLoading || contact?.value == null}>
        {isLoading ? <ThreeDots
          height="5"
          width="40"
          radius="9"
          color="white"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          visible={true}
        /> : t('confirm', { ns: 'Actions', defaultValue: 'Confirm' })}
      </Button>
    </Actions>
  </FormGrid>;
}

