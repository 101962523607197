import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { useUserData } from './hooks/useUserData';
import { BrieflyLogo, StarIcon, QuestionMarkIcon, } from "./Icons";
import AICreditsPopup from "./Reusable/AICreditsPopup";
import styled from 'styled-components';


const Container = styled.header`
  background: linear-gradient(var(--primaryGradient));
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
  height: var(--topNav-height);
  box-sizing: border-box;
`;

const FreeCredits = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: var(--white);
`;

const Badge = styled(Link)`
  background: var(--background);
  border-radius: var(--standardRadius);
  color: var(--brand-secondary);
  padding: '0 5px';
  display: 'flex';
  align-items: 'center';
  padding: 8px 16px;
  weight: 600;
`;

interface Props {
  style?: React.CSSProperties;
}

const TopNav = ({ style }: Props) => {
  const [userData] = useUserData();
  const { t } = useTranslation();

  const {
    admin_user,
    admin_user_subscription_is_active,
    managed_users,
    managed_users_not_confirmed,
    subscription_is_active,
    subscription_plan,
  } = userData;
  const isTeamAccount = managed_users ? (managed_users.concat(managed_users_not_confirmed)).length > 0 : false;
  const isTeamMemberAccount = !!admin_user;
  const isProGivenForFree = subscription_plan === 'STANDARD_GIVEN_FOR_FREE' && !isTeamMemberAccount;

  const showCredits = !isProGivenForFree && !userData.error && !subscription_is_active && !isTeamMemberAccount || (isTeamMemberAccount && !admin_user_subscription_is_active);

  const showBadge = !userData.error && !isProGivenForFree;

  return (
    <Container style={style}>
      <div>
        <Link to="/">
          <BrieflyLogo style={{ margin: '6px 15px', marginTop: '10px' }} />
        </Link>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', gap: "8px" }}>
        {showCredits &&
          <FreeCredits>
            {t('top-bar.credits', { count: userData.briefly_ai_credits_left, defaultValue: `${userData.briefly_ai_credits_left} free AI credits left` })}
            <div className="ai-credits-help-container">
              <QuestionMarkIcon data-tooltip-id="tooltip-button-ai-credits" style={{ cursor: 'pointer', position: 'relative', top: 2, marginLeft: 3 }} />
              <AICreditsPopup />
            </div >

          </ FreeCredits>}
        {showBadge &&
          <Badge to='/settings/subscriptions' >
            <StarIcon style={{ position: 'relative', marginRight: 3, marginLeft: 3 }} />
            {!(subscription_is_active || (isTeamMemberAccount && admin_user_subscription_is_active)) ? 'UPGRADE TO PRO' : isTeamAccount || isTeamMemberAccount ? 'TEAM' : 'PRO'}
          </ Badge>
        }
      </div >
    </Container>
  );
};

export default TopNav;
