import { useTranslation } from "react-i18next";
import { ExclamationIcon } from "../Icons";



const IssueDetected = () => {
  const { t } = useTranslation();

  return (
    <div style={{ display: 'inline-flex', alignItems: "center", gap: "10px", background: 'var(--error)', padding: '3px 6px', borderRadius: 4, fontSize: 12, fontWeight: 700, color: "var(--white)" }}>
      <ExclamationIcon />
      {t('settings.issue-detected.label', 'Issue detected')}
    </div>
  );
};
export default IssueDetected;