import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

const AICreditsPopup = () => {
  const { t } = useTranslation();
  // todo: Tooltip doesn't allow you to click the link inside since the popup closes off hover
  return (
    <Tooltip id="tooltip-button-ai-credits" className="ai-credits-popup" style={{
      width: 200,
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: 400,
      letterSpacing: 0.3,
      lineHeight: '18px',
      marginLeft: '-5px',
      marginRight: '-5px',
      zIndex: 99999999999
    }}>
      <p style={{ margin: 0, marginBottom: 10 }}>
        {t('top-bar.credits-popup.label',
          'AI summary, BrieflyCreate Output or email follow up generation, will use 1 AI credit. Transcriptions and transcript uploads are unlimited on your free plan.'
        )}
      </p>
      <Link to='/settings/subscriptions'  >
        {t('top-bar.credits-popup.upgrade', 'Upgrade now')}
      </ Link>
    </Tooltip >
  );
};
export default AICreditsPopup;