
const ExclamationIcon = (props: React.HTMLAttributes<HTMLOrSVGElement>) => (
  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Exclamation Icon</title>
    <g clipPath="url(#clip0_1023_50429)">
      <path d="M6 7.09155C6.3267 7.09155 6.59155 6.8267 6.59155 6.5V4.07746C6.59155 3.75076 6.3267 3.48592 6 3.48592C5.6733 3.48592 5.40845 3.75076 5.40845 4.07746V6.5C5.40845 6.8267 5.6733 7.09155 6 7.09155ZM6 9.51408C6.3267 9.51408 6.59155 9.24924 6.59155 8.92253V8.89437C6.59155 8.56766 6.3267 8.30282 6 8.30282C5.6733 8.30282 5.40845 8.56766 5.40845 8.89437V8.92253C5.40845 9.24924 5.6733 9.51408 6 9.51408ZM1.74648 2.27465C2.92958 1.09155 4.34742 0.5 6 0.5C7.65258 0.5 9.06103 1.09155 10.2254 2.27465C11.4085 3.43897 12 4.84742 12 6.5C12 8.15258 11.4085 9.57042 10.2254 10.7535C9.06103 11.9178 7.65258 12.5 6 12.5C4.34742 12.5 2.92958 11.9178 1.74648 10.7535C0.58216 9.57042 0 8.15258 0 6.5C0 4.84742 0.58216 3.43897 1.74648 2.27465Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_1023_50429">
        <rect width="12" height="12" fill="currentColor" transform="translate(0 0.5)" {...props} />
      </clipPath>
    </defs>
  </svg>
);

export default ExclamationIcon;
