import { useGoogleLogin } from '@react-oauth/google';
import { useTranslation } from 'react-i18next';
import { authCalendar } from '../../utils/genericUtils';
import { GoogleCalendarLogo } from '../Icons';
import { Button } from '../../theme';


interface Props {
  forceRenewUserData: () => void;
  style: React.CSSProperties;
}

const CalendarIntegrationInPopup = ({ forceRenewUserData, style = {} }: Props) => {
  const { t } = useTranslation();

  function onCalendarLoginSuccess(data) {

    authCalendar(data.code, () => {

      setTimeout(() => {
        forceRenewUserData();
      }, 500);
    });
  }

  const calendarLogin = useGoogleLogin({
    onSuccess: codeResponse => onCalendarLoginSuccess(codeResponse),
    flow: 'auth-code',
    scope: "openid profile email https://www.googleapis.com/auth/calendar.readonly"
  });

  return (
    <Button secondary onClick={calendarLogin} style={{ ...style }}>
      <GoogleCalendarLogo size="18" /> {t('settings.calendar.connect-calendar', 'Connect Google Calendar')}
    </Button>
  );
};

export default CalendarIntegrationInPopup;