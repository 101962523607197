import ComputerMonitorSvg from '../../images/ComputerMonitorSvg';
import { ButtonLink } from '../../theme';


export function MobileNotAvailableState() {
  return <div className="edge-case-state">
    <ComputerMonitorSvg />
    <h3>Briefly is not available on mobile, please access from desktop</h3>
    <ButtonLink href="https://brieflyai.com" target="_blank" rel="noreferrer">Find out more about Briefly</ButtonLink>

  </div>;
}


export default MobileNotAvailableState;