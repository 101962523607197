import React, { useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';

import { BACKEND_URL, I18N_SUPPORT } from '../../config/config';
import { csrfGet, csrfPost } from '../../utils/genericUtils';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../controls/LanguageSelector';
import { BrieflyLogo, CheckmarkCircleIcon, CopyIcon, GoogleLogo, SparklesIcon } from '../Icons';
import { A } from '../../theme';
import Antler from '../Icons/BrandLogos/Antler.svg';
import AppAcademy from '../Icons/BrandLogos/AppAcademy.svg';
import AthleticGreens from '../Icons/BrandLogos/AthleticGreens.svg';
import Etrainu from '../Icons/BrandLogos/Etrainu.svg';
import Harri from '../Icons/BrandLogos/Harri.svg';
import Hellofresh from '../Icons/BrandLogos/Hellofresh.svg';
import SalesforceMarketplace from '../Icons/BrandLogos/SalesforceMarketplace.svg';
import Voyages from '../Icons/BrandLogos/Voyages.svg';
import Wonderist from '../Icons/BrandLogos/Wonderist.svg';

import styled from 'styled-components';
import OutlineButton from '../Reusable/OutlineButton';

const Container = styled.div`
  background: linear-gradient(var(--secondaryGradient));
  width: 100%;
  min-height: calc(100vh - 32px);
  padding: 16px;
  display: flex;
`;

const Left = styled.div`
  padding: 32px 40px;
  padding-left: 24px;
  color: var(--white);
  display: flex;
  flex-direction: column;
  gap: 74px;
  width: 36%;

  > svg {
    width: 237px;
    height: auto;
  }
`;

const Topic = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const H2 = styled.div`
  font-weight: 700;
  font-size: 18px;
  display: flex;
  gap: 18px;
`;
const Text = styled.div`
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
`;

const Logos = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  opacity: .5;
`;

const Right = styled.div`
  background: var(--background);
  border-radius: var(--standardRadius);
  padding: 32px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 100%;
`;


export function UserNotLoggedInState() {
  const { t } = useTranslation();
  const [forceOkta, setForceOkta] = useState(false)


  function onLoginSuccess(token) {

    csrfPost(BACKEND_URL + '/api/briefly/auth_with_google_token', {
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json'
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        token: token,
        referrerData: null
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.error === 'auth_method_not_allowed') {
          setForceOkta(true);
          return
        }

        var key = "user-refresh-token";
        localStorage.setItem(key, response.refresh);

        csrfGet(BACKEND_URL + '/api/get_user', {
          cache: 'no-cache',
          headers: {
            'Authorization': 'Bearer ' + response.access
          },
          referrerPolicy: 'no-referrer'
        })
          .then(user_response => user_response.json())
          .then(user_response => {
            const isOnboardingCompleted = !!user_response?.is_onboarding_completed;

            const user_data_key = 'current-user-data';
            localStorage.setItem(user_data_key, JSON.stringify(user_response));

            localStorage.setItem("LOGGED_IN_AT_LEAST_ONCE_KEY", '1');
            if (isOnboardingCompleted) {

              window.location.reload();
            } else {
              // window.location.href = '/onboarding'
            }
          });
      });
  }

  const login = useGoogleLogin({
    onSuccess: codeResponse => onLoginSuccess(codeResponse.access_token),
    flow: 'implicit',
    scope: "profile email"
  });


  return <Container>
    <Left>
      <BrieflyLogo />
      <Topic>
        <H2><SparklesIcon size={22} />{t('sign-in.topic.ai.title', 'AI Summary')}</H2>
        <Text>{t('sign-in.topic.ai.content', 'Every call is transcribed and summarized with AI, ensuring all key points are captured across your calls.')}</Text>
      </Topic>
      <Topic>
        <H2><CopyIcon size={22} />{t('sign-in.topic.crm.title', 'CRM Integration')}</H2>
        <Text>{t('sign-in.topic.crm.content', 'Save transcripts and summaries to HubSpot or Salesforce, keeping your CRM up-to-date without spending hours on admin work.')}</Text>
      </Topic>
      <Topic>
        <H2><CheckmarkCircleIcon size={22} />{t('sign-in.topic.checklist.title', 'Checklist')}</H2>
        <Text>{t('sign-in.topic.checklist.content', 'Run better meetings by tracking what you’ve discussed in real-time. Briefly, checklists help you keep on topic and on time.')}</Text>
      </Topic>
      <Logos>
        <Row>
          <img src={Wonderist} alt="Wonderist logo" />
          <img src={Hellofresh} alt="HelloFresh logo" />
          <img src={AppAcademy} alt="App Academy logo" />
        </Row>
        <Row>
          <img src={Voyages} alt="Voyages logo" />
          <img src={Antler} alt="Antler logo" />
          <img src={Etrainu} alt="Etrainu logo" />
        </Row>
        <Row>
          <img src={AthleticGreens} alt="Athletic Greens logo" />
          <img src={SalesforceMarketplace} alt="SalesforceMarketplace logo" />
          <img src={Harri} alt="Harri logo" />
        </Row>
      </Logos>
    </Left>
    <Right>
      {forceOkta ? (
        <Text data-i18-content="sign-in.error.okta">
          Your organization does not allow login with Google. Please sign in with <A href="https://login.okta.com">Okta</A>.
        </Text>
      ) : (
        <OutlineButton onClick={login}>
          <GoogleLogo />{t('sign-in.title', 'Sign into your account')}
        </OutlineButton>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          gap: 10,
        }}>
        <p style={{ width: '100%', textAlign: 'center', margin: 0, fontSize: 11 }}>
          {t('sign-in.terms-and-conditions.part1', 'By signing in, you agree to our')}{' '}<A href="https://www.brieflyai.com/terms-of-service" target="_blank"
            rel="noreferrer">{t('sign-in.terms-and-conditions.part2', 'Terms and Conditions')}</A>
        </p>
        <p style={{ width: '40%', textAlign: 'center', fontSize: 11, margin: 0 }}>
          {t('sign-in.data-policy.part1', 'Briefly\'s use and transfer of information received from Google APIs to any other app will adhere to')}
          <A
            target="_blank"
            href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" rel="noreferrer"> {t('sign-in.data-policy.part2', 'Google API Services User Data Policy')}</A>{t('sign-in.data-policy.part3', ', including the Limited Use requirements.')}
        </p>
        {I18N_SUPPORT && <LanguageSelector />}
        <A style={{ marginTop: "16px", fontWeight: 500 }} href="https://www.brieflyai.com/" target="_blank"
          rel="noreferrer">{t('sign-in.find-out-more', 'Find out more about Briefly')}</A>
      </div>
    </Right>
  </Container>;
}

export default UserNotLoggedInState;
