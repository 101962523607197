import React from 'react';
import { SvgSize } from './types';
// THIS HAS A LARGE BOX FOR PADDING AROUND IT
const AddIcon = (props: SvgSize) =>
  <svg width={props?.size ?? '28'} height={props?.size ?? '28'} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Plus Icon</title>
    <path
      d="M20 14C20 14.467 19.6214 14.8456 19.1544 14.8456H14.8456V19.1544C14.8456 19.6214 14.467 20 14 20C13.533 20 13.1544 19.6214 13.1544 19.1544V14.8456H8.84564C8.37861 14.8456 8 14.467 8 14C8 13.533 8.3786 13.1544 8.84564 13.1544H13.1544V8.84564C13.1544 8.37861 13.533 8 14 8C14.467 8 14.8456 8.3786 14.8456 8.84564V13.1544H19.1544C19.6214 13.1544 20 13.533 20 14Z"
      fill="currentColor" />
  </svg>;

export default AddIcon;
