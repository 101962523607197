import { useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { apiPost } from '../../utils/api';
import { ChatThreadContext } from '../../ChatThreadProvider';
import { ChatThread } from './ShowChat/types';

type Fields = 'uuid';

function NewChat(): null {
  const navigate = useNavigate();
  const { setChatThread } = useContext(ChatThreadContext)

  const handleSubmit = async (abortController: AbortController) => {
    const { chat_thread: chat } = await apiPost<Fields, ChatThread, 'chat_thread'>({
      path: '/chat-threads',
      body: {},
      signal: abortController.signal,
    });

    setChatThread(chat);
    navigate(`/chat/${chat.uuid}`)
  }

  useEffect(() => {
    const abortController = new AbortController();

    handleSubmit(abortController)

    return () => abortController.abort();
  }, []);

  return null;
}

export default NewChat;
