import React, { useState } from 'react';

import { Button } from '../../theme';

interface Props {
  initialIcon: React.JSX.Element,
  clickedIcon: React.JSX.Element,
  className?: string,
  onClick: () => void,
}

const IconWithTimeoutReplaceOnClick = ({ initialIcon, clickedIcon, onClick, className }: Props) => {

  const [isClicked, setIsClicked] = useState(false);

  if (isClicked) {
    return <Button className={className} icon >{clickedIcon}</Button>;
  } else {
    return <Button className={className} icon onClick={() => {
      setIsClicked(true);
      setTimeout(() => {
        setIsClicked(false);
      }, 1000);

      onClick();
    }}>
      {initialIcon}
    </Button>;
  }
};

export default IconWithTimeoutReplaceOnClick;