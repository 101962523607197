import React, { useState } from 'react';
import Radio from './controls/Radio';
import { sendUserFeedback } from '../utils/genericUtils';
import { useTranslation } from 'react-i18next';
import OutlineButton from './Reusable/OutlineButton';
import { Button, FlexColumn10, FlexEnd10 } from '../theme';
import { XIcon } from './Icons';

export function FeedbackComponent() {

  const { t } = useTranslation();
  const [isFeedbackPopupOpened, setIsFeedbackPopupOpened] = useState(false);
  const [isFeedbackPopupSubmitted, setIsFeebackPopupSubmitted] = useState(false);
  const [feedbackText, setFeedbackText] = useState("");
  const [isBug, setIsBug] = useState(false);


  if (!isFeedbackPopupOpened) {
    return <div id="feedback-icon-container" style={{
      borderRadius: '8px 8px 0 0',
      padding: '8px 16px',
      paddingBottom: 6,
      background: '#E44867',
      position: 'fixed',
      bottom: 0,
      right: 10,
      zIndex: 9999
    }}>
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 3.60563C9.33448 3.60563 9.60563 3.33448 9.60563 3V3C9.60563 2.66552 9.33448 2.39437 9 2.39437H3C2.66552 2.39437 2.39437 2.66552 2.39437 3V3C2.39437 3.33448 2.66552 3.60563 3 3.60563H9ZM6.60563 7.21127C6.94012 7.21127 7.21127 6.94012 7.21127 6.60563V6.60563C7.21127 6.27115 6.94012 6 6.60563 6H3C2.66552 6 2.39437 6.27115 2.39437 6.60563V6.60563C2.39437 6.94012 2.66552 7.21127 3 7.21127H6.60563ZM3 4.19718C2.66552 4.19718 2.39437 4.46833 2.39437 4.80282V4.80282C2.39437 5.1373 2.66552 5.40845 3 5.40845H9C9.33448 5.40845 9.60563 5.1373 9.60563 4.80282V4.80282C9.60563 4.46833 9.33448 4.19718 9 4.19718H3ZM10.8169 0C11.1362 0 11.4085 0.122066 11.6338 0.366197C11.8779 0.591549 12 0.86385 12 1.1831V8.39437C12 8.71362 11.8779 8.99531 11.6338 9.23944C11.4085 9.48357 11.1362 9.60563 10.8169 9.60563H2.39437L0 12V1.1831C0 0.86385 0.112676 0.591549 0.338028 0.366197C0.58216 0.122066 0.86385 0 1.1831 0H10.8169Z" fill="var(--white)" />
      </svg>
      <span style={{ position: 'relative', fontSize: 12, fontWeight: 400, color: 'var(--white)', bottom: 0, marginLeft: 8 }}>
        {t('feedback.popup.label', 'Got feedback or found a bug?')}{' '}
        <a onClick={() => setIsFeedbackPopupOpened(true)} style={{ textDecoration: 'underline', fontWeight: 600, cursor: 'pointer' }}>{t('feedback.popup.link', 'Get in touch')}</a>
      </span>
    </div>;
  }

  if (isFeedbackPopupSubmitted) {
    return <div className="transcript-info-popup-container">
      <div className="popup-background" onClick={() => { setIsFeebackPopupSubmitted(false); setIsFeedbackPopupOpened(false); }}></div>

      <div className="popup-content">
        <div className="close-icon" onClick={() => { setIsFeebackPopupSubmitted(false); setIsFeedbackPopupOpened(false); }}>
          <XIcon color="var(--brand-primary)" />
        </div>
        <h2>{t('feedback.submitted.title', 'Thank you for your feedback!')}</h2>
        <p>{t('feedback.submitted.content', 'Your message was successfully shared with the team.')}</p>
      </div>
    </div>;
  }

  return <div className="transcript-info-popup-container feedback-popup">
    <div className="popup-background" onClick={() => setIsFeedbackPopupOpened(false)}></div>

    <div className="popup-content">
      <div className="close-icon" onClick={() => {
        setIsFeedbackPopupOpened(false);
      }}>
        <XIcon color="var(--brand-primary)" />
      </div>
      <h2>{t('feedback.form.title', 'We Value Your Input!')}</h2>
      <p>{t('feedback.form.content', 'Whether you have a suggestion or encountered an issue, we\'re here to listen.')}</p>

      <FlexColumn10>
        <Radio isChecked={!isBug} setIsChecked={() => setIsBug(false)} label={t("feedback.form.options.feedback", "Send us feedback")} />
        <Radio isChecked={isBug} setIsChecked={() => setIsBug(true)} label={t("feedback.form.options.bug", "Report a bug")} />
      </FlexColumn10>

      <textarea style={{ height: 100, margin: "10px 0" }} placeholder={t("feedback.form.text-placeholder", "Tell us how we can improve or what you love...")} value={feedbackText} onChange={e => setFeedbackText(e.target.value)} />

      <FlexEnd10>
        <OutlineButton onClick={() => setIsFeedbackPopupOpened(false)}>{t('cancel', { ns: 'Actions', defaultValue: 'Cancel' })}</OutlineButton>
        <Button onClick={() => {
          sendUserFeedback(feedbackText, isBug, false, () => {
            setIsFeebackPopupSubmitted(true);
            setIsBug(false);
            setFeedbackText("");
          });
        }}>{t('submit', { ns: 'Actions', defaultValue: 'Submit' })}</Button>
      </FlexEnd10>
    </div>


  </div>;
}

export default FeedbackComponent;