import React, { useEffect, useState } from 'react';
import { setupZoom, trackEvent } from '../utils/genericUtils';
import { useUserData } from './hooks/useUserData';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { HUBSPOT_CONNECT_LINK } from "../config/config";
import { useNavigate } from "react-router-dom";
import { Button as ButtonBase, ButtonLink, HR } from '../theme';
import styled from 'styled-components';
import { CheckmarkCircleIcon } from './Icons';

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
`;

const Button = styled(ButtonBase)`
  width: 300px;
`;

function HubspotOnboardingStep1({ closePopup, goToNextStep }) {

  const [userData] = useUserData();
  const {
    subscription_is_active,
  } = userData;


  return <div>
    <h1 style={{
      marginBottom: 20,
      "fontSize": "24px",
      "fontStyle": "normal",
      "fontWeight": "900",
      "lineHeight": "normal"
    }}>
      Set up your Briefly integration for Hubspot </h1>
    <div style={{ display: 'flex' }}>
      <div style={{ paddingLeft: 25 }}>
        <h6 style={{
          margin: 0,
          "fontSize": "16px",
          "fontStyle": "normal",
          "fontWeight": "500",
          "lineHeight": "22px",
          "letterSpacing": "0.15px"
        }}>
          How does it work?
        </h6>
        <h6 style={{
          margin: 0,
          marginTop: 8,
          "fontSize": "14px",
          "fontStyle": "normal",
          "fontWeight": "700",
          "lineHeight": "20px",
          "letterSpacing": "0.15px"
        }}>
          🔗 Connect to Hubspot </h6>
        <p style={{
          margin: 0,
          marginTop: 4,
          "color": "var(--textSecondary)",
          "fontSize": "14px",
          "fontStyle": "normal",
          "fontWeight": "400",
          "lineHeight": "20px",
          "letterSpacing": "0.15px"
        }}>
          You must have access to all contacts, organizations, and deals in Hubspot. </p>
        <h6 style={{
          margin: 0,
          marginTop: 8,
          "fontSize": "14px",
          "fontStyle": "normal",
          "fontWeight": "700",
          "lineHeight": "20px",
          "letterSpacing": "0.15px"
        }}>
          🛡️ Individual connection </h6>
        <p style={{
          margin: 0,
          marginTop: 4,
          "color": "var(--textSecondary)",
          "fontSize": "14px",
          "fontStyle": "normal",
          "fontWeight": "400",
          "lineHeight": "20px",
          "letterSpacing": "0.15px"
        }}>
          Each team member must connect to Hubspot individually.
        </p>
        <h6 style={{
          margin: 0,
          marginTop: 8,
          "fontSize": "14px",
          "fontStyle": "normal",
          "fontWeight": "700",
          "lineHeight": "20px",
          "letterSpacing": "0.15px"
        }}>
          ✍️ Configure transcript uploads </h6>
        <p style={{
          margin: 0,
          marginTop: 4,
          "color": "var(--textSecondary)",
          "fontSize": "14px",
          "fontStyle": "normal",
          "fontWeight": "400",
          "lineHeight": "20px",
          "letterSpacing": "0.15px"
        }}>
          Adjust your Hubspot settings to automatically upload transcripts after each call. </p>
        <h6 style={{
          margin: 0,
          marginTop: 8,
          "fontSize": "14px",
          "fontStyle": "normal",
          "fontWeight": "700",
          "lineHeight": "20px",
          "letterSpacing": "0.15px"
        }}>
          🔓️ Call-Level Override </h6>
        <p style={{
          margin: 0,
          marginTop: 4,
          "color": "var(--textSecondary)",
          "fontSize": "14px",
          "fontStyle": "normal",
          "fontWeight": "400",
          "lineHeight": "20px",
          "letterSpacing": "0.15px"
        }}>
          Override the default setting during a call for full control on a call-by-call basis</p>
      </div>
    </div>
    {!subscription_is_active ? <div
      style={{
        background: 'var(--backgroundSecondary)',
        border: 'var(--standardBorder)',
        display: "flex",
        flexDirection: 'column',
        textAlign: 'center',
        padding: 16,
        marginTop: 32
      }}
    >
      <p
        style={
          {
            margin: 0,
            fontSize: 16,
            fontFamily: 'Source Sans Pro',
            fontWeight: '900',
            wordWrap: 'break-word'
          }
        }>SalesForce integration is a PRO feature only</p>

      <p style={{
        color: 'var(--textSecondary)',
        fontSize: 12,
        fontWeight: '400',
        wordWrap: 'break-word',
        marginTop: 16,
        marginBlock: 8
      }}>Upgrade to a PRO account to use this feature
      </p>
      <ButtonLink style={{ margin: "auto" }} to="/settings/subscriptions">
        Upgrade to PRO now
      </ButtonLink>
    </div>
      :
      <ButtonWrapper>
        <Button onClick={goToNextStep} >
          Start Hubspot setup
        </Button>
        <div style={{}} />
        <Button transparent onClick={closePopup} >
          Quit Setup
        </Button>
      </ButtonWrapper>
    }
  </div>;
}

function HubspotOnboardingStep2({ closePopup, goToNextStep }) {
  const navigate = useNavigate();
  const onConnectHubspotClick = () => {
    trackEvent('hubspot-connect-clicked', {});
    setTimeout(() => {
      window.open(HUBSPOT_CONNECT_LINK, '_black');
    }, 500);
  };

  return <div>
    <h1 style={{
      "textAlign": "left",
      "fontSize": "24px",
      "fontStyle": "normal",
      "fontWeight": "900",
      "lineHeight": "normal"
    }}>
      Set up your Briefly integration for Hubspot </h1>
    <div style={{ display: "flex", flexDirection: 'row', gap: 16 }}>
      <div>
        <p style={{
          fontSize: 16,
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '22px',
          letterSpacing: '0.15px',
          textAlign: 'left'
        }}>
          Connect Briefly to your Hubspot workspace </p>
        <p style={{
          "color": "var(--textSecondary)",
          "textAlign": "left",
          "fontSize": "14px",
          "fontStyle": "normal",
          "fontWeight": "400",
          "lineHeight": "22px",
          "letterSpacing": "0.15px"
        }}>
          You must have access to all contacts, organizations, and deals in HubSpot.
        </p>
      </div>
      <Button secondary
        onClick={onConnectHubspotClick}>
        Connect
      </Button>
    </div>

    <ButtonWrapper>
      <Button onClick={goToNextStep} >
        Confirm and complete setup
      </Button>
      <div style={{}} />
      <Button transparent onClick={closePopup} >
        Quit Hubspot setup
      </Button>
    </ButtonWrapper>
  </div>;
}

function HubspotOnboardingStep3({
  closePopup,
}) {
  const [checked, setChecked] = useState();
  return <div>
    <>
      <h1 style={{
        "textAlign": "left",
        "fontSize": "24px",
        "fontStyle": "normal",
        "fontWeight": "900",
        "lineHeight": "normal"
      }}>
        Set up your Briefly integration for Hubspot </h1>
      <div style={{ display: "flex", flexDirection: 'column', width: '100%', gap: 24 }}>
        <div style={{ display: "flex", flexDirection: 'row', gap: 24, alignItems: 'center' }}>
          <p style={{
            flex: 1,
            fontSize: 16,
            fontWeight: '500',
            letterSpacing: 0.15,
            wordWrap: 'break-word'
          }}>Briefly is connected to Hubspot
          </p>
          <div style={
            {
              display: 'flex',
              alignItems: 'center',
              gap: 6,
              fontSize: 12,
              fontWeight: 700,
              padding: '2px 6px',
              borderRadius: 2,
              margin: 0,
              height: 'fit-content',
            }
          }>
            <CheckmarkCircleIcon color='var(--green500)' />

          </div>
        </div>
        <HR />
        <div>
          <div style={{ display: 'flex' }}>
            <p style={
              {
                margin: 0,
                flex: 1,
                fontSize: 16,
                fontWeight: '500',
                letterSpacing: 0.15,
                wordWrap: 'break-word'
              }
            }>
              Add AI Summaries and Transcripts to Hubspot</p>
            <label className="switch">
              <input type="checkbox" checked={checked} onClick={e => {
                const checked = e.target.checked;
                setChecked(checked);
              }} />
              <span className="slider round"></span>
            </label>
          </div>
          <p style={{
            width: '100%',
            color: 'var(--textSecondary)',
            fontSize: 12,
            margin: 0,
            fontWeight: '400',
            letterSpacing: 0.30,
            wordWrap: 'break-word'
          }}>Automatically add your call transcripts and AI summaries to Hubspot contacts
          </p>
        </div>
      </div>
    </>
    <ButtonWrapper>
      <Button onClick={closePopup} >
        Confirm and complete setup
      </Button>
      <Button transparent onClick={closePopup}>
        Quit Hubspot setup
      </Button>
    </ButtonWrapper>
  </div>;
}

const STEPS_CONFIG = [
  {
    component: HubspotOnboardingStep1,
    label: 'Preview'
  },
  {
    component: HubspotOnboardingStep2,
    label: 'Add to Hubspot'
  },
  {
    component: HubspotOnboardingStep3,
    label: 'Confirm and complete setup'
  },
];

function HubspotOnboardingPopup({ onClose }) {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const [_, forceRenewUserData] = useUserData();

  const [botShouldJoinAllCalls, setBotShouldJoinAllCalls] = useState(false);

  const currentStep = STEPS_CONFIG[currentStepIndex];
  const CurrentStepComponent = currentStep.component;

  useEffect(() => {
    if (currentStepIndex === 4) {
      setupZoom(botShouldJoinAllCalls, () => {
        forceRenewUserData();
      });
    }
  }, [currentStepIndex]);

  return <GoogleOAuthProvider clientId="576404817524-ss93f3dua36hvaum9oi4vqrmu8anc6o3.apps.googleusercontent.com">
    <div className="transcript-info-popup-container">
      <div className="popup-background" onClick={() => onClose()}></div>
      <div className="popup-content" style={{ padding: 20 }}>
        <CurrentStepComponent botShouldJoinAllCalls={botShouldJoinAllCalls}
          setBotShouldJoinAllCalls={setBotShouldJoinAllCalls} closePopup={onClose}
          goToPrevStep={() => setCurrentStepIndex(currentStepIndex - 1)}
          goToNextStep={() => setCurrentStepIndex(currentStepIndex + 1)} />
      </div>
    </div>
  </GoogleOAuthProvider>;
};

export default HubspotOnboardingPopup;
