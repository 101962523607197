import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import { useUserData } from '../hooks/useUserData';
import { CallBubble, DoubleLeftArrowIcon } from '../Icons';
import styled, { css } from 'styled-components';
import Integrations from "./Integrations";
import UploadTranscript from "./UploadTranscript";
import SettingsDropdown from "./SettingsDropdown";
import User from "./User";
import { UserData } from "../MeetingCall/Types";
import Chat from "./Chat";

const Container = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;
  width: var(--sideNav-width);
  min-width: var(--sideNav-width);
  height: calc(100vh - var(--topNav-height));
  overflow-y: scroll;
  overflow-x: hidden;
  border-right: var(--standardBorder);

  &.collapsed {
    width: 48px;
    min-width: 48px;
  }

  &.collapsed + #detail {
    max-width: calc(100vw - 47px);
  }

  &.collapsed > button {
    transform: rotate(180deg);
  }
`;

const Hide = styled.button<{ $isCollapsed: boolean; }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  background: transparent;
  font-weight: 600;
  font-size: 12px;
  color: var(--textPrimary);
  border: none;
  border-radius: var(--standardRadius);
  width: 100%;
  margin: 0;
  padding: 10px 0 10px;

  &:hover {
    color: var(--gray900);
    text-decoration: underline;
  }

  &:focus {
    text-decoration: underline;
    ${({ $isCollapsed }) => (!$isCollapsed && css`
      outline: none;
    `)}
  }

`;

const Meetings = styled(Link) <{ $isActive?: boolean; }>`
  display: flex;
  gap: 10px;
  padding: 12px 16px;
  border-radius: var(--standardRadius);
  border: 1px solid var(--background);
  color: var(textSecondary);
  font-weight: 500;
  ${({ $isActive }) => ($isActive && css`
    border: var(--standardBorder);
    background: linear-gradient(var(--focusGradientSecondary));
    color: var(textPrimary);
  `)}
`;

const SideNav = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();


  const activeTab = useMemo(() => {
    if (pathname.startsWith("/meeting")) {
      return "MEETINGS";
    } else if (pathname.startsWith("/settings/integrations")) {
      return "INTEGRATIONS";
    } else if (pathname.startsWith("/upload-transcript")) {
      return "UPLOAD";
    } else if (pathname.startsWith("/settings")) {
      return "SUBSCRIPTIONS";
    } else if (pathname.startsWith("/chat")) {
      return "CHAT";
    }
  }, [pathname]);


  const [isPastCallsPanelCollapsed, setIsPastCallsPanelCollapsed] = useState(false);
  const [userData] = useUserData();
  const {
    administering_organization,
    features,
    profile,
  } = userData as UserData;

  return (
    <Container id="side-nav" className={isPastCallsPanelCollapsed ? 'collapsed' : ''}>
      <Hide $isCollapsed={isPastCallsPanelCollapsed}
        onClick={() => setIsPastCallsPanelCollapsed(!isPastCallsPanelCollapsed)}>
        <DoubleLeftArrowIcon />
        {isPastCallsPanelCollapsed ? '' : t('hide', { ns: 'Actions', defaultValue: 'Hide' })}
      </Hide>
      {!isPastCallsPanelCollapsed && (
        <>
          <Meetings $isActive={activeTab === "MEETINGS"} to='/meetings'>
            <CallBubble />{t('root-sidebar.calls', 'My Calls')}
          </Meetings>
          <Integrations isActive={activeTab === "INTEGRATIONS"} userData={userData} />
          <Chat isActive={activeTab === "CHAT"} />
          <UploadTranscript isActive={activeTab === "UPLOAD"} />
          <SettingsDropdown isActive={activeTab === "SUBSCRIPTIONS"} features={features} administering_organization={administering_organization} />
          <User profile={profile} />
        </>
      )}
    </Container>
  );
};

export default SideNav;
