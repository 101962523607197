import React, { ReactNode } from 'react';
import * as RadixPopover from '@radix-ui/react-popover';

import { XIcon } from '../Icons';
import { Button } from '../../theme';
import styled from 'styled-components';

const Content = styled(RadixPopover.Content)`
  background-color: var(--backgroundSecondary);
  color: var(--textPrimary);
  border-radius: var(--standardRadius);
  border: var(--standardBorder);
  padding: 15px;
  animation: fadeIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
  position: relative;
`;

const Arrow = styled(RadixPopover.Arrow)`
  fill: var(--backgroundSecondary);
  z-index: 1000;

  & > path {
    stroke: var(--borderColor);
    stroke-linejoin: round;
    stroke-width: 1px;
    transform: translateY(-1px);
  }

  & > rect {
    fill: var(--backgroundSecondary);
    transform: translateX(2px);
  }
`;

const Trigger = styled(Button)`
`;

const Close = styled(RadixPopover.Close)`
  position: absolute;
  top: 0px;
  right: 6px;
`;

interface Props extends RadixPopover.PopoverProps {
  align?: 'start' | 'center' | 'end',
  alignOffset?: number,
  anchorRef?: React.RefObject<HTMLElement>,
  asModal?: boolean,
  hasArrow?: boolean,
  side?: 'right' | 'left' | 'top' | 'bottom';
  style?: React.CSSProperties;
  trigger?: string | ReactNode;
  triggerIsIcon?: boolean;
}


const Popover = ({
  align = 'center',
  alignOffset,
  anchorRef,
  asModal,
  children,
  defaultOpen,
  hasArrow,
  onOpenChange,
  side = 'right',
  style,
  trigger,
  triggerIsIcon,
}: Props) => {
  return (
    <RadixPopover.Root defaultOpen={defaultOpen} onOpenChange={onOpenChange} modal={asModal}>
      {trigger && (
        <RadixPopover.Trigger asChild>
          <Trigger icon={triggerIsIcon}>{trigger}</Trigger>
        </RadixPopover.Trigger>
      )}
      {anchorRef && <RadixPopover.Anchor virtualRef={anchorRef} />}
      <RadixPopover.Portal>
        <Content
          align={align}
          alignOffset={alignOffset}
          arrowPadding={5}
          collisionPadding={5}
          side={side}
          sideOffset={5}
          style={style}
        >
          <Close asChild>
            <Button icon><XIcon size={8} /></Button>
          </Close>
          {hasArrow && <Arrow asChild >
            <svg
              fill="none"
              height="8"
              viewBox="0 0 24 12"
              width="18"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect height="1" width="16" />
              <path
                d="M24 1C18 1 18 11 12 11C6 11 6 0.999999 8.74228e-07 0.999999"
              />
            </svg>
          </Arrow>}
          {children}
        </Content>
      </RadixPopover.Portal>
    </RadixPopover.Root>
  );
};

export default Popover;