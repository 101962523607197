import React, { ChangeEvent, useState } from 'react';
import { Actions, Button, FormGrid, Label, Input, RadioContainer } from './style';
import { ContactOperation } from './types';
import AsyncSearchSelect, { Option } from '../forms/AsyncSearchSelect';
import { apiGet, apiPost } from '../../utils/genericUtils';
import { SalesforceContact } from '.';
import { ThreeDots } from 'react-loader-spinner';
import formEntriesToBody from '../../utils/formEntriesToBody';
import { useTranslation } from 'react-i18next';

interface Props {
  onCancel: () => void,
  onSuccess: (salesforceContact: SalesforceContact) => Promise<void>,
}

export default function CreateContactForm({ onCancel, onSuccess }: Props): JSX.Element {
  const [contactOperation, setContactOperation] = useState<ContactOperation>('existing');
  const [account, setAccount] = useState<Option | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const handleContactOperationChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.currentTarget.checked) return;

    if (e.currentTarget.value === 'new') {
      setContactOperation('new');
    }

    if (e.currentTarget.value === 'existing') {
      setContactOperation('existing');
    }
  };

  const handleAccountSearch = async (value: string) => {
    return apiGet({ path: `/briefly/search-salesforce-accounts?q=${value}` });
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const body = formEntriesToBody(new FormData(e.currentTarget));
      const response: SalesforceContact = await apiPost({ path: '/services/salesforce/Contact', body });
      await onSuccess(response);
    } finally {
      setIsLoading(false);
    }
  };

  return <FormGrid onSubmit={handleSubmit}>
    <Label>
      <span>{t('meeting.salesforce.contact-form.create.first-name', 'First name')}</span>
      <Input name="contact.firstName" required />
    </Label>
    <Label>
      <span>{t('meeting.salesforce.contact-form.create.last-name', 'Last name')}</span>
      <Input name="contact.lastName" required />
    </Label>
    <Label $fullWidth>
      <span>{t('meeting.salesforce.contact-form.create.email', 'Email address')}</span>
      <Input name="contact.email" type="email" required />
    </Label>
    <Label>
      {t('meeting.salesforce.contact-form.create.account.title', 'Set an account for this contact')}
    </Label>
    <RadioContainer>
      <label>
        <input name="operation" type="radio" checked={contactOperation === 'existing'} onChange={handleContactOperationChange} value="existing" /> {t('meeting.salesforce.contact-form.create.account.existing', 'Existing account')}
      </label>
      <label>
        <input name="operation" type="radio" checked={contactOperation === 'new'} onChange={handleContactOperationChange} value="new" /> {t('meeting.salesforce.contact-form.create.account.new', 'New account')}
      </label>
    </RadioContainer>
    {contactOperation === 'existing' && (
      <>
        <Label $fullWidth>
          <AsyncSearchSelect onSearch={handleAccountSearch} onChange={setAccount} placeholder={t("meeting.salesforce.contact-form.create.account.search", "Search for an account")} />
        </Label>
        {account && (
          <input type="hidden" value={account.value} name="contact.accountId" />
        )}
      </>
    )}
    {contactOperation === 'new' && (
      <Label $fullWidth>
        <Input placeholder="Account name" name="account.name" required />
      </Label>
    )}
    <Actions>
      <Button onClick={onCancel} secondary inline small>Cancel</Button>
      <Button inline small disabled={isLoading || (contactOperation === 'existing' && !account?.value)}>
        {isLoading ? <ThreeDots
          height="5"
          width="40"
          radius="9"
          color="white"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          visible={true}
        /> : t('meeting.salesforce.contact-form.create.account.confirm', 'Confirm and add to Salesforce')}
      </Button>
    </Actions>
  </FormGrid>;
}

