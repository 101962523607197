import React, { useEffect, useMemo, useState } from "react";
import { ThreeDots } from 'react-loader-spinner';
import { useTranslation } from "react-i18next";
import { useOutletContext, useSearchParams } from "react-router-dom";
import { useDebounce } from 'use-debounce';

import {
  mapObject, groupBy, getUploadedTranscriptsRequest, getTranscriptionAsText, getContextStrings, getContextStringsFromList, mergeObjectsByMeetingId, countWordSetMatches,
} from '../../utils/genericUtils';
import { useUserData } from '../hooks/useUserData';
import { SearchIcon as SIcon, XCircleIcon } from '../Icons';
import { MeetingData, SearchMeetingData } from "../MeetingCall/Types";
import { Outlet } from "../../types/Outlet";
import Call from "./Call";

import styled from "styled-components";
import Select from "../forms/Select";

const Container = styled.main`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SearchBar = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Search = styled.input`
  background: var(--white);
  border-radius: var(--standardRadius);
  padding: 8px 28px;
  width: 312px;
  border: var(--standardBorder);

  &:focus-visible {
    outline: 1px solid var(--brand-secondary);
  }

  &::-webkit-search-cancel-button{
  display: none;
  }
`;

const SearchIcon = styled(SIcon)`
  position: absolute;
  top: 13px;
  left: 12px;
`;

const CancelSearch = styled.button`
  outline: none;
  background: transparent;
  border: none;
  color: var(--gray600);
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: var(--gray800);
  }
`;

const Calls = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;


const CallList = () => {
  const { meetingDataById: initialMeetingDataById } = useOutletContext() as Outlet;
  const { t } = useTranslation();

  const [URLSearchParams, setURLSearchParams] = useSearchParams();
  const searchQueryParam = URLSearchParams.get('search');
  const [searchQuery, setSearchQuery] = useState(searchQueryParam || "");
  const [searchQueryDebounce] = useDebounce(searchQuery, 1000);
  const [searchResults, setSearchResults] = useState(null);


  const SORTING_NEWEST_KEY = 'newest';
  const SORTING_OLDEST_KEY = 'oldest';
  const SORTING_BEST_MATCH_KEY = 'best_match';

  const SORTING_CONFIG = [
    {
      key: SORTING_NEWEST_KEY,
      label: t('root-sidebar.search-dropdown.newest', 'Newest'),
      // @ts-expect-error: use date for sort
      apply: (results: MeetingData) => Object.entries(results).sort((a, b) => new Date(b[0]) - new Date(a[0]))
    }, {
      key: SORTING_OLDEST_KEY,
      label: t('root-sidebar.search-dropdown.oldest', 'Oldest'),
      // @ts-expect-error: use date for sort
      apply: (results: MeetingData) => Object.entries(results).sort((a, b) => -(new Date(b[0]) - new Date(a[0])))
    }, {
      key: SORTING_BEST_MATCH_KEY,
      label: t('root-sidebar.search-dropdown.best-match', 'Best Match'),
      apply: (results: MeetingData, search: string) => {
        const res = [];
        let i = 0;
        for (const date in results) {
          for (const result of results[date]) {
            // @ts-expect-error: todo
            res.push([
              i++,
              [result]
            ]);
          }
        }

        const priority = (meeting: SearchMeetingData) => {
          const { meetingName } = meeting;
          let { transcriptMatchesCount, summaryMatchesCount, outputMatchesCount } = meeting;

          transcriptMatchesCount = transcriptMatchesCount || 0;
          summaryMatchesCount = summaryMatchesCount || 0;
          outputMatchesCount = outputMatchesCount || 0;

          const totalMatchGroups = [transcriptMatchesCount, summaryMatchesCount, outputMatchesCount].filter(x => x > 0).length;
          const totalMatches = [transcriptMatchesCount, summaryMatchesCount, outputMatchesCount].reduce((x, y) => x + y, 0);
          const isMeetingNameMatch = getContextStrings(meetingName, search.split('/s+/'), 1).length > 0;
          const isFullMeetingNameMatch = countWordSetMatches(meetingName, search.split('/s+/')) > 0;

          const GROUPS_CNT_WEIGHT = 5;
          const TOTAL_MATCHES_WEIGHT = 1;
          const MEETING_NAME_WEIGHT = 4;
          const MEETING_NAME_FULL_MATCH_WEIGHT = 4;

          // @ts-expect-error: TODO: not sure why they were adding a boolean to these weights. 🤷🏻‍♀️
          return totalMatchGroups * GROUPS_CNT_WEIGHT + TOTAL_MATCHES_WEIGHT * totalMatches + isMeetingNameMatch * MEETING_NAME_WEIGHT + isFullMeetingNameMatch * MEETING_NAME_FULL_MATCH_WEIGHT;

        };

        return res.sort((a, b) => priority(b[1][0]) - priority(a[1][0]));
      }
    }];

  const [currentSorting, setCurrentSorting] = useState(SORTING_CONFIG[0]);

  const [meetingDataById, setMeetingDataById] = useState(initialMeetingDataById);
  const [removedMeetingIds, setRemovedMeetingIds] = useState<string[]>([""]);

  const [userData] = useUserData();



  const chronologicalMeetingDataByDay = useMemo<Record<string, [MeetingData]>>(() => {
    return mapObject(groupBy(Object.values(meetingDataById).filter(meeting => meeting.meetingStartDate), (meeting: { meetingStartDate: string; }) => meeting.meetingStartDate.split('T')[0]), lst => lst.sort(function (a, b) {
      // @ts-expect-error: sorting by date
      return new Date(b.meetingStartDate) - new Date(a.meetingStartDate);
    }));
  }, [meetingDataById]);

  useEffect(() => {
    if (!searchQuery) {
      setCurrentSorting(SORTING_CONFIG[0]);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (meetingDataById?.['demo-meeting'] && userData?.features?.show_demo_meeting === false) {
      const { ...otherMeetings } = meetingDataById;
      delete otherMeetings['demo-meeting'];

      setMeetingDataById(otherMeetings);
    }
  }, [userData?.features?.show_demo_meeting, meetingDataById]);

  useEffect(() => {

    async function f() {

      if (!searchQueryDebounce) {
        setURLSearchParams("");
        return;
      }
      setURLSearchParams(`search=${searchQueryDebounce}`);

      const words = searchQueryDebounce.split(' ').map(w => w.toLowerCase());
      const localItemsThatMatch = Object.values(meetingDataById).filter(x => !x.isBackend).filter(meeting => {
        return !words.map(w => {
          if (!meeting.transcriptionBlocks) {
            return false;
          }
          return !!meeting.meetingName.toLowerCase().startsWith(w) || !!meeting.transcriptionBlocks.find(b => b.text.toLowerCase().startsWith(w));
        }).includes(false);

      }).map(meeting => {
        return {
          ...meeting,
          transcriptContextStrings: getContextStrings(getTranscriptionAsText(meeting), words, 5),
          transcriptMatchesCount: countWordSetMatches(getTranscriptionAsText(meeting), words)
        };
      });

      const backendItemsThatMatch = (await getUploadedTranscriptsRequest(searchQueryDebounce)).transcripts.map(x => {
        return {
          ...meetingDataById?.['bkend-' + x.transcriptId],
          ...x
        };
      }).map(meeting => ({
        ...meeting,
        transcriptContextStrings: meeting.search_context_strings,
        transcriptMatchesCount: meeting.search_matches_count
      }));

      const promptsSummariesItemsThatMatch = Object.values(meetingDataById).map(meeting => {
        // @ts-expect-error: let null pass through
        const prompts = JSON.parse(localStorage.getItem(meeting.meetingId + '-customised-prompts')) || [];
        // @ts-expect-error: let null pass through
        const summary = JSON.parse(localStorage.getItem('summary-cache-' + meeting.meetingId));
        const outputContextStrings = prompts ? getContextStringsFromList(prompts.map(x => x.output), words, 5) : [];

        const summaryItemsList = summary ? [
          summary.short_summary,
          summary.long_summary,
          summary.key_insights,
          summary.action_items
        ] : [];

        const summaryContextStrings = summary ? getContextStringsFromList(summaryItemsList, words, 5) : [];

        const outputMatchesCount = prompts ? countWordSetMatches(prompts.map(x => x.output).join(' '), words) : 0;
        const summaryMatchesCount = prompts ? countWordSetMatches(summaryItemsList.join(' '), words) : 0;

        return {
          ...meeting,
          outputContextStrings,
          summaryContextStrings,
          outputMatchesCount,
          summaryMatchesCount
        };
      }).filter(x => x.outputMatchesCount > 0 || x.summaryMatchesCount > 0);

      setSearchResults(
        mapObject(
          groupBy(
            mergeObjectsByMeetingId(localItemsThatMatch.concat(backendItemsThatMatch).concat
              // @ts-expect-error: todo
              // eslint-disable-next-line no-unexpected-multiline
              (promptsSummariesItemsThatMatch)),
            meeting => meeting.meetingStartDate.split('T')[0]
          ),
          lst => lst.sort(function (a, b) {
            // @ts-expect-error: use date for sort
            return new Date(b.meetingStartDate) - new Date(a.meetingStartDate);
          })
        ));

    }

    f();
  }, [searchQueryDebounce]);

  return (
    <Container>
      <SearchBar name="search" role="search" style={{ position: "relative" }}>
        <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
          <Search type="search"
            placeholder={t("root-sidebar.search.placeholder", "Search calls, transcripts and summaries")} value={searchQuery} onChange={e => {
              setSearchResults(null);
              setSearchQuery(e.target.value);
            }} />
          <SearchIcon />
          <CancelSearch onClick={() => {
            setSearchResults(null);
            setSearchQuery('');
          }} name="cancel search" tabIndex={!searchQuery ? -1 : 0}>
            <XCircleIcon
              size={14}
              style={!searchQuery ? { display: 'none' } : {}}
            />
          </CancelSearch>
        </div>
        {/* sorting doesn't work unless filtered via search */}
        {searchQuery && <Select size='small' name="sort search" options={SORTING_CONFIG} defaultValue={currentSorting}
          // @ts-expect-error options
          onChange={item => setCurrentSorting(item)} />}
      </SearchBar>
      {/* @ts-expect-error: TODO: sorting doesn't work unless filtered */}
      {!searchQuery && Object.entries(chronologicalMeetingDataByDay).sort((a, b) => new Date(b[0]) - new Date(a[0])).map(entry => {
        const date = entry[0];
        const meetings = entry[1];
        if (!meetings.find(m => !removedMeetingIds.includes(m.meetingId))) {
          return '';
        }
        return <Calls key={date}>
          {meetings.filter(m => m.meetingId == 'demo-meeting' || (!removedMeetingIds.includes(m.meetingId) && m.isBackend)).map(m => {
            return (
              <Call key={m.meetingId} m={m} setRemovedMeetingIds={setRemovedMeetingIds} removedMeetingIds={removedMeetingIds} />
            );
          })}
        </Calls>;
      })}
      {searchResults && currentSorting.apply(searchResults, searchQueryDebounce).map(entry => {
        const date = entry[0];
        const meetings = entry[1];
        if (!meetings.find(m => !removedMeetingIds.includes(m.meetingId))) {
          return '';
        }

        return <Calls className="search-result" key={date}>
          {meetings.filter(m => !removedMeetingIds.includes(m.meetingId)).map(m => {
            return <Call key={m.meetingId} m={m} setRemovedMeetingIds={setRemovedMeetingIds} searchQuery={searchQuery} removedMeetingIds={removedMeetingIds} />;
          })}
        </Calls>;
      })}
      {searchQuery && !searchResults && <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
        <ThreeDots
          height="35"
          width="35"
          radius="9"
          color="var(--brand-primary)"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          visible={true}
        />
      </div>}
    </Container >

  );
};

export default CallList;;