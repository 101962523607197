import React, { useRef, useEffect, FormEventHandler, useState } from 'react';
import { Panels, SparklesIcon } from '../../Icons';
import Markdown from 'react-markdown';
import { ThreeDots } from 'react-loader-spinner';
import { Button } from '../../../theme';
import { ChatLog, Bubble, ChatControl, ChatInput, ChatInputWrapper, ChatLogContainer, ChatScroll, EmptyState, Form, SparkleContainer, SubmitButton, CopyChatButton } from './styles';
import { Message } from './types';
import MeetingFilter from '../../Reusable/MeetingFilter';
import remarkGfm from 'remark-gfm'
import remarkBreaks from 'remark-breaks'
import Dialog from '../../Reusable/Dialog';
import formEntriesToBody from '../../../utils/formEntriesToBody';
import { useTranslation } from 'react-i18next';
import { Meeting } from '../../Reusable/MeetingFilter/types';

interface Props {
  messages: Message[],
  isLoading: boolean,
  loadingMessage: string,
  transcripts: Meeting[],
  updateChatThreads: (transcriptIds: number[]) => Promise<void>,
  onSubmit: (body: { message: string }) => Promise<void>,
  isEditingMeetings: boolean,
  setIsEditingMeetings: (_: boolean) => void,
}

function ShowChatChat({
  messages,
  isLoading,
  loadingMessage,
  onSubmit,
  updateChatThreads,
  transcripts,
  isEditingMeetings,
  setIsEditingMeetings,
}: Props) {
  const { t } = useTranslation()
  const scrollAnchorRef = useRef<HTMLSpanElement>(null);
  const [message, setMessage] = useState<string>('');
  const scrollMessages = () => {
    if (scrollAnchorRef.current != null) {
      scrollAnchorRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }

  useEffect(() => {
    scrollMessages()
  }, [messages.length, loadingMessage]);

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()

    const form = e.currentTarget;
    const data = formEntriesToBody(new FormData(form));

    if (typeof data.message !== 'string') {
      return
    }

    const body = { message: data.message };

    form.reset();
    setMessage('');
    await onSubmit(body);
  }

  const handleMeetingsUpdate = async (transcriptIds: number[]) => {
    await updateChatThreads(transcriptIds);

    setIsEditingMeetings(false);
  }

  const listenToTextAreaHeight = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.currentTarget.value);
  }

  const handleTextAreaEnter = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const assistKeysPressed = e.ctrlKey || e.shiftKey || e.altKey;
    if(e.key === 'Enter' && !assistKeysPressed && e.currentTarget.value.trim() !== '') {
      e.preventDefault();
      e.currentTarget.form?.dispatchEvent(new Event('submit', { bubbles: true }));
      return;
    }
  }

  return (
    <ChatLogContainer>
      <ChatScroll>
        <ChatLog>
          {!isLoading && messages.length === 0 && (
            <EmptyState>{t('chat-log.empty-state-notice', 'No Messages')}</EmptyState>
          )}
          {messages.map((message, index) => {
            const startOfAssistant = message.role === 'assistant' && messages[index - 1]?.role != 'assistant';
            const flattenedTextContent = message.content.map((c) => c.text.value).join();
            const filesChanged = message.metadata.messageType === 'fileChangeNote';
            return <Bubble key={message.id} className={message.role} $messageType={message.metadata.messageType}>
              {message.role === 'assistant' && !filesChanged && <CopyChatButton text={flattenedTextContent} size={16} />}
              {startOfAssistant && <SparkleContainer><SparklesIcon /></SparkleContainer>}
              <div>
                <Markdown remarkPlugins={[remarkGfm, remarkBreaks]}>
                  {flattenedTextContent}
                </Markdown>
              </div>
            </Bubble>
          })}
          {loadingMessage && (
            <Bubble className='assistant'><Markdown remarkPlugins={[remarkGfm]}>{loadingMessage}</Markdown></Bubble>
          )}
          {isLoading && !loadingMessage && <ThreeDots color='var(--primary200)' width={40} />}
          <span ref={scrollAnchorRef}>&nbsp;</span>
        </ChatLog >
      </ChatScroll>
      <ChatControl>
        <Dialog
          trigger={<Button forwardedAs="span" inline small outline>
            <Panels size={24} />
          </Button>}
          open={isEditingMeetings}
          onOpenChange={setIsEditingMeetings}
          maxWidth="800px"
          title={t('meeting-filter.title', 'Your Meetings')}
          titleStyle={{ fontSize: '18px', marginTop: 0 }}
          hideClose
        >
          <MeetingFilter
            initialSelectedMeetingIds={transcripts.map(({ id }) => id) || []}
            onConfirm={handleMeetingsUpdate}
            onClose={() => setIsEditingMeetings(false)}
          />
        </Dialog>
        <Form onSubmit={handleSubmit}>
          <ChatInputWrapper data-textarea-content={message}>
            <ChatInput rows={1} onKeyUp={handleTextAreaEnter} onChange={listenToTextAreaHeight} name="message" placeholder={t('chat-log.input-placeholder', "Message BrieflyAI")} />
          </ChatInputWrapper>
          <SubmitButton disabled={isLoading} small>
            {isLoading ?
              <ThreeDots width={20} height={20} color='white' /> : t('chat-log.submit-button', 'Submit')}
          </SubmitButton>
        </Form>
      </ChatControl>
    </ChatLogContainer >
  )
}

export default ShowChatChat;
