import React from 'react';
import { copyToClipboard } from "../../utils/genericUtils";
import IconWithTimeoutReplaceOnClick from './IconWithTimeoutReplaceOnClick';
import CopyIcon from '../Icons/Copy';
import CheckmarkCircleIcon from '../Icons/CheckmarkCircle';

interface Props {
  id?: string,
  size?: number | string,
  style?: React.CSSProperties,
  className?: string,
  text: string,
}

export default function CopyToClipboard({ id, text, size = 14, style, className }: Props): JSX.Element {
  return <IconWithTimeoutReplaceOnClick
    className={className}
    onClick={() => copyToClipboard(text)}
    initialIcon={<CopyIcon size={size} data-tooltip-id={id} color="#E44867" style={style} />}
    clickedIcon={<CheckmarkCircleIcon data-tooltip-id={id} color="#E44867" size={size} style={style} />}
  />;
}
