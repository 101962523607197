import { useTranslation } from 'react-i18next';
import CalendarSvg from '../../images/CalendarSvg';
import GoToExtension from '../../images/GoToExtension';
import { ButtonLink } from '../../theme';

export function NoCallsYet() {
  const { t } = useTranslation();

  return <div className="edge-case-state" style={{ position: 'relative' }}>
    <CalendarSvg />
    <h3 style={{ marginBottom: 10 }}>{t('empty-calls.main.title', 'Start a call with Briefly ...')}</h3>
    <span style={{ width: 310, textAlign: 'center' }}>
      {t('empty-calls.main.description', 'You haven’t made any call yet. Your dashboard will be available here after your first call.')}
    </span>

    <ButtonLink to="https://meet.google.com/" target="_blank" rel="noreferrer">
      {t('empty-calls.google-meet.open', 'Open Google Meet')}
    </ButtonLink>

    <div className="go-to-extension">
      <GoToExtension />
      <h3>{t('empty-calls.extension.title', 'Open Briefly extension')}</h3>
      <span>
        {t('empty-calls.extension.description', 'Pin the Briefly extension to easily access your past calls at any time.')}
      </span>
    </div>
  </div>;
}

export default NoCallsYet;