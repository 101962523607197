import styled, { css } from "styled-components";
import { Button } from "../../../theme";

export const LoadingAnimation = styled.div`
  margin: auto;
  width: 30px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 640px;
`;

export const MeetingList = styled.div`
  padding: 0 2px;
  display: flex;
  flex-direction: column;
  min-height: 400px;
  max-height: 400px;
  overflow-y: scroll;
  gap: 10px;
`;
export const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const DateText = styled.span`
  font-weight: bold;
  font-size: 14px;
  color: var(--gray800);
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const GroupRow = styled(Row)`
  padding: 4px 0;
  position: sticky;
  top: 0px;
  background-color: var(--white);
`;

export const Centered = styled.div`
  margin: auto;
`;

export const SearchRow = styled(Row)`
  justify-content: flex-end;
  gap: 11px;
  font-size: 12px;

  & > *:first-child {
    max-width: 300px;
    margin-right: auto;
    flex-grow: 1;
  }

  .search__control {
    border-radius: 15px;
    min-height: 30px;
  }

  .search__control > svg {
    color: var(--gray500);
    margin-left: 5px;
  }

  .search__indicators {
    display: none;
  }

  .select__control {
    min-width: 125px;
    min-height: 30px;
  }

  .select__control > svg {
    color: var(--gray500);
    margin-left: 5px;
  }

  .select__clear-indicator {
    padding: 2px;
  }

  .select__dropdown-indicator {
    padding: 2px;
  }
`;

export const Column = styled.div<{ $center?: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ $center }) => $center && css`align-items: center;`}
`;

export const RowContainer = styled(Row) <{ $active: boolean }>`
  border: 1px solid #E7E7E7;
  padding: 8px;
  border-radius: 6px;
  font-size: 11px;
  color: var(--gray500);
  cursor: pointer;

  ${({ $active }) => $active && css`
    outline: 2px solid var(--primary600);
  `}
  ${[2, 9, 44, 25, 20].map((percent, i) => css`
    & > *:nth-child(${i + 1}) {
      width: ${percent}%;
    }
  `)}

  & > *:nth-child(2) {
    border-right: 1px solid #E7E7E7;
    margin-right: 8px;
  }
`;

export const MeetingTitle = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: bold;
  color: var(--gray800);
`;

export const LinkButton = styled(Button)`
  background: none;
  border: none;
  color: var(--gray600);
  font-weight: normal;
  margin-left: auto;
`
