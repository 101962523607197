import React, { useState } from 'react';

import * as Dialog from '@radix-ui/react-dialog';
import { ThreeDots } from 'react-loader-spinner';

import { useDataFromEndpoint } from "../../hooks/useDataFromEndpoint";

import style from "./style";
import { apiPatch } from "../../../utils/genericUtils";
import FieldMappingContent from "./FieldMappingContent";
import ServiceContactForm, { SalesforceContact } from '../../ServiceContactForm';
import { Button, colors } from '../../../theme';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const HelpHeader = styled.h4`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: ${colors.gray200};
`;

interface Props {
  backendMeetingData: { id: number; },
}

interface FieldMapping {
  id: number;
}

interface Data {
  workflowRun: {
    id: number;
    objectName: string;
    objectType: string;
    fieldMappings: FieldMapping[];
  },
}

type DataFromEndpointResponse = [Data, boolean, Error, () => Promise<void>];

const NullWorkflowRun = {
  id: 0,
  objectName: undefined,
  objectType: undefined,
  fieldMappings: {},
};

const MeetingTabSalesforce = ({ backendMeetingData: { id: transcriptId } }: Props) => {
  const [data, isLoading, _, fetchWorkflowRun]: DataFromEndpointResponse = useDataFromEndpoint(
    `/api/transcripts/${transcriptId}/workflow_run`
  );
  const [openId, setOpenId] = useState(null);
  const [isContactDialogOpen, setIsContactDialogOpen] = useState(false);
  const { t } = useTranslation();

  const {
    workflowRun: { id, objectName, objectType, fieldMappings } = NullWorkflowRun,
  } = data || {};

  const handleOpenContactDialog: React.MouseEventHandler<HTMLButtonElement> = () => {
    setIsContactDialogOpen((bool) => !bool);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>, fieldMappingId: number) => {
    e.preventDefault();
    const body = Object.fromEntries(new FormData(e.currentTarget));

    await apiPatch({
      path: `/workflow_runs/${id}`,
      body: {
        field_mapping_values: { [fieldMappingId]: body },
      },
    });

    fetchWorkflowRun();
  };

  const handleContactChange = async (salesforceContact: SalesforceContact) => {
    await apiPatch({
      path: `/workflow_runs/${id}`,
      body: {
        object_id: salesforceContact.Account.Id,
        object_name: salesforceContact.Account.Name,
        contact_ids: [salesforceContact.Id],
      },
    });
    setIsContactDialogOpen(false);
    fetchWorkflowRun();
  };

  const hasContact = objectName != null;

  return (
    <div style={style.container}>
      <div style={style.header}>
        <span>{objectType}</span>
        <span style={style.headerRight}>
          {hasContact ? (
            <span>{objectName}</span>
          ) : (
            <span>{t('meeting.salesforce.no-contact.title', 'Briefly couldn’t detect a contact for this call')}</span>
          )}
          {/* TODO: Use Dialog component */}
          <Dialog.Root open={isContactDialogOpen} onOpenChange={setIsContactDialogOpen}>
            <Dialog.Trigger style={style.headerRightLink} onClick={handleOpenContactDialog}>{hasContact ? t('change', { ns: 'Actions', defaultValue: 'Change' }) : t('update', { ns: 'Actions', defaultValue: 'Update' })}</Dialog.Trigger>
            <Dialog.Portal>
              <Dialog.Overlay style={style.dialogOverlay}>
                <Dialog.Content style={style.dialogBox} onInteractOutside={(e) => e.preventDefault()}>
                  <ServiceContactForm onCancel={() => setIsContactDialogOpen(false)} onSuccess={handleContactChange} />
                </Dialog.Content>
              </Dialog.Overlay>
            </Dialog.Portal>
          </Dialog.Root>
        </span>
      </div>
      {hasContact && (
        <div style={style.itemList}>
          {fieldMappings.map((fieldMapping) => (
            <FieldMappingContent
              key={`field-mapping-${fieldMapping.id}`}
              fieldMapping={fieldMapping}
              isOpen={fieldMapping.id === openId}
              onSubmit={handleSubmit}
              onToggle={setOpenId}
            />
          ))}
        </div>
      )}
      {!isLoading && !hasContact &&
        <HelpHeader>
          {t('meeting.salesforce.no-contact.title', 'Briefly couldn’t detect a contact for this call')}
          <Button inline link onClick={handleOpenContactDialog}>{t('update', { ns: 'Actions', defaultValue: 'Update' })}</Button>
        </HelpHeader>}
      {isLoading && <HelpHeader><ThreeDots
        height="10"
        width="80"
        radius="9"
        color="white"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        visible={true}
      /></HelpHeader>}
    </div>
  );
};

export default MeetingTabSalesforce;
