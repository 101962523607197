import React, { useState, useCallback, useEffect, useMemo, useContext } from 'react';
import { apiPostStream, apiGet, apiPatch } from '../../../utils/api';
import { useParams } from 'react-router-dom';
import { Title } from '../../../theme';
import { attendeesWithColor } from '../../../utils/genericUtils'
import Attendees from '../../Reusable/Attendees';
import { Container, Header, TitleHelper, SubTitle } from './styles';
import { ChatThread, Message, Sources, ThreadMessageDelta, ThreadMessageCompleted, UpdateFields } from './types';
import { cleanMessage, formatDateRange } from './utils';
import Chat from './Chat';
import { ChatThreadContext } from '../../../ChatThreadProvider';
import { useTranslation } from 'react-i18next';
import { Meeting } from '../../Reusable/MeetingFilter/types';

function ShowChat(): JSX.Element {
  const { t } = useTranslation();
  const [isEditingMeetings, setIsEditingMeetings] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [loadingMessage, setLoadingMessage] = useState('')
  const [messages, setMessages] = useState<Message[]>([])
  const { chat_uuid: chatId } = useParams()
  const { loadChatThread, chatThreads, transcriptsById, setChatThread } = useContext(ChatThreadContext);

  const chatThread = chatThreads.find((chatThread) => chatThread.uuid === chatId);
  const transcripts: Meeting[] = (chatId ? transcriptsById[chatId] : []) || [];

  const getMessages = useCallback(async () => {
    setIsLoading(true)
    const { messages, sources } = await apiGet<Message[] | Sources, 'messages' | 'sources'>({ path: `/chat-threads/${chatId}/messages` }) as { messages: Message[], sources: Sources };

    if (messages.length === 0) {
      setIsEditingMeetings(true);
    }

    const customMessages = messages.map((m) => cleanMessage(m, sources)).reverse();

    setMessages(customMessages);
    setIsLoading(false);
  }, [chatId]);

  useEffect(() => {
    if (chatId) {
      const abortController = new AbortController();
      loadChatThread({ chatId, signal: abortController.signal });

      return () => abortController.abort();
    }
  }, [chatId]);

  useEffect(() => {
    setMessages([]);
    getMessages();
  }, [chatId]);

  const updateChatThreads = async (transcriptIds: number[]): Promise<void> => {
    const { chat_thread } = await apiPatch<UpdateFields, ChatThread, 'chat_thread'>({
      path: `/chat-threads/${chatThread!.uuid}`,
      body: { transcript_ids: transcriptIds },
    });
    setChatThread(chat_thread);
    getMessages()
    loadChatThread({ chatId: chatThread!.uuid });
  }

  const handleSubmit = async (body: { message: string }): Promise<void> => {
    setIsLoading(true)
    setLoadingMessage('');

    setIsLoading(true)
    const message: Message = {
      id: body.message,
      content: [{ text: { value: body.message, annotations: [] } }],
      role: "user",
      metadata: {},
    };

    setMessages((messages) => [...messages, message]);

    await apiPostStream<{ messages: ThreadMessageDelta | ThreadMessageCompleted, sources: Sources }>({ path: `/chat-threads/${chatId}/message`, body }, (event) => {
      const { messages: { data }, sources } = event;
      if ('delta' in data) {
        const text = cleanMessage(data.delta, sources).content.map((c) => c.text.value).join();
        return setLoadingMessage((message) => message + text);
      }
      setIsLoading(false);
      setLoadingMessage('');
      setMessages((m) => [...m, cleanMessage(data, sources)]);
    }).finally(() => {
      setIsLoading(false);
    });

    if (chatThread?.title == 'Untitled' && messages.filter(({ role }) => role === 'user').length > 2) {
      const { chat_thread } = await apiPatch<UpdateFields, ChatThread, 'chat_thread'>({
        path: `/chat-threads/${chatThread!.uuid}`,
        body: { generate_title: true },
      });

      setChatThread(chat_thread);
    }
  }

  const attendeesByColor = useMemo(() => {
    const allAttendees = [...new Set(
      transcripts.flatMap((t) => t.meeting_participants).filter((a) => !!a)
    )];

    return attendeesWithColor(allAttendees);
  }, [transcripts.length])

  const dateRange = useMemo(() => {
    if (!chatThread) return '';

    return formatDateRange(transcripts);
  }, [transcripts.length])

  return <Container>
    <Header>
      <div>
        <Title>
          {t('show-chat.title', 'Meetings')} <TitleHelper $primary>{transcripts.length}</TitleHelper>
        </Title>
        <SubTitle>{dateRange}</SubTitle>
      </div>
      <div>
        <TitleHelper>{t('show-chat.users-title', 'Users')}</TitleHelper>
        <Attendees attendeesByColor={attendeesByColor} />
      </div>
    </Header>
    {chatThread && (
      <Chat
        isLoading={isLoading}
        messages={messages}
        loadingMessage={loadingMessage}
        updateChatThreads={updateChatThreads}
        onSubmit={handleSubmit}
        transcripts={transcripts}
        isEditingMeetings={isEditingMeetings}
        setIsEditingMeetings={setIsEditingMeetings}
      />
    )}
  </Container>;
}

export default ShowChat;

