import React from 'react';
import { SvgSize } from './types';

const SettingsIcon = (props: SvgSize) => (
  <svg width={props?.size ?? '16'} height={props?.size ?? '16'} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Settings Icon</title>
    <path fillRule="evenodd" clipRule="evenodd" d="M9.73244 2C9.38663 1.4022 8.74028 1 8 1C7.25972 1 6.61337 1.4022 6.26756 2H1C0.447715 2 0 2.44772 0 3C0 3.55228 0.447715 4 1 4H6.26756C6.61337 4.5978 7.25972 5 8 5C8.74028 5 9.38663 4.5978 9.73244 4H15C15.5523 4 16 3.55228 16 3C16 2.44772 15.5523 2 15 2H9.73244ZM1 7C0.447715 7 0 7.44772 0 8C0 8.55228 0.447715 9 1 9H2.26756C2.61337 9.5978 3.25972 10 4 10C4.74028 10 5.38663 9.5978 5.73244 9H15C15.5523 9 16 8.55228 16 8C16 7.44772 15.5523 7 15 7H5.73244C5.38663 6.4022 4.74028 6 4 6C3.25972 6 2.61337 6.4022 2.26756 7H1ZM0 13C0 12.4477 0.447715 12 1 12H11.2676C11.6134 11.4022 12.2597 11 13 11C13.7403 11 14.3866 11.4022 14.7324 12H15C15.5523 12 16 12.4477 16 13C16 13.5523 15.5523 14 15 14H14.7324C14.3866 14.5978 13.7403 15 13 15C12.2597 15 11.6134 14.5978 11.2676 14H1C0.447715 14 0 13.5523 0 13Z" fill="currentColor" />
  </svg>
);

export default SettingsIcon;
