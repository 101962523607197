import { useAvailableLanguages } from '../hooks/useAvailableLanguages';
import { useTranslation } from 'react-i18next';
import { ThreeDots } from 'react-loader-spinner';

import { notifyExtensionLangChanged } from '../../utils/extension';
import Select from '../forms/Select';
import { BodyLight } from '../../theme';


const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const { result, status } = useAvailableLanguages();

  const onLanguageSelected = (language: string) => {
    i18n.changeLanguage(language, () => {
      notifyExtensionLangChanged(language);
    });
  };

  if (status === "IDLE" || status === "IN_PROGRESS") {
    return (
      <div>
        <BodyLight>Loading
          <ThreeDots
            height="35"
            width="35"
            radius="9"
            color="var(--brand-primary)"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            visible={true} />
        </BodyLight>
      </div>
    );
  }

  if (status === 'SUCCESS') {
    const languageSelected = i18n.language;

    const options = Object.entries(result).map(([key, value]) => {
      return (
        { label: value.name, value: key }
      );
    });


    return (
      <Select size='medium' name="language select"
        options={options} defaultValue={options.find((option) => option.value === languageSelected)}
        // @ts-expect-error options type
        onChange={(option) => onLanguageSelected(option?.value)} />
    );
  }
};

export default LanguageSelector;