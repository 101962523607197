import { useState } from "react";
import { useTranslation } from 'react-i18next';

import { getSlackDeepLink, resendKeyInsightsSlack, resendSummaryEmail, openCheckoutSessionInNewTab } from '../../utils/genericUtils';
import { CheckmarkCircleIcon, EmailIcon, PlugIcon, RedoArrowIcon, SendIcon, SettingsIcon, SlackLogo, XCircleIcon } from '../Icons';
import Alert from '../Reusable/Alert';
import ProBadge from "../Reusable/ProBadge";
import Popover from "../Reusable/Popover";

import { BackendMeetingData, MeetingData, UserData } from './Types';
import { A, Button, HR } from "../../theme";
import styled from "styled-components";
import ButtonWithTimeoutReplaceOnClick from "../Reusable/ButtonWithTimeoutReplaceOnClick";

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Text = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 5px;
`;

const UpgradeButton = styled(Button)`
  margin: auto;
`;

const ButtonWithTimeout = {
  marginLeft: "-6px",
};

const Link = styled(A)`
 margin-left: 6px;
 font-size: 14px;
 font-weight: 600;
`;

const Notice = styled.span`
  margin-left: 6px;
`;

const greenSuccessCheckBoxOrGrayCross = (isSuccess, successText, notSuccessText) => (
  <Text>
    {isSuccess ?
      <CheckmarkCircleIcon style={{ color: "var(--green500)" }} size="14" />
      :
      <XCircleIcon style={{ color: "#6D6D6D" }} size="14" />}
    <span style={{ fontSize: "14px", "color": isSuccess ? "var(--textPrimary)" : 'var(--textSecondary)', }}>
      {isSuccess ? successText : notSuccessText}
    </span>
  </Text>
);

interface Props {
  backendMeetingData: BackendMeetingData,
  loadBackendMeetingData: (boolean) => void,
  meetingData: MeetingData,
  userData: UserData;
}

const SendNotifications = ({
  backendMeetingData,
  loadBackendMeetingData,
  meetingData,
  userData,
}: Props) => {
  const { t } = useTranslation();
  const [alertIsOpen, setAlertIsOpen] = useState(false);

  const { isBackend } = meetingData;
  const {
    email_summary_sent_to_myself, email_summary_sent_to_everyone,
    slack_summary_sent_to_team_id, slack_summary_sent_to_channel_id,
    meeting_participants_emails,
  } = backendMeetingData;
  const { admin_user, admin_user_subscription_is_active, features, slack_workspace_name, subscription_is_active } = userData;

  const userIsPaid = (subscription_is_active || (!!admin_user && admin_user_subscription_is_active));
  const slackIsConnected = !!slack_workspace_name;


  const handleSendEmailEveryone = () => {
    resendSummaryEmail({ ...meetingData, ...backendMeetingData }, { sendToEveryone: true });
    setTimeout(() => {
      loadBackendMeetingData(false);
    }, 4000);
  };

  return (
    <div>
      <Alert
        open={alertIsOpen}
        onOpenChange={setAlertIsOpen}
        onConfirm={handleSendEmailEveryone}
        cancelContent={t('meeting.ia-summary.send-email.confirmation.cancel', 'Cancel')}
        confirmContent={t('meeting.ia-summary.send-email.confirmation.confirm', 'Send')}
        description={t('meeting.ia-summary.send-email.confirmation.description', 'This will send an email to everyone on the invite.')}
        title={t('meeting.ia-summary.send-email.confirmation.title', 'Are you sure?')}
      />
      <Popover style={{ width: "220px" }} side="bottom" trigger={<SendIcon />} triggerIsIcon >
        {isBackend && <Section>
          <Text>
            <EmailIcon color="var(--brand-primary)" />
            <span>{t('meeting.ia-summary.email.title', 'Email')}</span>
          </Text>
          <ButtonWithTimeoutReplaceOnClick
            small transparent
            style={ButtonWithTimeout}
            initialText={
              email_summary_sent_to_myself ?
                <><RedoArrowIcon color="var(--brand-primary)" size={14} />{t('meeting.ia-summary.email.hover-options.myself.resend-label', 'Re-send to myself')}</>
                :
                <><EmailIcon color="var(--brand-primary)" size="14" />{t('meeting.ia-summary.email.hover-options.myself.send-label', 'Send to myself')}</>
            }
            clickedText={<><CheckmarkCircleIcon style={{ color: "var(--green500)" }} size="14" />{t('meeting.ia-summary.email.hover-options.myself.clicked', 'Sent to myself')}</>}
            onClick={() => {
              resendSummaryEmail({ ...meetingData, ...backendMeetingData }, { sendToEveryone: false });
              setTimeout(() => {
                loadBackendMeetingData(false);
              }, 4000);
            }} />
          {features?.email_attendees &&
            <ButtonWithTimeoutReplaceOnClick
              small transparent
              style={ButtonWithTimeout}
              disabled={meeting_participants_emails?.length == 0}
              initialText={
                email_summary_sent_to_myself ?
                  <><RedoArrowIcon color="var(--brand-primary)" size={14} />{t('meeting.ia-summary.email.hover-options.everyone.resend-label', 'Re-send to everyone')}</>
                  :
                  <><EmailIcon color="var(--brand-primary)" size="14" />{t('meeting.ia-summary.email.hover-options.everyone.send-label', 'Send to everyone')}</>
              }
              clickedText={<><CheckmarkCircleIcon style={{ color: "var(--green500)" }} size="14" />{t('meeting.ia-summary.email.hover-options.everyone.clicked', 'Sent to everyone')}</>}
              onClick={() => setAlertIsOpen(true)} />
          }
          <Link to="/settings/ai-summary-email"><Text><SettingsIcon color="var(--brand-primary)" size={14} />{t('meeting.ia-summary.email.hover-options.settings', 'Email settings')}</Text></Link>

          <Notice>{greenSuccessCheckBoxOrGrayCross(email_summary_sent_to_myself,
            t("meeting.ia-summary.email.checklist.myself.done", "AI summary sent to myself"),
            t("meeting.ia-summary.email.checklist.myself.not-done", "AI summary not sent to myself")
          )}</Notice>
          <Notice>{greenSuccessCheckBoxOrGrayCross(email_summary_sent_to_everyone,
            t("meeting.ia-summary.email.checklist.everyone.done", "AI summary sent to everyone"),
            t("meeting.ia-summary.email.checklist.everyone.not-done", "AI summary not sent to everyone")
          )}</Notice>

          {features?.integrations && (<>
            <HR />

            <Section>
              <Text>
                <SlackLogo />
                <span>{`Slack ${userIsPaid ? '' : <ProBadge />}`}</span>
              </Text>
              {slackIsConnected && userIsPaid && (
                <ButtonWithTimeoutReplaceOnClick
                  small transparent
                  style={ButtonWithTimeout}
                  initialText={
                    slack_summary_sent_to_team_id || slack_summary_sent_to_channel_id ?
                      <><RedoArrowIcon color="var(--brand-primary)" />{t('meeting.ia-summary.slack.hover-options.resend.label', 'Re-send to Slack')}</>
                      :
                      <><EmailIcon color="var(--brand-primary)" size="16" />{t('meeting.ia-summary.slack.hover-options.send.label', 'Send to Slack')}</>
                  }
                  clickedText={<><CheckmarkCircleIcon style={{ color: "var(--green500)" }} size="14" />{t('meeting.ia-summary.slack.hover-options.resend.clicked', 'Sent to Slack')}</>}
                  onClick={() => {
                    resendKeyInsightsSlack({ ...meetingData, ...backendMeetingData });
                    setTimeout(() => {
                      loadBackendMeetingData(false);
                    }, 2000);
                  }} />
              )}

              <Link to="/settings/integrations/slack"><Text>
                <PlugIcon color="var(--brand-primary)" size={14} />{slackIsConnected ? t('meeting.ia-summary.slack.hover-options.settings', 'Manage Slack settings') : t('meeting.ia-summary.slack.hover-options.connect', 'Connect Slack')}</Text>
              </Link>

              <Notice>
                {greenSuccessCheckBoxOrGrayCross(!!slack_summary_sent_to_team_id,
                  <>
                    <span>{t('meeting.ia-summary.slack.checklist.done.pt1', 'AI summary sent to')}</span>{' '}
                    <a href={getSlackDeepLink(slack_summary_sent_to_team_id, slack_summary_sent_to_channel_id)} target="_blank" style={{ "color": "var(--texSecondary)", "fontSize": "14px", "lineHeight": "20px", "letterSpacing": "0.15px", textDecoration: 'underline', cursor: 'pointer' }} rel="noreferrer">
                      {t('meeting.ia-summary.slack.checklist.done.pt2', 'Slack channel')}
                    </a>
                  </>,
                  t("meeting.ia-summary.slack.checklist.not-done", "AI summary not sent to Slack")
                )}
              </Notice>
            </Section>
          </>
          )}
        </Section>}
        {!userIsPaid && <>
          <HR />
          <UpgradeButton medium onClick={openCheckoutSessionInNewTab}>
            {t('meeting.ia-summary.upgrade', 'Upgrade to PRO now')}
          </UpgradeButton>
        </>
        }
      </Popover>
    </div >
  );
};

export default SendNotifications;