import React, { useEffect, useState } from 'react';
import { setSalesforceIntegrationIsActive, setupZoom, trackEvent } from '../utils/genericUtils';
import { useUserData } from './hooks/useUserData';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { SALESFORCE_CONNECT_LINK } from "../config/config";
import { Button as ButtonBase, ButtonLink, HR } from '../theme';
import styled from 'styled-components';
import { CheckmarkCircleIcon, XCircleIcon } from './Icons';

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
`;

const Button = styled(ButtonBase)`
  width: 300px;
`;



function SalesForceOnboardingStep1({ closePopup, goToNextStep }) {
  const [userData] = useUserData();
  const {
    subscription_is_active,
  } = userData;

  return <div>
    <h1 style={{
      marginBottom: 20,
      "fontSize": "24px",
      "fontStyle": "normal",
      "fontWeight": "900",
      "lineHeight": "normal"
    }}>
      Set up your Briefly integration for Salesforce </h1>
    <div style={{ display: 'flex' }}>
      <div style={{ paddingLeft: 25 }}>
        <h6 style={{
          margin: 0,
          "fontSize": "16px",
          "fontStyle": "normal",
          "fontWeight": "500",
          "lineHeight": "22px",
          "letterSpacing": "0.15px"
        }}>
          How does it work?
        </h6>
        <h6 style={{
          margin: 0,
          marginTop: 8,
          "fontSize": "14px",
          "fontStyle": "normal",
          "fontWeight": "700",
          "lineHeight": "20px",
          "letterSpacing": "0.15px"
        }}>
          🔗 Connect to Salesforce </h6>
        <p style={{
          margin: 0,
          marginTop: 4,
          "color": "var(--textSecondary)",
          "fontSize": "14px",
          "fontStyle": "normal",
          "fontWeight": "400",
          "lineHeight": "20px",
          "letterSpacing": "0.15px"
        }}>
          You must have access to all contacts and accounts in Salesforce. </p>
        <h6 style={{
          margin: 0,
          marginTop: 8,
          "fontSize": "14px",
          "fontStyle": "normal",
          "fontWeight": "700",
          "lineHeight": "20px",
          "letterSpacing": "0.15px"
        }}>
          🛡️ Individual connection </h6>
        <p style={{
          margin: 0,
          marginTop: 4,
          "color": "var(--textSecondary)",
          "fontSize": "14px",
          "fontStyle": "normal",
          "fontWeight": "400",
          "lineHeight": "20px",
          "letterSpacing": "0.15px"
        }}>
          Each team member will need to connect to Salesforce individually.
        </p>
        <h6 style={{
          margin: 0,
          marginTop: 8,
          "fontSize": "14px",
          "fontStyle": "normal",
          "fontWeight": "700",
          "lineHeight": "20px",
          "letterSpacing": "0.15px"
        }}>
          ✍️ Configure transcript uploads </h6>
        <p style={{
          margin: 0,
          marginTop: 4,
          "color": "var(--textSecondary)",
          "fontSize": "14px",
          "fontStyle": "normal",
          "fontWeight": "400",
          "lineHeight": "20px",
          "letterSpacing": "0.15px"
        }}>
          Adjust your Salesforce settings to automatically upload transcripts after each call. </p>
        <h6 style={{
          margin: 0,
          marginTop: 8,
          "fontSize": "14px",
          "fontStyle": "normal",
          "fontWeight": "700",
          "lineHeight": "20px",
          "letterSpacing": "0.15px"
        }}>
          🔓️ Call-Level Override </h6>
        <p style={{
          margin: 0,
          marginTop: 4,
          "color": "var(--textSecondary)",
          "fontSize": "14px",
          "fontStyle": "normal",
          "fontWeight": "400",
          "lineHeight": "20px",
          "letterSpacing": "0.15px"
        }}>
          Override the default setting during a call for full control on a call-by-call basis.</p>
      </div>
    </div>

    {!subscription_is_active ? <div
      style={{
        background: "var(--backgroundSecondary)",
        border: 'var(--standardBorder)',
        display: "flex",
        flexDirection: 'column',
        textAlign: 'center',
        padding: 16,
        marginTop: 32
      }}
    >
      <p
        style={
          {
            margin: 0,
            fontSize: 16,
            fontWeight: '900',
            wordWrap: 'break-word'
          }
        }>SalesForce integration is a PRO feature only</p>

      <p style={{
        color: 'var(--textSecondary)',
        fontSize: 12,
        fontWeight: '400',
        wordWrap: 'break-word',
        marginTop: 16,
        marginBlock: 8
      }}>Upgrade to a PRO account to use this feature
      </p>
      <ButtonLink style={{ margin: "auto" }} to="/settings/subscriptions">
        Upgrade to PRO now
      </ButtonLink>
    </div>
      :
      <ButtonWrapper>
        <Button onClick={goToNextStep} >
          Start SalesForce setup
        </Button>
        <Button transparent onClick={closePopup}>
          Quit Setup
        </Button>
      </ButtonWrapper>
    }
  </div>;
}

function SalesForceOnboardingStep2({ closePopup, goToNextStep }) {

  const handleRedirect = () => {
    trackEvent('salesforce-connect-clicked', {});
    setTimeout(() => {
      window.location = SALESFORCE_CONNECT_LINK;
    }, 500);
  };

  return <div>
    <h1 style={{
      "textAlign": "left",
      "fontSize": "24px",
      "fontStyle": "normal",
      "fontWeight": "900",
      "lineHeight": "normal"
    }}>
      Set up your Briefly integration for Salesforce </h1>
    <div style={{ display: "flex", flexDirection: 'row', gap: 16 }}>
      <div>
        <p style={{
          fontSize: 16,
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '22px',
          letterSpacing: '0.15px',
          textAlign: 'left'
        }}>
          Connect Briefly to your Salesforce workspace </p>
        <p style={{
          "color": "var(--textSecondary)",
          "textAlign": "left",
          "fontSize": "14px",
          "fontStyle": "normal",
          "fontWeight": "400",
          "lineHeight": "22px",
          "letterSpacing": "0.15px"
        }}>
          You must have access to all contacts and accounts in Salesforce.
        </p>
      </div>
      <Button secondary
        onClick={handleRedirect}
      >
        Connect
      </Button>
    </div>

    <ButtonWrapper>
      <Button onClick={goToNextStep} >
        Confirm and complete setup
      </Button>
      <div style={{}} />
      <Button transparent onClick={closePopup} >
        Quit Salesforce setup
      </Button>
    </ButtonWrapper>
  </div>;
}

function SalesForceOnboardingStep3({
  closePopup,
}) {

  const [userData] = useUserData();
  const {
    is_integration_active_salesforce,
    is_integration_active_google_calendar,
    salesforce_redshift_db_name

  } = userData;
  const salesforceDomain = salesforce_redshift_db_name ? salesforce_redshift_db_name?.replace('staging-salesforce-', '')?.replace('prod-salesforce-', '')?.replaceAll('-', '.') : '';

  const [setShowHubspotCalendarRequiredPopup] = useState();
  return <div>
    <>
      <h1 style={{
        "textAlign": "left",
        "fontSize": "24px",
        "fontStyle": "normal",
        "fontWeight": "900",
        "lineHeight": "normal"
      }}>
        Set up your Briefly integration for Salesforce </h1>
      <div style={{ display: "flex", flexDirection: 'column', width: '100%', gap: 24 }}>
        <div style={{ display: "flex", flexDirection: 'row', gap: 24, alignItems: 'center' }}>
          <p style={{
            flex: 1,
            fontSize: 16,
            fontWeight: '500',
            letterSpacing: 0.15,
            wordWrap: 'break-word'
          }}>Briefly is connected to Salesforce
          </p>
          <div style={
            {
              display: 'flex',
              alignItems: 'center',
              gap: 6,
              fontSize: 12,
              fontWeight: 700,
              padding: '2px 6px',
              borderRadius: 2,
              margin: 0,
              height: 'fit-content',
            }
          }>
            {salesforceDomain ? <><CheckmarkCircleIcon color={"var(--green500)"} />
              {salesforceDomain} </> : <XCircleIcon size={16} />}
          </div>
        </div>
        <HR />
        <div>
          <div style={{ display: 'flex' }}>
            <p style={
              {
                margin: 0,
                flex: 1,
                fontSize: 16,
                fontWeight: '500',
                letterSpacing: 0.15,
                wordWrap: 'break-word'
              }
            }>
              Add AI Summaries and Transcripts to Salesforce </p>
            <label className="switch">
              <input type="checkbox"
                disabled={!is_integration_active_google_calendar}
                checked={is_integration_active_salesforce}
                onClick={e => {
                  const checked = e.target.checked;
                  if (checked && !is_integration_active_google_calendar) {
                    setShowHubspotCalendarRequiredPopup(true);
                    return;
                  }
                  setSalesforceIntegrationIsActive(checked);

                }} />
              <span className="slider round"></span>

            </label>
          </div>
          <p style={{
            width: '100%',
            color: 'var(--textSecondary)',
            fontSize: 12,
            margin: 0,
            fontWeight: '400',
            letterSpacing: 0.30,
            wordWrap: 'break-word'
          }}>Automatically add your call transcripts and AI summaries to Salesforce contacts
          </p>
        </div>
      </div>
    </>
    <ButtonWrapper >
      <Button onClick={closePopup} >
        Confirm and complete setup
      </Button>
      <div style={{}} />
      <Button transparent onClick={closePopup}>
        Quit Salesforce setup
      </Button>
    </ButtonWrapper>
  </div>;
}

const STEPS_CONFIG = [
  {
    component: SalesForceOnboardingStep1,
    label: 'Preview'
  },
  {
    component: SalesForceOnboardingStep2,
    label: 'Add to SalesForce'
  },
  {
    component: SalesForceOnboardingStep3,
    label: 'Confirm and complete setup'
  },
];

function SalesForceOnboardingPopup({ onClose }) {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const [, forceRenewUserData] = useUserData();

  const [botShouldJoinAllCalls, setBotShouldJoinAllCalls] = useState(false);

  const currentStep = STEPS_CONFIG[currentStepIndex];
  const CurrentStepComponent = currentStep.component;

  useEffect(() => {
    if (currentStepIndex === 4) {
      setupZoom(botShouldJoinAllCalls, () => {
        forceRenewUserData();
      });
    }
  }, [currentStepIndex]);

  return <GoogleOAuthProvider clientId="576404817524-ss93f3dua36hvaum9oi4vqrmu8anc6o3.apps.googleusercontent.com">
    <div className="transcript-info-popup-container">
      <div className="popup-background" onClick={() => onClose()}></div>
      <div className="popup-content" style={{ padding: 20 }}>
        <CurrentStepComponent botShouldJoinAllCalls={botShouldJoinAllCalls}
          setBotShouldJoinAllCalls={setBotShouldJoinAllCalls} closePopup={onClose}
          goToPrevStep={() => setCurrentStepIndex(currentStepIndex - 1)}
          goToNextStep={() => setCurrentStepIndex(currentStepIndex + 1)} />
      </div>
    </div>
  </GoogleOAuthProvider>;
};

export default SalesForceOnboardingPopup;
